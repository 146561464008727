import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  api_multipague_cobranca,
  api_multipague_transacao,
  api_multipague_arquivo,
} from '../../services/api';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import Buttons from '../../components/Buttons/ConfirmButton';
import Data from '../../components/InputDate';
import { BsInfoCircle } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import {
  hideLoading,
  hideLoadingButton,
  showLoading,
  showLoadingButton,
} from '../../store/modules/loading/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';

import { MdClose, MdHighlightOff, MdRefresh, MdSave } from 'react-icons/md';
import { useTheme } from '@material-ui/core/styles';
import { toastComponentError } from '../../components/Toast';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export const DetalhePagamentoLote = () => {
  const theme = useTheme();
  const [pageTitle, setPageTitle] = useState('');
  const root = {
    path: [
      {
        name: 'Pagamento em Lote',
        caminho: '/Pagamento/Lote',
      },
      {
        name: 'Detalhe arquivo CNAB',
        caminho: '/Pagamento/Lote',
      },
    ],
    pagAtual: pageTitle,
  };
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [nomeBanco, setNomeBanco] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');
  const [dataPagamento, setDataPagamento] = useState('');
  const data = new Date(dataVencimento);
  const dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  const dataPag = new Date(dataPagamento);
  const dataFormatadaPagamento = dataPag.toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
  });
  const [pagCodigoBarras, setPagCodigoBarras] = useState('');
  const [documentoFederalBeneficiario, setDocumentoFederalBeneficiario] =
    useState('');
  const [idArquivoPagamentoDetalhe, setIdArquivoPagamentoDetalhe] =
    useState('');
  const [linhaDigitavel, setLinhaDigitavel] = useState('');
  const [nomeBeneficiario, setNomeBeneficiario] = useState('');
  const [tipoPessoaBeneficiario, setTipoPessoaBeneficiario] = useState('');
  const [valorAbatimento, setValorAbatimento] = useState('');
  const [valorJuros, setValorJuros] = useState('');
  const [valorMulta, setValorMulta] = useState('');
  const [valorPagamento, setValorPagamento] = useState('');
  const [valorTitulo, setValorTitulo] = useState('');

  const [clienteNomeTed, setClienteNomeTed] = useState('');
  const [bancoTed, setBancoTed] = useState('');
  const [agenciaTed, setAgenciaTed] = useState('');
  const [contaTed, setContaTed] = useState('');
  const [digitoTed, setDigitoTed] = useState('');
  const [favorecidoNomeTed, setFavorecidoNomeTed] = useState('');

  const initialFavorecidoTed = {
    nome: '',
    banco: '',
    agencia: '',
    conta: '',
    dataAgendamento: '',
    valor: 0,
  };
  const [favorecidoTed, setFavorecidoTed] = useState(initialFavorecidoTed);

  const [favorecidoBancoTed, setFavorecidoBancoTed] = useState('');
  const [valorTed, setValorTed] = useState('');

  // const { id } = useParams();
  const id = localStorage.getItem('idPagamentoLote');
  const history = useHistory();
  const { loadingButton } = useSelector(state => state.loading);
  const { contaPadrao } = useSelector(state => state.contas);
  const dispatch = useDispatch();
  const [uploadFiles, setUploadFiles] = useState([]);
  const fileRef = useRef(null);
  const [name, setName] = useState('');
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [rowsTable, setRowsTable] = useState([]);

  const initiDialogObs = {
    title: '',
    message: '',
    open: false,
  };

  const [dialogObs, setDialogObs] = useState(initiDialogObs);
  const handleModalDialogObs = event => {
    setDialogObs({
      title: event.row.tipoRegistro,
      open: true,
      message: event.row.observacao,
    });
  };

  const columnsTable = [
    { field: 'codigo', headerName: 'Código', width: 160 },
    {
      field: 'tipoRegistro',
      headerName: 'Tipo Registro',
      type: 'string',
      flex: 1,
    },

    {
      field: 'situacao',
      headerName: 'Situação',
      width: 140,
    },
    {
      field: 'detalhe',
      headerName: 'Detalhes',
      sortable: false,
      width: 110,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: event => {
        if (event.row.observacao) {
          return (
            <button onClick={() => handleModalDialogObs(event)}>
              <MdHighlightOff size={20} color="red" />
            </button>
          );
        } else {
          if (
            event.row.tipoRegistro === 'PAGAMENTO_BOLETO' &&
            event.row.idGerado != null
          ) {
            return (
              <button onClick={() => handleClickOpenPag(event)}>
                <BsInfoCircle size={20} color={theme.palette.primary.light} />
              </button>
            );
          } else if (
            event.row.tipoRegistro === 'TRANSFERENCIA' &&
            event.row.idGerado != null
          ) {
            return (
              <button onClick={() => handleClickOpenTed(event)}>
                <BsInfoCircle size={20} color={theme.palette.primary.light} />
              </button>
            );
          } else {
            return <></>;
          }
        }
      },
    },
  ];

  function refresh() {
    try {
      const { data } = api_multipague_arquivo.get(
        `/arquivo/pagamento/${id}/detalhes?clienteConta=${contaPadrao.id}`,
      );

      const formatted = data.map(files => ({
        ...files,
        codigo: files.idGerado,
        arquivo: files.arquivo.nome,
        segmento: files.segmento,
        tipoRegistro: files.tipoRegistro,
        situacao: files.situacao,
        observacao: files.observacao,
      }));
      setRowsTable(formatted);
    } catch (err) {
      if (!!err.response.data.mensagem) {
        toastComponentError(err.response.data.mensagem);
      } else {
        toastComponentError('Erro ao realizar busca.');
      }
      console.log(err);
    } finally {
      console.log('finnally ==>');
    }
  }

  async function listFiles() {
    try {
      const { data } = await api_multipague_arquivo.get(
        `/arquivo/pagamento/${id}/detalhes?clienteConta=${contaPadrao.id}`,
      );
      // arquivo/pagamento/24/detalhes?clienteConta=479&situacao=AGUARDANDO_PROCESSAMENTO'
      // const { data } = await api_multipague_arquivo.get(
      //   `/arquivo/pagamento?clienteConta=${
      //     contaPadrao.id
      //   }&situacao=&nome=${name}&dataInicial=${dataInicial || ''}&dataFinal=${
      //     dataFinal || ''
      //   }`,
      // );

      const formatted = data.map(files => ({
        ...files,
        codigo: files.idGerado,
        arquivo: files.arquivo.nome,
        segmento: files.segmento,
        tipoRegistro: files.tipoRegistro,
        situacao: files.situacao,
        observacao: files.observacao,
      }));

      setPageTitle(data[0]?.arquivo.nome);
      setNomeArquivo(data[0]?.arquivo.nome);
      setRowsTable(formatted);
    } catch (err) {
      if (!!err.response.data.mensagem) {
        toastComponentError(err.response.data.mensagem);
      } else {
        toastComponentError('Erro ao realizar busca.');
      }
      console.log(err);
    } finally {
      console.log('finnally2 ==>');
    }
  }

  useEffect(() => {
    if (contaPadrao?.id) {
      listFiles();
    }
  }, [contaPadrao]);

  const [openPag, setOpenPag] = useState(false);
  const [openTed, setOpenTed] = useState(false);

  function handleClickOpenPag(event) {
    // pagBoletoData()
    setOpenPag(true);
    api_multipague_cobranca
      .get(`boleto/${event.row.idGerado}`)
      .then(response => {
        setNomeBanco(
          response.data.banco != null ? response.data.banco.descricao : '',
        );
        setDataVencimento(response.data.dataVencimento);
        setDataPagamento(response.data.dataPagamento);
        setPagCodigoBarras(response.data.codigoBarras);
        setDocumentoFederalBeneficiario(
          response.data.documentoFederalBeneficiario,
        );
        setIdArquivoPagamentoDetalhe(response.data.idArquivoPagamentoDetalhe);
        setLinhaDigitavel(response.data.linhaDigitavel);
        setNomeBeneficiario(response.data.nomeBeneficiario);
        setTipoPessoaBeneficiario(response.data.tipoPessoaBeneficiario);
        setValorAbatimento(response.data.valorAbatimento);
        setValorJuros(response.data.valorJuros);
        setValorMulta(response.data.valorMulta);
        setValorPagamento(response.data.valorPagamento);
        setValorTitulo(response.data.valorTitulo);
      })
      .catch(err => {
        if (!!err.response.data.mensagem) {
          toastComponentError(err.response.data.mensagem);
        } else {
          toastComponentError('Erro ao realizar consulta.');
        }
        console.log(err);
      });
  }

  function handleClickOpenTed(event) {
    setOpenTed(true);
    api_multipague_transacao
      .get(`transferencia/${event.row.idGerado}`)
      .then(response => {
        const { data } = response;

        console.log('fovorecido-ted', data, data.clienteConta);

        setFavorecidoTed({
          nome: data.favorecido.nome,
          banco: data.favorecido.bancoDTO.nome,
          agencia: data.favorecido.agencia,
          conta: data.favorecido.conta,
          dataAgendamento: data.dataAgendamento,
          valor: data.valor,
        });

        /*
      setClienteNomeTed(response.data.clienteContaDTO.cliente.nome)
      setBancoTed(response.data.clienteContaDTO.banco.nome)
      setAgenciaTed(response.data.clienteContaDTO.agencia)
      setContaTed(response.data.clienteContaDTO.conta + "-" + response.data.clienteContaDTO.digitoConta)
      setFavorecidoNomeTed(response.data.favorecidoDTO.nome)
      setFavorecidoBancoTed(response.data.favorecidoDTO.bancoDTO.nome)
      setValorTed(response.data.valor)
      */
      })
      .catch(err => {
        console.log('erro modal ted', err);
        if (!!err.response.data.mensagem) {
          toastComponentError(err.response.data.mensagem);
        } else {
          toastComponentError('Erro ao realizar consulta.');
        }
      });
  }

  const handleClosePag = () => {
    setOpenPag(false);
  };

  const handleCloseTed = () => {
    setOpenTed(false);
  };

  useEffect(() => {
    console.log('favorecidoTed', favorecidoTed);
  }, [favorecidoTed]);

  return (
    // <ContainerComponent root={root}>
    <Wrapper
      title="Detalhe do Pagamento"
      crumb={[
        { name: 'Dashboard', link: '/' },
        { name: 'Pagamento em Lote', link: '/Pagamento-Lote' },
      ]}
      crumbActive={`Detalhe do Pagamento ${nomeArquivo}`}
    >
      <Box>
        <Dialog
          open={openPag}
          onClose={handleClosePag}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Detalhes do boleto'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <p>Código de Barras: {pagCodigoBarras}</p>
                <p style={{ marginTop: '10px' }}>Banco: {nomeBanco}</p>
                <p style={{ marginTop: '10px' }}>
                  Doc. Federal: {documentoFederalBeneficiario}
                </p>
                <p style={{ marginTop: '10px' }}>
                  ID do Arquivo: {idArquivoPagamentoDetalhe}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Linha Digitável: {linhaDigitavel}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Nome Beneficiário: {nomeBeneficiario}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Tipo Pessoa: {tipoPessoaBeneficiario}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Valor Abatimento:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(valorAbatimento)}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Valor Juros + Multa:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(valorJuros)}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Valor Pagamento:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(valorPagamento)}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Valor Título:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(valorTitulo)}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Data de Vencimento: {dataFormatada}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Data de Pagamento: {dataFormatadaPagamento}
                </p>
                {/* <p style={{marginTop:'10px'}}>Data de Vencimento: {new Intl.DateTimeFormat('pt-BR', {timeZone: 'UTC'}).format(new Date(`'${dataVencimento}'`))}</p> */}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Buttons onClick={handleClosePag} color="primary">
            Disagree
          </Buttons> */}
            <Buttons
              title={'Fechar'}
              startIcon={<MdClose color="background" />}
              onClick={handleClosePag}
              color="primary"
              autoFocus
            >
              Fechar
            </Buttons>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openTed}
          onClose={handleCloseTed}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">{'TED'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <p style={{ marginTop: '10px' }}>
                  Favorecido: {favorecidoTed.nome}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Banco: {favorecidoTed.banco}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Agência: {favorecidoTed.agencia}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Conta: {favorecidoTed.conta}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Data Pagamento: {favorecidoTed.dataAgendamento}
                </p>
                <p style={{ marginTop: '10px' }}>
                  Valor da Transferência:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(favorecidoTed.valor)}
                </p>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons
              title={'Fechar'}
              startIcon={<MdClose color="background" />}
              onClick={handleCloseTed}
              color="primary"
              autoFocus
            >
              Fechar
            </Buttons>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogObs.open}
          onClose={() => setDialogObs(initiDialogObs)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Observação: </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <p>
                  TIpo de Registro:<b>{dialogObs.title}</b>
                </p>
                <div style={{ height: '1rem' }} />
                <b style={{ color: 'red' }}>ERRO:</b>
                <p>{dialogObs.message}</p>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons
              onClick={() => setDialogObs(initiDialogObs)}
              color="primary"
              title={'Fechar'}
              startIcon={<MdClose color="background" />}
              autoFocus
            >
              Fechar
            </Buttons>
          </DialogActions>
        </Dialog>

        <Buttons
          title={'Atualizar'}
          color="primary"
          variant="contained"
          startIcon={<MdRefresh color="background" />}
          onClick={listFiles}
        >
          Atualizar
        </Buttons>
        <DataGrid
          rows={rowsTable}
          columns={columnsTable}
          pageSize={10}
          height="500px"
        />
      </Box>
    </Wrapper>
  );
};
