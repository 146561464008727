import React, { useState, useRef, useEffect } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import * as Yup from 'yup';
import getValidationErros from '../../util/FormattedError';
import InputText from '../InputText';
import Button from '../Buttons/ConfirmButton';
import {
  Container,
  Subcontainer,
  ContainerInput,
  ContainerKeyboard,
  ContainerButtons,
  ContainerTitle,
  ContainerInputPassword,
  ContainerError,
} from './styles';
import KeyboardPassword from '../KeyboardPassword';
import { MdSend } from 'react-icons/md';
const Password = ({ callback }) => {
  const formRef = useRef(null);
  const keyboardRef = useRef(null);
  const [error, setError] = useState('');

  function sendPassword() {
    console.log('testando ref', keyboardRef.current?.password_value);

    try {
      if (keyboardRef.current?.password_value.length < 6) {
        setError('Senha Inválida');
        return;
      }
      if (callback) {
        callback(
          window.btoa(JSON.stringify(keyboardRef.current?.password_value)),
        );
      }
      keyboardRef.current?.clean_array([]);
      keyboardRef.current?.clean_field('');
    } catch (err) {
      keyboardRef.current?.clean_array([]);
      keyboardRef.current?.clean_field('');
    }
  }

  return (
    <Container ref={formRef} onSubmit={sendPassword}>
      <Subcontainer>
        <KeyboardPassword ref={keyboardRef} />
        <ContainerButtons>
          <Button
            color="primary"
            variant="contained"
            disabled={false}
            title={'Enviar'}
            width={100}
            startIcon={<MdSend color={'background'} />}
            type="submit"
          ></Button>
        </ContainerButtons>
        <ContainerError>{!!error && <p>{error}</p>}</ContainerError>
      </Subcontainer>
    </Container>
  );
};

export default Password;
