import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { api_multipague_despesa } from '../../src/services/api';
import moment from 'moment';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardCard } from './DashboardCards';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';

const useStyles = makeStyles(theme => ({
  rootChart: {
    '& svg': {
      // width: '120%',
      '& text': {
        flexWrap: 'wrap',
        fontSize: 11,

        fill: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '& rect:nth-child(1)': {
        fill: theme.palette.background.paper,
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {
  const theme = useTheme();
  const classes = useStyles();
  const chartContainerRef = useRef(null);
  const [error, setError] = React.useState(false);
  const [mensagemErro, setMensagemErro] = React.useState(false);
  const dispatch = useDispatch();
  const infoRedux = useSelector(state => state.contas);
  const [value, setValue] = React.useState(0);
  const [carregando, setCarregando] = useState(false);

  const optionsChart = {
    backgroundColor: theme.palette.background.paper,
    title: 'Despesas',
    titleTextStyle: {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    legend: {
      textStyle: {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      position: 'left',
    },
    colors: [theme.palette.primary.main, '#394c59', '#2962ff', '#ffab00'],
  };

  const [options, setOptions] = useState(optionsChart);

  const [informacoes, setInformacoes] = useState({
    etapasConsolidada: [],
  });
  const [infoGraficos, setInfoGraficos] = useState([]);

  const dataInicial = moment(new Date().getFullYear() + '-01-01').format(
    'YYYY-MM-DD',
  );
  const dataFinal = moment(new Date().getFullYear() + '-12-31').format(
    'YYYY-MM-DD',
  );
  const URL = `/despesa?dataInicial=${dataInicial}&dataFinal=${dataFinal}&contextoConsolidado=true`;

  useEffect(() => {
    setCarregando(true);
    api_multipague_despesa
      .get(URL)
      .then(response => {
        setCarregando(false);

        setInformacoes(response.data);

        const formattedInfo = response.data.dataCentroCustos.reduce(
          (acc, item) => {
            const percentuais = item.centrosCustos.reduce(
              (innerAcc, innerItem) => {
                innerAcc[innerItem.nomeCentroCusto] =
                  innerItem.valorCentroCusto;
                return innerAcc;
              },
              {},
            );

            const filledPercentuais = [
              'GERAL',
              'Favorecidos',
              'Pagadores',
              'Clientes',
            ].map(nomeCentroCusto => percentuais[nomeCentroCusto] || 0);

            acc.push([moment(item.data).format('MMMM'), ...filledPercentuais]);
            return acc;
          },
          [],
        );

        formattedInfo.unshift([
          'Mês',
          'Geral',
          'Favorecidos',
          'Pagadores',
          'Clientes',
        ]);
        setInfoGraficos(formattedInfo);
      })
      .catch(err => {
        setCarregando(false);
        setError(true);
        setMensagemErro(
          err.response.data.mensagem
            ? err.response.data.mensagem
            : 'Não foi possível conectar-se ao servidor',
        );
      });
  }, [infoRedux, dispatch]);

  useEffect(() => {
    setOptions(optionsChart);
  }, [theme.palette.type]);

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    if (chartContainer) {
      chartContainer.style.backgroundColor = theme.palette.primary.main;
    }
  }, [theme.palette.primary.main]);

  return (
    <DashboardCard
      isChart
      title={'Despesas'}
      icon={
        <AssessmentIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      carregando={carregando}
      children={
        <>
          <TabPanel value={value} index={0}>
            {error ? (
              <div
                style={{
                  backgroundColor: 'transparent',
                  transform: 'translate(0%, 150%)',
                }}
              >
                <MessageErrorComponent mensagemErro={mensagemErro} />
              </div>
            ) : (
              <div ref={chartContainerRef} className={classes.rootChart}>
                <Chart
                  className={classes.rootChart}
                  width={'100%'}
                  chartType="Bar"
                  data={infoGraficos}
                  options={options}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </>
      }
    />
  );
}

export default App;
