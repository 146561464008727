import React from "react";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {Container} from "./style";

const Copyright = () => {
    return (
      <Container>
        <Typography variant="body2" color="textSecondary">
          {'Copyright © '}
          <Link color="inherit" href="https://grupomultiplica.com.br/">
            Grupo Multiplica
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    );
}

export default Copyright

