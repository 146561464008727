/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import TextField from '@material-ui/core/TextField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Button from '../../../components/Buttons/ConfirmButton';
import Typography from '@material-ui/core/Typography';

import Box from '../../../components/Box';
import Wrapper from '../../../components/Wrapper';
import { FaSave } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';

import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../../services/api';
import { toastComponentError, toastComponentSuccess } from '../../../components/Toast';

// import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    alignItems: 'center',
    marginLeft: '10px',
    width: '200px',
  },
  inputtext: {
    // margin: '15px 15px 0px',
    width: '100%',
  },
  inputtextTitle: {
    margin: '0px 0px 0px 0px',
    width: '100%',
  },
  radiobox: {
    margin: '15px 15px 0px',
    padding: '10px 10px',
    border: '1px solid #c1c1c1',
    borderRadius: '20px',
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function CentroDeCustoEditar() {
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [value, setValue] = React.useState('ativo');
  const [code, setCode] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [contas, setContas] = React.useState([]);
  const [contasNew, setContasNew] = React.useState([]);
  const [contaName, setContaName] = useState('');
  const [editDescription, setEditDescription] = React.useState(
    localStorage.getItem('description'),
  );
  const [valorMeta, setValorMeta] = React.useState(0);
  const [listaContas, setListaContas] = React.useState('');
  const theme = useTheme();
  const [state, setState] = React.useState({
    centrocusto: '',
  });
  const { userAccount } = useSelector(state => state.user);
  const { contaPadrao } = useSelector(state => state.contas);

  const { info } = useParams();

  const handleMultipleSelect = value => {
    setContaName(value);
  };

  React.useEffect(() => {
    let contaformat = [];
    api_multipague_conta.get(`/conta`).then(response => {
      response.data.map(item => {
        contaformat.push({
          label:
            'Banco: ' +
            item.banco.nome +
            ' / ' +
            'Conta: ' +
            item.conta +
            ' / ' +
            'Agência ' +
            item.agencia,
          value: item.id,
        });
      });
      setContas(contaformat);
    });
  }, []);

  React.useEffect(() => {
    const contaEdit = [];
    api_multipague_configuracao
      .get(`/centroCusto/contas/${info}`)
      .then(response => {
        console.log('Lista aí:', response.data);
        response.data.listaContaBasica.map(item => {
          contaEdit.push({
            label:
              'Banco: ' +
              item.nomeBanco +
              ' / ' +
              'Conta: ' +
              item.conta +
              ' / ' +
              'Agência ' +
              item.agencia,
            value: item.id,
          });
        });
        console.log('Will Aqui ', contaEdit);
        setContaName(contaEdit);

        // response.data.listaContaBasica.map(item => {
        //   contaEdit.push({
        //     label:
        //       `Banco: ${item.numeroBanco}  ${item.nomeBanco} / ` +
        //       `Conta: ${item.conta} / ` +
        //       `Digito conta: ${item.digitoConta} / ` +
        //       `Agência ${item.agencia}`,
        //     value: item.id,
        //   });
        // });
        // const listarAqui =  response.data.listaContaBasica.map(item => {
        //   <p>Banco: {item.numeroBanco} {item.nomeBanco}
        //   Conta: {item.conta}
        //   Digito conta: {item.digitoConta}
        //   Agência ${item.agencia}</p>
        // })
        // console.log(contaEdit)
        // setContaName(contaEdit);
        // setListaContas(listarAqui)
        setEditDescription(response.data.descricao);
        setValorMeta(response.data.valorMeta || 0);
      });
  }, []);

  const editarCusto = () => {
    setLoadButton(true);
    console.log(userAccount.id, contaPadrao);
    const idClienteConta = userAccount.id;
    const conta = [];
    contasNew.map(item => {
      conta.push({ idClienteConta: item.value });
    });

    const arr = new Array();
    for (var i = 0; i < contaName.length; i++) {
      var obj = { idClienteConta: contaName[i].value };
      arr.push(obj);
    }
    const params = {
      ativo: true,
      descricao: editDescription,
      idcliente: idClienteConta,
      lista: arr,
      valorMeta: parseInt(valorMeta) || 0,
    };
    console.log(params);
    api_multipague_configuracao

      .put(`centroCusto/associa/${info}`, params)
      .then(response => {
        setLoadButton(false);
        toastComponentSuccess('centro de custo editado com sucesso!');
        window.location.href = '/centro-de-custo';
      })
      .catch(err => {
        if (
          err.response.status == 400 ||
          err.response.status == 404 ||
          err.response.status == 500
        ) {
          toastComponentError(err.response.data.mensagem);
        }
        setLoadButton(false);
      });
  };

  const handleChangeCode = event => {
    setCode(event.target.value);
  };

  const handleChangeDescription = event => {
    setEditDescription(event.target.value);
  };

  const handleChangeValorMeta = event => {
    setValorMeta(event.target.value);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.getContrastText(theme.palette.primary.light),
      '&:hover': {
        backgroundColor: state.isSelected
          ? theme.palette.primary.light
          : theme.palette.primary.dark,
      },
    }),
  };

  return (
    <Wrapper
      title="Editar"
      crumb={[
        {
          name: 'Dashboard',
          link: '/',
        },
        {
          name: 'Centro de Custo',
          link: '/centro-de-custo',
        },
      ]}
    >
      <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} sm={2}>
            <Typography color="textPrimary" className={classes.inputtextTitle}>
              Código
            </Typography>
            <TextField
              size="small"
              className={classes.inputtext}
              id="outlined-basic"
              value={info}
              variant="outlined"
              onChange={handleChangeCode}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography color="textPrimary" className={classes.inputtextTitle}>
              Descrição
            </Typography>
            <TextField
              size="small"
              className={classes.inputtext}
              id="outlined-basic"
              value={editDescription}
              variant="outlined"
              onChange={handleChangeDescription}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography color="textPrimary" className={classes.inputtextTitle}>
              Meta Mensal
            </Typography>
            <CurrencyTextField
              size="small"
              className={classes.inputtext}
              id="outlined-basic"
              value={valorMeta}
              currencySymbol="R$"
              decimalCharacter=","
              digitGroupSeparator="."
              onChange={(event, value) => setValorMeta(value)}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography color="textPrimary">
              Em quais contas quer cadastrar?
            </Typography>
            <Select
              placeholder="Selecione..."
              isMulti
              value={contaName}
              styles={customStyles}
              style={{ borderRadius: '20px' }}
              name="label"
              options={contas}
              className="basic-multi-select"
              classNamePrefix="contas"
              onChange={handleMultipleSelect}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.grid}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              variant="contained"
              color="default"
              title={'Cancelar'}
              startIcon={<MdClear size={15} color="background" />}
              href="/centro-de-custo"
            ></Button>
            <LoadingBtn
              variant="contained"
              color="primary"
              title={'Salvar'}
              isLoading={loadButton}
              startIcon={<FaSave size="1em" color="background" />}
              onClick={editarCusto}
            ></LoadingBtn>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
}

export default CentroDeCustoEditar;
