import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { api_multipague_cobranca } from '../../src/services/api';
import formattedMoney from '../util/FormatteMoney/index';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';
import Divider from '@material-ui/core/Divider';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import { DashboardCard } from './DashboardCards';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';

export default function CobrancaSimples() {
  const infoRedux = useSelector(state => state.contas);
  const theme = useTheme();
  const matchesPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const [vencendoHoje, setVencendoHoje] = useState('0');
  const [aVencer, setAVencer] = useState('0');
  const [vencido, setVencido] = useState('0');
  const [error, setError] = React.useState(false);
  const [mensagemErro, setMensagemErro] = React.useState('');
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    setCarregando(true);
    api_multipague_cobranca
      .get(
        `/cobranca-simples/resumo?cliente=${localStorage.getItem(
          'id-cliente',
        )}&clienteConta=${infoRedux.contaPadrao.id}`,
      )
      .then(response => {
        setVencendoHoje(response.data.total.vencendoHoje);
        setAVencer(response.data.total.aVencer);
        setVencido(response.data.total.vencido);
        setCarregando(false);
      })
      .catch(err => {
        setCarregando(false);
        setError(true);
        setMensagemErro(
          err?.response?.data?.mensagem
            ? err?.response?.data?.mensagem
            : 'Não foi possível conectar-se ao servidor',
        );
      });
  }, [infoRedux]);

  return (
    <DashboardCard
      title={'Cobrança Simples'}
      carregando={carregando}
      children={
        <>
          {error ? (
            <MessageErrorComponent mensagemErro={mensagemErro} />
          ) : (
            <>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: theme.palette.text.primary,
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                  }}
                >
                  <AiOutlineCheckCircle
                    size={25}
                    style={{ paddingRight: '5px' }}
                    color={'green'}
                  />
                  Vencendo hoje:
                </Typography>
                {vencendoHoje == 0
                  ? 'R$ 0,00'
                  : formattedMoney(parseFloat(vencendoHoje))}
              </Box>
              <Divider />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: theme.palette.text.primary,
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                  }}
                >
                  <AiOutlineExclamationCircle
                    size={25}
                    style={{ paddingRight: '5px' }}
                    color={'orange'}
                  />
                  À vencer:
                </Typography>
                {aVencer == 0 ? 'R$ 0,00' : formattedMoney(parseFloat(aVencer))}
              </Box>
              <Divider />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: theme.palette.text.primary,
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                  }}
                >
                  <AiOutlineExclamationCircle
                    size={25}
                    style={{ paddingRight: '5px' }}
                    color={'red'}
                  />
                  Vencidas:
                </Typography>
                {vencido == 0 ? 'R$ 0,00' : formattedMoney(parseFloat(vencido))}
              </Box>
            </>
          )}
        </>
      }
      icon={
        <SubtitlesIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
    />
  );
}
