import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, alpha, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../services/api';
import { MdWarning } from 'react-icons/md';

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      &copy; {new Date().getFullYear()}
      {'.'} Grupo Multiplica.
      <Link color="inherit" href="#">
        {' '}
        {useTheme()?.config?.clienteName}
      </Link>
      {' | '}
      Todos os direitos reservados.
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderBottomRightRadius: '20px',
    borderTopRightRadius: '20px',
    width: '50%',
    height: '95vh',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 'auto',
      borderRadius: '20px',
    },
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '80%',
    paddingTop: theme.spacing(1),
  },
  submit: {
    borderRadius: '10px',
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
  boxImage: {
    borderRadius: '20px',
    height: '95vh',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backgroundLeft: {
    position: 'relative',
    height: '95vh',
    width: '100%',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    objectFit: 'cover',
  },
  logoBackground: {
    position: 'absolute',
    top: '50%',
    left: '27%',
    width: '29%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    backgroundColor: 'transparent',
    zIndex: 99,
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const theme = useTheme();
  const [selectContas, setSelectContas] = useState('');
  const [combo, setCombo] = useState('');
  const [messageErrorCombo, setMessageErrorCombo] = useState('');

  useEffect(() => {
    api_multipague_configuracao
      .get('/usuario/clientes')
      .then(response => {
        const selectFilter = response.data;
        setCombo(selectFilter);
      })
      .catch(error => {
        console.log('erro', error.response.data.message);
        setMessageErrorCombo(
          error.response.data.message
            ? error.response.data.message
            : 'Não foi possível conectar-se ao servidor',
        );
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('accounts_cliente');
  }, []);

  async function handleEnter(e) {
    e.preventDefault();
    localStorage.setItem('id-cliente', selectContas);
    api_multipague_conta.interceptors.request.use(function (config) {
      const contextContent = {
        codigoCliente: selectContas,
        codigoConta: null,
        contextoBackoffice: false,
      };
      config.headers.ApplicationContext = window.btoa(
        JSON.stringify(contextContent),
      );
      config.headers.apiService = 'api_multipague_conta select cout';
      return config;
    });

    const { data } = await api_multipague_conta.get(
      '/conta?situacaoConta=ATIVADA',
    );
    let contaPadrao = {};
    if (data.length > 1) {
      const findDefaultAccount = data.filter(data => data.contaPadrao);
      if (findDefaultAccount.length) {
        contaPadrao = findDefaultAccount[0];
      }
    } else {
      contaPadrao = data[0];
    }
    localStorage.setItem('accounts_default', JSON.stringify(contaPadrao));
    location.href = '/';
  }

  return (
    <Box
      spacing={0}
      style={{
        padding: '2.5vh 5vh',
        backgroundColor: alpha(
          theme.palette.appBar[theme.palette.type].bgMenu,
          0.9,
        ),
      }}
    >
      <Box sx={{ width: '100%', height: '95vh', display: 'flex' }}>
        <Box className={classes.boxImage}>
          <img
            src={theme.config.loginImage}
            className={classes.backgroundLeft}
            alt={theme.config.clienteName}
          />
          <img
            className={classes.logoBackground}
            src={theme.config.loginLogoLeft}
            alt={theme.config.clienteName}
          />
        </Box>
        <Container className={classes.container} component="main">
          <CssBaseline />
          <div className={classes.paper} data-testid="paper">
            <Avatar className={classes.avatar}>
              <AccountBalanceWalletIcon
                style={{ color: theme.palette.background.paper }}
              />
            </Avatar>
            {!combo ? (
              <Typography variant="subtitle2">
                {messageErrorCombo ? (
                  <p
                    style={{
                      color: 'red',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <MdWarning
                      size={17}
                      style={{
                        marginRight: 6,
                        marginBottom: 3,
                      }}
                    />
                    {messageErrorCombo}
                  </p>
                ) : (
                  'Aguarde...'
                )}
              </Typography>
            ) : (
              <>
                <Typography
                  style={{ marginTop: '20px' }}
                  component="h1"
                  variant="h5"
                >
                  Qual cliente deseja acessar?
                </Typography>
                <form className={classes.form} noValidate>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullwidth
                    className={classes.formControl}
                  >
                    <Autocomplete
                      id="combo-box-demo"
                      onChange={(event, newValue) => {
                        setSelectContas(newValue.id);
                        localStorage.setItem('contaId', newValue.id);
                      }}
                      options={combo}
                      getOptionLabel={option => option.nome}
                      style={{ width: '100%' }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Selecione aqui"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                  {selectContas == '' ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      disabled
                    >
                      <Typography
                        style={{
                          color: theme.palette.background.paper,
                          lineHeight: 'normal',
                          fontWeight: 900,
                        }}
                        color={theme.palette.text.primary}
                      >
                        Entrar
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={e => handleEnter(e)}
                    >
                      <Typography
                        style={{
                          color: theme.palette.background.paper,
                          lineHeight: 'normal',
                          fontWeight: 900,
                        }}
                        color={theme.palette.text.primary}
                      >
                        Entrar
                      </Typography>
                    </Button>
                  )}

                  <Grid container>
                    <Grid item xs>
                      <Link href="/login" variant="body2">
                        Voltar
                      </Link>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                </form>
              </>
            )}
          </div>
          <Box mt={7}>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
