import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { MSAMBIENTE, MSBASEURL } from '../util/constants';

export const apiServices = require('./services.json');
const token = localStorage.getItem('tkn-access');
const contaPadrao = JSON.parse(localStorage.getItem('accounts_default'));
const contextContent = {
  codigoCliente: contaPadrao?.cliente?.id || localStorage.getItem('id-cliente'),
  codigoConta: contaPadrao?.id || null,
  contextoBackoffice: false,
};
const contextContentString = JSON.stringify(contextContent);

export const getService = service => {
  const keys = Object.keys(apiServices);
  if (!keys.includes(service)) {
    console.error('RequestApi', `O endpoint ${service} não existe`);
    throw new Error(`O endpoint ${service} não existe`);
  }
  const api = apiServices[service];

  if (!api[MSAMBIENTE]) {
    console.error(
      'RequestApi',
      `O serviço ${service} não existe para o ambiente ${MSAMBIENTE}  `,
    );
    throw new Error(
      `O serviço ${service} não existe para o ambiente ${MSAMBIENTE}  `,
    );
  }
  return `${MSBASEURL}${api[MSAMBIENTE]}`;
};

export const createApiService = (service, headers = {}) => {
  const apiAxios = axios.create({
    baseURL: getService(service),
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: 'application/json',
      IdempotencyKey: uuidv4(),
      ApplicationContext: window.btoa(contextContentString),
      ...headers,
    },
  });
  apiAxios.interceptors.request.use(function (config) {
    config.headers.apiService = service;
    return config;
  });

  apiAxios.interceptors.response.use(
    function (response) {
      return response;
    },
    error => {
      try {
        //solução provisória para o problema de redirecionamento (em observação):
        if (
          error.response?.status === 401 ||
          (error.response === undefined && error.request?.status === 0)
        ) {
          // JWT INVALIDOOO
          window.location.href = '/login?error=Logar novamente';
        }
      } catch (e) {}
      console.error('RequestApi', error.response.data);
      return Promise.reject(error);
    },
  );
  return apiAxios;
};
