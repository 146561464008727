import React from 'react';
import { FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa';
import ReactExport from 'react-export-excel';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  alpha,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import { useState } from 'react';
import { MdCloudDownload } from 'react-icons/md';
import { useStyles } from './styles';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExcelGrid = ({
  handlePDFCSV = {
    downloadPDF: () => {},
    downloadExcel: () => {},
    rowsExcel: [],
    ExcelColumn: [],
    carregaExcel: false,
    carregaIconExcel: false,
    title,
  },
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [showListExport, setShowListExport] = useState(false);
  return (
    <Grid
      item
      spacing={3}
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
      }}
    >
      <Grid spacing={3} style={{ position: 'relative' }}>
        <Tooltip title="Exportar" placement="bottom">
          <IconButton
            variant="contained"
            className={classes.hoverIconButton}
            color="primary"
            onClick={() => setShowListExport(!showListExport)}
          >
            <MdCloudDownload
              color={theme.palette.getContrastText(
                alpha(theme.palette.appBar[theme.palette.type].bgMenu, 0.4),
              )}
            />
          </IconButton>
        </Tooltip>
        {showListExport ? (
          <List
            style={{
              border: `1px solid ${alpha(theme.palette.primary.dark, 0.2)}`,
              boxShadow: `0px 8px 45px -15px ${alpha(
                theme.palette.primary.dark,
                0.2,
              )}`,
              borderRadius: '10px',
              backgroundColor: theme.palette.background.paper,
              position: 'absolute',
              zIndex: 99999,
              top: '110%',
              flexDirection: 'row',
              right: 0,
              display: 'flex',
            }}
          >
            <ListItem
              alignItems="flex-end"
              onClick={() => handlePDFCSV.downloadPDF()}
            >
              <IconButton style={{ borderRadius: '2px' }}>PDF</IconButton>
            </ListItem>
            <ListItem>
              {!handlePDFCSV.carregaExcel && (
                <IconButton
                  onClick={() => handlePDFCSV.downloadExcel()}
                  style={{
                    borderRadius: '2px',
                    marginRight: '30px',
                  }}
                >
                  CARREGAR CSV
                </IconButton>
              )}
              {handlePDFCSV.carregaExcel && (
                <ExcelFile
                  element={
                    <IconButton style={{ borderRadius: '2px' }}>
                      {!handlePDFCSV.carregaIconExcel ? (
                        'CSV'
                      ) : (
                        <CircularProgress
                          style={{ margin: '0 1.2rem 0' }}
                          size={20}
                        />
                      )}
                    </IconButton>
                  }
                >
                  <ExcelSheet
                    data={handlePDFCSV?.rowsExcel}
                    name={handlePDFCSV.title}
                  >
                    {handlePDFCSV.ExcelColumn.map(item => (
                      <ExcelColumn label={item.label} value={item.value} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
              )}
            </ListItem>
          </List>
        ) : null}
      </Grid>
    </Grid>
  );
};
