import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api_multipague_conta } from '../../../services/api';
import { hideLoading, showLoading } from '../loading/actions';
import { listarUserRequest } from '../user/actions';
import {
  listarContasError,
  listarContasSuccess,
  setContaPadrao,
} from './actions';
import { toastComponentError } from '../../../components/Toast';

function* listarContas({ payload }) {
  console.log('Payload', payload);
  try {
    yield put(showLoading(true));

    const verifyExistingAccount = JSON.parse(
      sessionStorage.getItem('accounts_cliente'),
    );
    console.log('item contas - verifyExistingAccount', verifyExistingAccount);
    if (verifyExistingAccount) {
      yield put(listarContasSuccess(verifyExistingAccount));
      yield put(setContaPadrao(verifyExistingAccount));
      yield put(listarUserRequest(verifyExistingAccount[0].cliente.id));
      return;
    }

    const { data } = yield call(
      api_multipague_conta.get,
      `/conta?situacaoConta=ATIVADA`,
      // `/conta/${payload}?situacaoConta=ATIVADA`,
    );

    console.log('console depois da chamada contas');
    yield put(hideLoading(false));

    if (data.length) {
      yield put(listarContasSuccess(data));
      yield put(setContaPadrao(data));
      yield put(listarUserRequest(payload));
      // yield put(listarUserRequest(data[0].cliente.id));
    } else {
      yield put(listarContasError());
    }
  } catch (err) {
    console.log('deu pau no sagas');
    yield put(hideLoading(false));
    toastComponentError(
      ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
    );
    yield put(listarContasError());
  }
}

export default all([takeLatest('LISTAR_CONTAS_REQUEST', listarContas)]);
