import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { api_multipague_cobranca } from '../../../src/services/api';
import { api_multipague_cobranca_cnab } from '../../../src/services/api';
import { Cancel } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const ListaTransferencia = () => {
  const history = useHistory();
  const [rowCount, setRowCount] = useState();
  const [textNoRows, setTextNoRows] = useState('');
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [rowId, setRowId] = useState(0);
  const date = new Date();
  const [dataDe, setDataDe] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataAte, setDataAte] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataPagDe, setDataPagDe] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataPagAte, setDataPagAte] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    ),
  );
  const [nomeFavorecido, setNomeFavorecido] = useState('');
  const [codigoBarras, setCodigoBarras] = useState('');

  const columnsTable = [
    { field: 'nome', headerName: 'Nome', flex: 1 },
    {
      field: 'bancos',
      headerName: 'Banco',
      type: 'string',
      flex: 1,
    },
    {
      field: 'dataCadastros',
      headerName: 'Data de Cadastro',
      type: 'string',
      flex: 1,
    },
    {
      field: 'dataPagamentos',
      headerName: 'Data de Pagamento',
      type: 'string',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      type: 'string',
      flex: 1,
      renderCell: event => {
        return (
          <>
            {event.row.status == 'REJEITADO' ? (
              <>
                <p>{event.row.status}</p>
                <Tooltip title={event.row.erro.mensagemErro}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              </>
            ) : (
              <>
                <p>{event.row.status}</p>
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'valor',
      headerName: 'Valor',
      flex: 1,
    },
    {
      field: 'cancelar',
      headerName: 'Cancelar',
      align: 'center',
      flex: 1,
      renderCell: event => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            {event.row.situacao === 'PENDENTE' ? (
              <Tooltip title="Cancelar">
                <button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {loadingCancel && event.row.id === rowId ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Cancel
                      onClick={() => handleCancel(event.row)}
                      color="primary"
                      size={24}
                    />
                  )}
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Cancelamento não permitido">
                <button
                  disabled
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Cancel color="disabled" size={24} />
                </button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (contaPadrao) {
      pesquisar();
    }
  }, [contaPadrao]);

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
    pesquisar(page, rowsState.pageSize);
  };

  function limpar() {
    setNomeFavorecido(''),
      setDataDe(''),
      setDataAte(''),
      setDataPagDe(''),
      setDataPagAte('');
  }

  function pesquisar(page = 0, pageSize = 10) {
    const idCliente =
      localStorage.getItem('id-cliente') || localStorage.getId('contaId');
    let config = {
      headers: {
        ContentType: 'application/paged+json',
      },
    };
    setRowsState(prev => ({ ...prev, page }));
    setLoadingTable(true);
    api_multipague_cobranca_cnab
      .get(
        `/boleto?cliente=${idCliente}&clienteConta=${contaPadrao.id}&page=${page}&size=${pageSize}&nomeBeneficiario=${nomeFavorecido}&codigoBarras=${codigoBarras}&dataCadastroInicial=${dataDe}&dataCadastroFinal=${dataAte}&dataPagamentoInicial=${dataPagDe}&dataPagamentoFinal=${dataPagAte}&sort=DESC`,
        config,
      )
      .then(response => {
        setLoadingTable(false);
        const qtdItems = response.data.totalElements;
        const formattedData = response.data.content.map(dados => ({
          id: dados.id,
          nome:
            dados.nomeBeneficiario != null
              ? dados.nomeBeneficiario
              : 'Sem informações',
          codigoBarras: dados.codigoBarras,
          dataCadastros: moment(dados.dataCadastro).format('DD/MM/YYYY'),
          dataPagamentos: moment(dados.dataPagamento).format('DD/MM/YYYY'),
          bancos: dados.banco != null ? dados.banco.descricao : ' ',
          valor: dados.valorTitulo.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          ...dados,
        }));
        setRowCount(qtdItems);
        setRowsTable(formattedData);
      })
      .catch(err => {
        setLoadingTable(false);
        const textError =
          err.response.status === 404 ? 'Nenhum boleto encontrado' : null;
        setTextNoRows(textError);
        setRowCount(0);
        setRowsTable([]);
        console.log(err);
      });
  }

  const handlePageSize = pageSize => {
    setRowsState({ page: 0, pageSize: pageSize });
    pesquisar(0, pageSize);
  };

  const handleCancel = value => {
    setRowId(value.id);
    const idTransferencia = value.id;
    const cancelCliente = value?.clienteConta?.cliente?.id;
    setLoadingCancel(true);
    api_multipague_cobranca
      .put(`/boleto/${idTransferencia}/cancelar?cliente=${cancelCliente}`)
      .then(response => {
        toastComponentSuccess('Evento cancelado com sucesso!');
        setLoadingCancel(false);
        pesquisar();
      })
      .catch(error => {
        setLoadingCancel(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Nome"
            value={nomeFavorecido}
            onChange={e => {
              setNomeFavorecido(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataDe"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - De:"
            value={dataDe}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataDe(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataAte"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - Até:"
            value={dataAte}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataAte(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataDe"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - De:"
            value={dataPagDe}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
            onChange={e => {
              setDataPagDe(e.target.value);
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataAte"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - Até:"
            value={dataPagAte}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
            onChange={e => {
              setDataPagAte(e.target.value);
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Cód. de Barras"
            onChange={e => {
              setCodigoBarras(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
    ],
    searchButton: {
      searchButton: () => pesquisar(0, rowsState.pageSize),
    },
    cleanButton: {
      cleanButton: () => limpar(),
    },
  };

  return (
    <DataGridPage
      title="Lista de Boletos"
      crumb={[{ name: 'Dashboard', link: '/' }, { name: 'Boletos' }]}
      newRegister={() => history.push('/Pagamento/Boleto')}
      formData={filterInputs}
      rows={rowsTable}
      key={rowsTable.key}
      rowCount={rowCount}
      setDatagrid={setRowsState}
      columns={columnsTable}
      loading={loadingTable}
      minHeight={'600px'}
      {...rowsState}
      textNoRows={textNoRows}
      onPageChangeFunction={onPage}
      onPageSizeChangeFunction={handlePageSize}
    />
  );
};

export default ListaTransferencia;
