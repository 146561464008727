import { Form } from '@unform/web';
import styled from 'styled-components';
import ContainerComponent from '../Container';
import DateInput from '../InputDate';
import InputTypeText from '../InputText';

export const Data = styled(DateInput)`
  width: 100%;
  margin: 0 !important;

  @media only screen and (min-width: 1024px) {
    margin: 0 !important;
  }
  @media only screen and (min-width: 1440px) {
    margin: 0 !important;
  }
`;

export const ContainerTotal = styled(ContainerComponent)``;

export const Container = styled.div`
  display: flex;
  gap: 20px;
  @media only screen and (min-width: 1200px) {
  }
`;
export const FormContainer = styled(Form)`
  height: auto;
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  gap: 1rem;
  & .MuiGrid-item {
    height: auto !important;
  }
  @media only screen and (max-width: 700px) {
    & .item-full-mobile{
      width:100% !important;
    }
  }
`;

export const ContainerBanco = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  flex-direction: row;
  width: 100%;
  div {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1200px) {
    /* display: flex;
    justify-content: flex-end;
    align-items: center; */
  }
`;

export const ContainerPrimario = styled.div`
  display: grid;
  grid-template-columns: auto;

  row-gap: 20px;
  column-gap: 20px;
  align-items: flex-start;
  @media only screen and (min-width: 1200px) {
  }
`;
export const ContainerInfosConta = styled.div`
  display: grid;
  grid-template-columns: auto;
  box-sizing: border-box;
  row-gap: 20px;
  column-gap: 20px;
  align-items: center;
  @media only screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: auto 40%;
    box-sizing: border-box;
    row-gap: 20px;
    /* column-gap: 20px; */
    align-items: center;
  }
`;
export const ContainerSecundario = styled.div`
  display: grid;
  grid-template-columns: auto;
  row-gap: 20px;
  column-gap: 0px;

  align-items: center;
  @media only screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 50px;
    row-gap: 0px;
    align-items: center;
  }
`;
export const ContainerTercirario = styled.div`
  display:flex;
  gap:1rem;
  @media only screen and (max-width: 700px) {
    flex-wrap:wrap;

  }
`;

export const ContainerAgencia = styled.div`
  display: flex;
  grid-template-columns: auto;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  @media only screen and (min-width: 1200px) {
  }
`;
export const ContainerConta = styled.div`
  display: grid;
  grid-template-columns: 100px 50px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  @media only screen and (min-width: 1200px) {
  }
`;

export const ContainerRadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
`;
export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const InputText = styled(InputTypeText)`
  width: auto;
  @media only screen and (min-width: 1200px) {
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 1200px) {
  }
`;
export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  @media only screen and (min-width: 1200px) {
  }
`;
export const ContainerInfoBanco = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  row-gap: 20px;
  width: 100%;
  @media only screen and (min-width: 1200px) {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    margin-bottom: 0px;
  }
`;
export const ContainerFavoritoAtivo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (min-width: 1200px) {
  }
`;
