import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Alert } from '@material-ui/lab/';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import '../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import formattedMoney from '../../util/FormatteMoney';
import { Tooltip } from '@material-ui/core';
import { saldoRequest } from '../../store/modules/saldo/actions';
import { useStyles } from '.';
import { useTheme, alpha } from '@material-ui/core/styles';
import { IoPersonCircle } from 'react-icons/io5';
import { MdKeyboardArrowDown } from 'react-icons/md';
import {
  Brightness5,
  Brightness2,
  VisibilityOff,
  ErrorRounded,
  Visibility,
} from '@material-ui/icons';
import { changeModeDark } from '../../store/modules/app';

export default ({
  open,
  localEmpresa,
  saldo,
  handleProfileMenuOpen,
  infoRedux,
  anchorElProfile,
  handleCloseProfile,
  newHoraRefresh,
  horaexpiracao,
  handleClickProfile,
  handleOpenAccount,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { modeTheme } = useSelector(state => state.app);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 30,
      }}
    >
      <Grid item className={classes.topBarMobileleft}>
        <IconButton
          onClick={handleProfileMenuOpen}
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountBalanceIcon />
        </IconButton>
        <Typography
          variant="body2"
          style={{
            fontSize: '11px',
          }}
        >
          <Typography
            style={{
              flexGrow: '2',
              textTransform: 'uppercase',
              fontSize: '11px',
            }}
            color="inherit"
            variant="h5"
            noWrap
            class="font-titulo"
          >
            {('Banco: ', infoRedux.contaPadrao.banco?.nome)}{' '}
            {('Ag: ', infoRedux.contaPadrao?.agencia)}{' '}
            {('Conta: ', infoRedux.contaPadrao?.conta)}
            {'-'}
            {infoRedux.contaPadrao?.digitoConta}
          </Typography>
          Saldo:{' '}
          {saldo?.showSaldo
            ? saldo?.saldo?.saldo
              ? ` ${formattedMoney(parseFloat(saldo?.saldo?.saldo))}${' '}`
              : 'R$ 0,00 '
            : null}
          <Tooltip
            placement="right"
            title={
              saldo === 'erro'
                ? 'Não foi possível conectar-se ao servidor'
                : !saldo?.showSaldo
                ? 'Visualizar saldo'
                : 'Ocultar saldo'
            }
          >
            <IconButton
              style={{ padding: 0 }}
              color="inherit"
              onClick={() => {
                if (infoRedux) {
                  dispatch(
                    saldoRequest(infoRedux.contaPadrao?.id, !saldo?.showSaldo),
                  );
                }
              }}
            >
              {saldo === 'erro' ? (
                <ErrorRounded />
              ) : saldo?.showSaldo ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginLeft: '20px',
        }}
      >
        <Tooltip
          title={
            modeTheme === 'dark'
              ? 'Desativar tema escuro'
              : 'Ativar tema escuro'
          }
        >
          <IconButton
            color="primary"
            onClick={() =>
              dispatch(changeModeDark(modeTheme === 'dark' ? false : true))
            }
          >
            {modeTheme === 'dark' ? (
              <Brightness5
                style={{
                  color: theme.palette.background.default,
                }}
              />
            ) : (
              <Brightness2
                style={{
                  color: theme.palette.background.paper,
                }}
              />
            )}
          </IconButton>
        </Tooltip>
        <IconButton color="inherit" onClick={handleClickProfile}>
          <AccountCircle />
        </IconButton>
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorElProfile}
        keepMounted
        open={Boolean(anchorElProfile)}
        onClose={handleCloseProfile}
      >
        <Alert
          severity="info"
          style={{ marginLeft: '10px', marginRight: '10px' }}
        >
          Seu acesso expira às{' '}
          {newHoraRefresh === null ? horaexpiracao : newHoraRefresh}
        </Alert>

        <MenuItem onClick={handleOpenAccount}>
          <AccountBalanceWalletIcon color="primary" />
          <Typography component="body2" color="primary">
            {localEmpresa}
          </Typography>
        </MenuItem>
        <Link to="/meu-perfil">
          <MenuItem onClick={handleCloseProfile}>
            <AccountCircle color="primary" />
            <Typography component="body2" color="primary">
              Minha conta
            </Typography>
          </MenuItem>
        </Link>
        <Link to="/new-financial-password">
          <MenuItem>
            <LockIcon color="primary" />
            <Typography component="body2" color="primary">
              Alterar senhas
            </Typography>
          </MenuItem>
        </Link>
        <Link to="/usuarios">
          <MenuItem>
            <PeopleIcon color="primary" />
            <Typography component="body2" color="primary">
              Usuários
            </Typography>
          </MenuItem>
        </Link>
        <Link to="/login">
          <MenuItem>
            <ExitToAppIcon color="primary" />
            <Typography component="body2" color="primary">
              Sair
            </Typography>
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
};
