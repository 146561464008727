import styled, { keyframes, css } from 'styled-components';
import Tooltip from '../../tooltip';

export const Container = styled.button`
  cursor: pointer;
  -webkit-appearance: button-bevel;
  -moz-appearance: button;
  border-width: 1;
  border-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.largura ? props.largura : '76px')};
  height: ${props => (props.altura ? props.altura : '76px')};
  position: relative;
  bottom: 5%;
  right: 5.5%;
  border-radius: 100px;

  ${({ error }) =>
    error
      ? css`
          background-color: red;
          &:hover {
            opacity: 1;
            div {
              span {
                opacity: 1;
                visibility: visible;
                text-align: center;
              }
            }
          }
        `
      : css`
          background-color: blue;

          &:hover {
            background-color: green;
            opacity: 1;
          }
        `}
`;

export const Icon = styled.i`
  /* ${props =>
    props.iconName === 'icon-paper-plane' &&
    css`
      transform: rotate(35deg);
    `}; */
  font-size: 24px;

  ${({ error }) =>
    error
      ? css`
          color: grey;
        `
      : css`
          color: grey;
        `}
`;

export const ErroContainer = styled(Tooltip)`
  width: auto;
  height: auto;
  span {
    background-color: lightgreen;

    color: lightblue;
    border-radius: 4px;
    width: 175px;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      border-color: black transparent;
    }
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  object {
    display: flex;
  }
  svg {
    animation: ${rotate} 0.8s linear infinite;
    color: blue;
  }
`;
