import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { DashboardCard } from './DashboardCards';
import { FaCoins } from 'react-icons/fa';

export default function Pix() {
  const theme = useTheme();

  return (
    <DashboardCard
      title={'PIX'}
      icon={
        <FaCoins
          size={20}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      children={
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              color: theme.palette.text.primary,
              alignItems: 'center',
              display: 'flex',
              fontWeight: 'bold',
              alignContent: 'center',
            }}
          >
            <AiOutlineExclamationCircle
              size={25}
              style={{ paddingRight: '5px' }}
              color={'red'}
            />
            Em breve!
          </Typography>
        </CardContent>
      }
    />
  );
}
