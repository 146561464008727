export function saldoRequest(idConta, showSaldo) {
  return {
    type: 'SALDO_REQUEST',
    payload: { saldo: idConta, showSaldo: showSaldo },
  };
}
export function saldoSuccess(saldo, showSaldo) {
  return {
    type: 'SALDO_REQUEST_SUCCESS',
    payload: { saldo, showSaldo },
  };
}

export function saldoError(err) {
  return {
    type: 'SALDO_ERROR',
    payload: err,
  };
}
