import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import Wrapper from '../../components/Wrapper';
import Box from '@material-ui/core/Box';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    borderRadius: '20px',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textUpercase: {
    textTransform: 'uppercase',
  },
}));

export default function Novidades() {
  const classes = useStyles();

  return (
    <Wrapper
      title="Novidades do Sistema"
      crumb={[{ name: 'Dashboard', link: '/' }]}
    >
      <CssBaseline />
      <Container maxWidth="xl" component="main" className={classes.heroContent}>
        <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 05.10.23
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4 className={classes.textUpercase}>
                      CNAB remessa (registro de boletos)
                    </h4>
                    No menu Cob. Simples &gt; Acompanhar CNAB, a coluna situação
                    se refere ao status da importação do arquivo CNAB. Em ações
                    &gt; "Acompanhar o processamento", a contagem das instruções
                    dos boletos ficará inicialmente como "aguardando
                    processamento" e, finalizado, aparecerá "sucesso" ou "erro".
                    Para os erros, em Ações &gt; Detalhes, será possível a
                    visualização do erro do boleto e correção.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 15.09.23
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Validação de Dados de Favorecido
                    </h4>
                    Na nova versão, somente será necessário o cadastro do CNPJ
                    ou CPF do favorecido, e automaticamente, será preenchido a
                    razão social ou nome respectivo ao documento
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Alteração de Favorecido Bloqueada
                    </h4>
                    Para corrigir um favorecido cadastrado indevidamente, será
                    necessária a inativação do favorecido e novo cadastro
                    correto
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 01.09.23
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Aprovação ou Rejeição na Esteira
                    </h4>
                    Para a aprovação ou rejeição de uma TED, você deve clicar no
                    positivo ou negativo, incluir o parecer, incluir a senha
                    financeira e salvar
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Prorrogação de Boleto
                    </h4>
                    Executar pelo Menu Cob. Simples &gt; Consultar Boleto&gt;
                    Editar
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Agendamento de TED (após as 17h)
                    </h4>
                    Ajustado para a conclusão de inclusão para agendamento no
                    dia seguinte ou qualquer data
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Menu Transferência TED
                    </h4>
                    Inclusão da informação de rejeição de TED e motivo
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Cancelamento de Lançamento de TED/Boleto/Pagamento
                      Convênio
                    </h4>
                    Ajustado para o CPF responsável pela inclusão conseguir
                    concluir o cancelamento do lançamento
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 07.06.23
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Atualização de saldo fixo
                    </h4>
                    Atualizando na barra fixa, de acordo com as movimentações do
                    dia
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Cancelamento de TED
                    </h4>
                    No menu Transferência &gt; TED: permitido o cancelamento do
                    usuário que incluiu a TED na plataforma
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>Filtro CNAB</h4>
                    Adicionado filtro de CNAB no menu Cob.Simples &gt; Consultar
                    Boleto
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 15.05.23
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Comprovante de TED em nova aba
                    </h4>
                    Assim que liberada a TED, caso não tenha nenhuma trava de
                    esteira de BackOffice ou de esteira do MFE, o comprovante de
                    TED sairá automaticamente, em uma nova aba do navegador
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Horário e responsável pela aprovação de TED via esteira
                    </h4>
                    Durante o processo e, após a finalização da liberação da
                    TED, é possível verificar na parte de TED &gt; Histórico, o
                    responsável pela aprovação e horário
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      VISUALIZAÇÃO DE TEDS NA ESTEIRA, PARA USUÁRIO OPERADOR
                    </h4>
                    Conta com esteira de aprovação, no cenário de assinatura
                    solidária, o usuário operador conseguirá visualizar o
                    processo de aprovação das TEDs lançadas
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>Acompanhar CNAB</h4>
                    Alteração de texto do botão de retorno – agora aparecerá
                    “gerar arquivo retorno”
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 08.05.23
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Saldo fixo no cabeçalho
                    </h4>
                    Caso acessem qualquer tela do {useTheme()?.config?.clienteName}, o saldo ficará
                    disponível para acesso a todo o momento
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Adicionar origem na notificação de TED via e-mail
                    </h4>
                    Ao receber o e-mail de notificação de aprovação de TED
                    pendente, aparecerá a conta de origem da TED lançada
                  </li>
                  <li>
                    <h4 className={classes.textUpercase}>
                      Visualização de TEDs na esteira, para usuário operador
                    </h4>
                    Conta com esteira de aprovação, no cenário de assinatura
                    individual, o usuário operador conseguirá visualizar o
                    processo de aprovação das TEDs lançadas (está em
                    desenvolvimento a melhoria para assinatura solidária)
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 03.06.22
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    {/* <ArrowRightIcon style={{position:'relative', bottom:'-7px'}} /> */}
                    <h4>CARTEIRA PARA REGISTRO BOLETO</h4>
                    Permitido configurar a carteira de registro de boleto
                    informando % de Juros % Multa, anteriormente só era aceito
                    um valor fixo de juros e multa. O percentual irá aplicar
                    sobre o valor do boleto em questão.
                  </li>
                  <li>
                    <h4>EXPORTAÇÃO XLS BOLETOS REGISTRADOS</h4>
                    Ajustado recurso de exportação de boletos registros para o
                    formato XLS.
                  </li>
                  <li>
                    <h4>SITUAÇÃO BOLETO</h4>
                    Adicionado novos campos na tela de situação boleto.
                  </li>
                  <li>
                    <h4>ARQUIVO DE RETORNO PAGAMENTO</h4>
                    Com a confirmação dos pagamentos de boletos, será gerado
                    automaticamente arquivos de retorno no formato CNAB para
                    importação no ERP.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 27.05.22
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4>TOKEN POR USUÁRIO</h4>
                    Adicionado TOKEN por usuário, permitindo uma conta com 2
                    correntistas utilizar o recurso. Cada correntista, com seu
                    login consegue efetivar a transação recebendo o token no seu
                    telefone pessoal.
                  </li>
                  <li>
                    <h4>TOKEN PARA PAGAMENTO EM LOTE</h4>
                    Pagamentos em lote solicitarão o token desde que a conta
                    esteja previamente configurada para envio de TOKEN.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                VERSÃO 20.05.22
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>
                    <h4>TOKEN SMS</h4>
                    Adicionamos mais um recurso de segurança para as transações
                    efetivadas pelo {useTheme()?.config?.clienteName}. Para obter o recurso, é
                    necessário contactar o time de BackOffice e solicitar a
                    ativação em sua conta. Com a conta ativada para uso do
                    TOKEN, as transações de TED e Pagamento de Boleto
                    solicitarão o TOKEN SMS enviado ao telefone celular do
                    usuário que está acessando a aplicação, antes da senha
                    financeira. A transação só será efetivada se o usuário
                    informar o token correto enviado por SMS e a senha
                    financeira válida.
                  </li>
                  <li>
                    <h4>BOLETO CONSUMO</h4>
                    Pagamentos de contas de consumo estão liberados na
                    plataforma.
                  </li>
                  <li>
                    <h4>EXTRATO SALDO DIÁRIO</h4>
                    Adicionado impressão de Comprovante no extrato com saldo
                    diário.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Wrapper>
  );
}
