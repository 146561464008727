import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IoMdMailUnread } from 'react-icons/io';
import { maskCpfCnpj } from '../../util/mask';
import Switch from '@material-ui/core/Switch';
import jwt_decode from 'jwt-decode';
import Tooltip from '@material-ui/core/Tooltip';
import LoadingBtn from '../../components/Buttons/ConfirmButton';
import CPF from '../../components/Inputs/CPF';
import InputText from '../../components/InputText';
import Button from '../../components/Buttons/ConfirmButton';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Grid from '@material-ui/core/Grid';
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonTable, Checks, Formulario } from './styles';
import { useHistory } from 'react-router-dom';

import { MdSearch, MdClear, MdCleaningServices, MdSave } from 'react-icons/md';

import { api_multipague_configuracao } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import SaveIcon from '@material-ui/icons/Save';
import DataGrid from '../../components/DataGrid';

import { useAuthContext } from '../../Context/AuthContext';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  positive: {
    color: theme.palette.primary.main,
  },
  buttonProgress: {
    color: theme.palette.appBar[theme.palette.type].bgMenu,
    marginRight: '5px',
  },
  btnAbrirConta: {
    marginTop: theme.spacing(2),
  },
  divModal: {
    width: 800,
    maxWidth: 800,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: 800,
    maxWidth: 800,
    height: 600,
    textAlign: 'center',
    alignItems: 'center',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperItens: {
    padding: theme.spacing(1, 1, 1),
  },
  formControl: {
    margin: 'auto',
    minWidth: '100%',
    textAlign: 'center',
  },
}));

export const Usuarios = () => {
  const { permissionAdmin } = useAuthContext();
  const classes = useStyles();
  const { contaPadrao } = useSelector(state => state.contas);
  const { userAccount } = useSelector(state => state.user);
  const history = useHistory();
  const formRef = useRef(null);
  const [listaPerfis, setListaPerfis] = useState();
  const dispatch = useDispatch();
  const [rowsTable, setRowsTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const token = localStorage.getItem('tkn-access');
  const { realm_access, permissaoContas } = jwt_decode(token);
  const [loadButton, setLoadButton] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [textNoRows, setTextNoRows] = React.useState('');
  const idConta = localStorage.getItem('idConta');
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const [rowId, setRowId] = useState(null);

  const columnsTable = [
    { field: 'nome', headerName: 'Nome', flex: 2 },
    {
      field: 'login',
      headerName: 'CPF/CNPJ',
      type: 'string',
      flex: 2,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      type: 'string',
      flex: 3,
    },
    {
      field: 'editPerm',
      headerName: 'Ações',
      align: 'center',
      sortable: false,
      flex: 2,
      disableClickEventBubbling: true,
      renderCell: event => {
        const theme = useTheme();
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <>
                {permissionAdmin && (
                  <>
                    <Tooltip
                      title="Editar Permissão"
                      aria-label="editar-permissao"
                    >
                      <ButtonTable
                        onClick={() => {
                          handleOpen(event.row);
                        }}
                      >
                        <VpnKeyIcon
                          size={24}
                          color={theme.palette.primary.main}
                        />
                      </ButtonTable>
                    </Tooltip>
                    <Tooltip title="Editar Usuário" aria-label="editar-usuario">
                      <ButtonTable
                        onClick={() => {
                          handleEditUser(event.row);
                        }}
                      >
                        <PersonIcon
                          size={24}
                          color={theme.palette.primary.main}
                        />
                      </ButtonTable>
                    </Tooltip>
                  </>
                )}
                <Tooltip title="Enviar E-mail" aria-label="send_email">
                  <ButtonTable
                    hasError={!!event.row.codigoErro}
                    onClick={() => {
                      sendEmail(event.row);
                    }}
                  >
                    <IoMdMailUnread
                      size={24}
                      color={theme.palette.primary.main}
                    />
                  </ButtonTable>
                </Tooltip>

                <Tooltip
                  title={
                    !event.row.ativo
                      ? 'Desbloquear Usuário'
                      : 'Bloquear Usuário'
                  }
                  aria-label="block_user"
                >
                  <button
                    style={{ marginLeft: 10 }}
                    onClick={() => blockUser(event.row)}
                  >
                    <FormControlLabel
                      control={
                        <Switch color="primary" checked={event.row.ativo} />
                      }
                    />
                  </button>
                </Tooltip>

                <Tooltip title="Excluir Usuário" aria-label="delete_user">
                  <ButtonTable
                    hasError={!!event.row.codigoErro}
                    onClick={() => {
                      deleteUser(event.row);
                    }}
                  >
                    <CancelIcon size={24} color={theme.palette.primary.main} />
                  </ButtonTable>
                </Tooltip>
              </>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  function deleteUser(event) {
    api_multipague_configuracao
      .delete(
        `usuario/cliente/${userAccount.id}/${event.login.replace(
          /[^0-9]+/g,
          '',
        )}`,
      )
      .then(toastComponentSuccess(`Usuário excluído com sucesso`))
      .catch(error => {
        toastComponentError(
          ` Codigo: ${error.response.data.codigo} - ${error.response.data.mensagem}`,
        );
      });
    listarUsuarios();
  }

  /** contas de usuarios */
  const infoRedux = useSelector(state => state.contas);
  console.log('contasCliente', infoRedux.contasCliente);
  const [roles, setRoles] = useState([]);
  /*** ***/

  function handleEditUser(value) {
    localStorage.setItem('edicao_usuario', JSON.stringify(value));
    history.push('/Edicao');
  }

  const handleOpen = useCallback(value => {
    console.log('useCallback render');
    localStorage.setItem('editarId', value.id ? value.id : null);
    localStorage.setItem('editarLogin', value.login.replace(/[^0-9]+/g, ''));
    localStorage.setItem(
      'editarAtivo',
      JSON.stringify(value.atributos.permissaoContas),
    );
    localStorage.setItem(
      'editarBackoffice',
      value.atributos.profileBackOffice
        ? value.atributos.profileBackOffice[0]
        : null,
    );
    localStorage.setItem('editarPerfil', value.perfis ? value.perfis[0] : null);
    localStorage.setItem(
      'editarMaster',
      value.atributos.master ? value.atributos.master[0] : null,
    );
    localStorage.setItem(
      'editarContasAcesso',
      value.atributos.contasAcesso ? value.atributos.contasAcesso : null,
    );

    const listPerfil = value.atributos.permissaoContas
      ? JSON.parse(value.atributos.permissaoContas)[0].role ==
        'correntista_administrador'
        ? 'correntista_administrador'
        : 'correntista_operador'
      : value?.perfis?.includes('correntista_administrador')
      ? 'correntista_administrador'
      : 'correntista_operador';
    setListaPerfis(listPerfil);

    const permissaoContas = value.atributos.permissaoContas
      ? JSON.parse(value.atributos.permissaoContas[0])
      : [];

    const contas = [];

    permissaoContas.map(item => {
      item.contas.map(c => {
        contas[`conta_${c.id}`] = {
          role: item.role,
          possuiAcesso: c.possuiAcesso,
        };
      });
    });
    setRoles(contas);
    setOpen(true);
  }, []);

  useEffect(() => {
    if (!permissionAdmin) {
      history.push('/');
    }
  }, [permissionAdmin]);

  const handleClose = () => {
    setOpen(false);
  };

  async function sendEmail({ login }) {
    try {
      dispatch(showLoading(true));
      const { data } = await api_multipague_configuracao.post(
        `usuario/senha/definir/enviar-email?username=${login.replace(
          /[^\d]/g,
          '',
        )}`,
      );
      toastComponentSuccess(`E-mail enviado com sucesso`);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
      console.log(err);
    } finally {
      dispatch(hideLoading(false));
    }
  }

  async function blockUser({ ativo, login }) {
    try {
      dispatch(showLoading(true));

      const { data } = await api_multipague_configuracao.put(
        ativo
          ? `/usuario/cliente/${userAccount.id}/${login.replace(
              /[^\d]/g,
              '',
            )}/desativar`
          : `/usuario/cliente/${userAccount.id}/${login.replace(
              /[^\d]/g,
              '',
            )}/ativar`,
      );

      const formatted = rowsTable.map(val => ({
        ...val,
        ativo: val.login === login ? !val.ativo : val.ativo,
      }));

      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
      console.log(err);
    } finally {
      dispatch(hideLoading(false));
    }
  }
  async function filterUser(body) {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_configuracao.get(
        `usuario/login/${userAccount.id}?master=false&nome=${
          body.nome
        }&username=${!!body.cpf ? body.cpf.replace(/[^\d]/g, '') : ''}`,
      );

      const formatted = data.map(usr => ({
        ...usr,
        nome: usr.nome,
        login: maskCpfCnpj(usr.login),
        email: usr.email,
        situacao: usr.ativo,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      setRowsTable([]);
      setTextNoRows(err.response.data.mensagem);
      if (err.response.status != 404) {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  }

  async function listarUsuarios() {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_configuracao.get(
        `usuario/login/${userAccount.id}?master=false`,
      );
      const formatted = data.map(usr => ({
        ...usr,
        nome: usr.nome,
        login: maskCpfCnpj(usr.login),
        email: usr.email,
        situacao: usr.ativo,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  }

  useEffect(() => {
    if (!!userAccount?.id) {
      listarUsuarios();
    }
  }, [userAccount?.id, pageSize]);

  const reset = () => {
    formRef.current.reset();
  };

  const handleMudarPermissoes = () => {
    const login = localStorage.getItem('editarLogin');

    try {
      const contas = Object.keys(roles);
      const data = [];
      console.log('teste will', permissaoContas, contas, roles);
      contas.map(id => {
        data.push({
          contas: [
            {
              id: id.replace('conta_', ''),
              possuiAcesso: roles[id].possuiAcesso,
            },
          ],
          role: roles[id].role,
        });
      });

      console.log('contasCliente', data, `/usuario/${login}/permissoes-conta`);
      api_multipague_configuracao
        .put(`/usuario/${login}/permissoes-conta`, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          toastComponentSuccess(`Permissão alterada com sucesso`);
          handleClose();
          listarUsuarios();
          console.log('contasCliente', response);
        })
        .catch(err => {
          toastComponentError(err.response.data.mensagem);
          console.log('contasCliente err', err);
        });
    } catch (error) {
      console.log('contasCliente error', error);
    }
  };
  return (
    <Wrapper
      title="Usuários"
      crumb={[{ name: 'Dashboard', link: '/dashboard' }, { name: 'Usuários' }]}
      crumbActive="Listar"
      newRegister={() => history.push('/Cadastro/Usuario')}
    >
      <Dialog
        style={{ borderRadius: '20px' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        data-test="div-dialog-principal"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ margin: 'auto', marginBottom: '20px' }}
        >
          Selecione os tipos de permissões
        </DialogTitle>
        <DialogContent
          data-test="div-dialog"
          style={{ margin: 'auto', textAlign: 'center' }}
        >
          {infoRedux.contasCliente.map(item => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '5px',
              }}
            >
              <div style={{ paddingTop: '10px' }}>
                ({item.banco.nome})-{item?.agencia}-{item?.conta}
              </div>

              <div>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    fullWidth
                    style={{ width: '240px' }}
                    size="small"
                    disabled={item.id != idConta}
                    value={roles[`conta_${item.id}`]?.role}
                    onChange={e => {
                      setRoles(old => ({
                        ...old,
                        [`conta_${item.id}`]: {
                          ...roles[`conta_${item.id}`],
                          role: e.target.value,
                        },
                      }));
                    }}
                  >
                    <MenuItem value={listaPerfis}>Perfil Padrão</MenuItem>
                    <MenuItem value="correntista_administrador">
                      Administrador
                    </MenuItem>
                    <MenuItem value="correntista_operador">Operador</MenuItem>
                    <MenuItem value="analista_credito">
                      Analista de Crédito
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    fullWidth
                    style={{ width: '240px' }}
                    size="small"
                    disabled={item.id != idConta}
                    value={roles[`conta_${item.id}`]?.possuiAcesso}
                    defaultValue={roles[`conta_${item.id}`]?.possuiAcesso}
                    onChange={e => {
                      setRoles(old => ({
                        ...old,
                        [`conta_${item.id}`]: {
                          ...roles[`conta_${item.id}`],
                          possuiAcesso: e.target.value,
                        },
                      }));
                    }}
                  >
                    <MenuItem value={true}>Com Permissão</MenuItem>
                    <MenuItem value={false}>Sem Permissão</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions style={{ margin: 'auto' }}>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="200"
            title={'Salvar'}
            style={{ marginTop: '20px' }}
            isLoading={loadButton}
            onClick={handleMudarPermissoes}
            startIcon={!loadButton && <MdSave color="background" />}
          >
            Salvar
          </LoadingBtn>
        </DialogActions>
      </Dialog>
      <Box>
        <Formulario
          style={{ width: '100%', paddingTop: '1rem', paddingBottom: '1.5rem' }}
          ref={formRef}
          onSubmit={filterUser}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={5} sm={12}>
              <InputText
                id="nome"
                name="nome"
                label="Nome"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={5} sm={12}>
              <CPF name="cpf" id="cpf" variant="outlined" label="CPF" />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={2}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                flexWrap: 'wrap',
                [useTheme().breakpoints.down('sm')]: {
                  width: '100%',
                  margin: 'auto',
                },
              }}
            >
              <Button
                title={'Buscar'}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<MdSearch color="background" />}
              >
                Buscar
              </Button>
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={reset}
                title={'Limpar'}
                startIcon={<MdCleaningServices color="background" />}
              >
                Limpar
              </Button>
            </Grid>
          </Grid>
        </Formulario>
        <DataGrid
          rows={rowsTable}
          columns={columnsTable}
          pageSize={pageSize}
          loading={loadingTable}
          height="500px"
          onRowClick={params => setRowId(params.id)}
          rowsPerPageOptions={[10, 20, 50]}
          componentsProps={{
            pagination: { classes: null },
          }}
          textNoRows={textNoRows}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        />
      </Box>
    </Wrapper>
  );
};
