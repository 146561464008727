import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { api_multipague_despesa } from '../../src/services/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { DashboardCard } from './DashboardCards';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';
require('moment/locale/pt-br');

const useStyles = makeStyles(theme => ({
  rootChart: {
    '& svg': {
      // width: '120%',
      '& text': {
        flexWrap: 'wrap',
        fontSize: 11,

        fill: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '& rect:nth-child(1)': {
        fill: theme.palette.background.paper,
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Despesas() {
  const classes = useStyles();
  const theme = useTheme();
  const chartContainerRef = useRef(null);
  const [error, setError] = React.useState(false);
  const [mensagemErro, setMensagemErro] = React.useState('');
  const infoRedux = useSelector(state => state.contas);
  const [value, setValue] = React.useState(0);
  const [infoGraficos, setInfoGraficos] = useState([]);
  const [carregando, setCarregando] = useState(false);

  const optionsChart = {
    title: 'Despesas',
    backgroundColor: 'transparent',
    titleTextStyle: {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    legend: {
      textStyle: {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
    colors: [theme.palette.primary.main, '#394c59', '#2962ff', '#ffab00'],
    legend: { position: 'left' },
  };

  const [options, setOptions] = useState(optionsChart);

  useEffect(() => {
    setOptions(optionsChart);
  }, [theme.palette.type]);

  const [informacoes, setInformacoes] = useState({
    etapasConsolidada: [],
  });

  const dataInicial = moment(new Date().getFullYear() + '-01-01').format(
    'YYYY-MM-DD',
  );
  const dataFinal = moment(new Date().getFullYear() + '-12-31').format(
    'YYYY-MM-DD',
  );

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    if (chartContainer) {
      chartContainer.style.backgroundColor = theme.palette.primary.main;
    }
  }, [theme.palette.primary.main]);

  useEffect(() => {
    if (infoRedux) {
      setCarregando(true);
      const URL = `/despesa?cliente=${localStorage.getItem(
        'id-cliente',
      )}&clienteConta=${
        infoRedux.contaPadrao.id
      }&dataInicial=${dataInicial}&dataFinal=${dataFinal}`;
      api_multipague_despesa
        .get(URL)
        .then(response => {
          setCarregando(false);
          setError(false);
          setInformacoes(response.data);

          const formattedInfo = response.data.dataCentroCustos.reduce(
            (acc, item) => {
              const percentuais = item.centrosCustos.reduce(
                (innerAcc, innerItem) => {
                  innerAcc[innerItem.nomeCentroCusto] =
                    innerItem.valorCentroCusto;
                  return innerAcc;
                },
                {},
              );

              const filledPercentuais = [
                'GERAL',
                'Favorecidos',
                'Pagadores',
                'Clientes',
              ].map(nomeCentroCusto => percentuais[nomeCentroCusto] || 0);

              acc.push([
                moment(item.data).format('MMMM'),
                ...filledPercentuais,
              ]);
              return acc;
            },
            [],
          );

          formattedInfo.unshift([
            'Mês',
            'Geral',
            'Favorecidos',
            'Pagadores',
            'Clientes',
          ]);
          setInfoGraficos(formattedInfo);
        })
        .catch(err => {
          setCarregando(false);
          console.log('deu pau na despesas', err.response.data);
          setError(true);
          setMensagemErro(
            err.response.data.mensagem
              ? err.response.data.mensagem
              : 'Não foi possível conectar-se ao servidor',
          );
        });
    }
  }, [infoRedux]);

  return (
    <DashboardCard
      children={
        <>
          <TabPanel value={value} index={0}>
            {error ? (
              <div
                style={{
                  backgroundColor: 'transparent',
                  transform: 'translate(0%, 200%)',
                }}
              >
                <MessageErrorComponent mensagemErro={mensagemErro} />
              </div>
            ) : infoGraficos.length < 0 ? (
              <CircularProgress color="primary" style={{ margin: 'auto' }} />
            ) : (
              <div ref={chartContainerRef} className={classes.rootChart}>
                <Chart
                  className={classes.rootChart}
                  width={'100%'}
                  chartType="Bar"
                  data={infoGraficos}
                  options={options}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </>
      }
      carregando={carregando}
      icon={
        <AssessmentIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      isChart
      title={'Despesas'}
    />
  );
}

export default Despesas;
