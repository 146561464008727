import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import LocalAtm from '@material-ui/icons/LocalAtm';
import { DashboardCard } from './DashboardCards';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    alignItems: 'center',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
    flexDirection: 'column',
    borderRadius: '15px',
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
    fontWeight: '500',
    fontSize: '14px',
    textTransform: 'none',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function RecipeReviewCard() {
  const theme = useTheme();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  return (
    <DashboardCard
      title={'Folha de Pagamento'}
      icon={
        <LocalAtm
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      children={
        <Typography
          style={{
            color: theme.palette.text.primary,
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            transform: 'translate(35%, 40%)',
            alignContent: 'center',
          }}
        >
          <AiOutlineExclamationCircle
            size={25}
            style={{ paddingRight: '5px' }}
            color={'red'}
          />
          Em breve!
        </Typography>
      }
    />
  );
}
