import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import TopBar from '../../components/TopBar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SaldoAtual from '../../components/SaldoAtualDash';
import SimpleCharges from '../../components/CobrancaSimples';
import FinancialAgenda from '../../components/AgendaFinanceira';
import Pix from '../../components/Pix';
import ConsultaMercado from '../../components/ConsultaMercado';
import moment from 'moment';
import Expenses from '../../components/Despesas';
import GoalsExpenses from '../../components/MetasDespesasConta';
import Running from '../../components/Esteira';
import SimpleAlerts from '../../components/SaldoAtual';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';

import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';

import { useAuthContext } from '../../Context/AuthContext';
import { api_multipague_configuracao } from '../../services/api';
import { Redirect } from 'react-router-dom';

const drawerWidth = 240;

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  rootcontent: {
    '& > *': {
      margin: theme.spacing(2),
      // width: '30%'
    },
  },
  rootcard: {
    minWidth: 275,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperModal: {
    position: 'absolute',
    alignItems: 'center',
    textAlign: 'center',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #fff',
    borderRadius: '15px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '300px',
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
}));

export default function Dashboard() {
  const history = useHistory();
  const token = localStorage.getItem('tkn-access');
  if (!token) {
    window.location.href = '/login';
  }
  const { permissionMercado } = useAuthContext();
  const { contaPadrao } = useSelector(state => state.contas);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { realm_access, permissaoContas } = jwt_decode(token);
  const [verificarPerfilMercado, setVerificarPerfilMercado] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [openModal, setOpenModal] = useState(false);
  const [avisos, setAvisos] = useState([]);

  useEffect(() => {
    const verify = realm_access.roles.filter(
      val => val.toUpperCase() === 'consulta_mercado'.toUpperCase(),
    );

    if (verify.length === 0) {
      setVerificarPerfilMercado(false);
    } else {
      setVerificarPerfilMercado(true);
    }
  }, [realm_access]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function paginaNoticias() {
    history.push('/novidades-da-versao');
  }

  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URLsearch = `/avisos?ativo=${true}&vigenteEm=${moment(
    new Date(),
  ).format('YYYY-MM-DD')}`;

  useEffect(() => {
    // setLoadButton(true)
    api_multipague_configuracao
      .get(URLsearch, { headers: { Authorization: AuthStr } })
      .then(response => {
        // setLoadButton(false)
        // setLoadButtonAccount(false)
        // setLoadButtonEmail(false)
        // setLoadButtonEmail2(false)
        const aquiTable = response.data.content.map(data => ({
          ...data,
          id: data.id,
          ativo: data.ativo,
          caminhoStorage: data.caminhoStorage,
          observacao: data.observacao,
          urlRedirecionamento: data.urlRedirecionamento,
          vigenciaInicio: moment(new Date(data.vigenciaInicio)).format(
            'DD/MM/YYYY',
          ),
          vigenciaFim: moment(new Date(data.vigenciaFim)).format('DD/MM/YYYY'),
        }));
        setAvisos(aquiTable[0]);
        aquiTable.length > 0 && setOpenModal(true);
        console.log('Aviso Aqui: ', aquiTable[0]);
      })
      .catch(error => {
        // setLoadButton(false)
        // if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
        //   toast.error(error.response.data.mensagem, {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   }
        //     );
        // }
      });
  }, []);

  return (
    <Wrapper backButton={false} title="Dashboard">
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img
            src={avisos?.caminhoStorage}
            id="simple-modal-title"
            width="100%"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="h6" component="h6">
              {avisos?.observacao}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            marginTop: '15px',
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            endIcon={<CloseIcon />}
            onClick={handleCloseModal}
          >
            Fechar
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SendIcon />}
            onClick={() => paginaNoticias()}
          >
            Confira
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <SaldoAtual />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <FinancialAgenda />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <SimpleCharges />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          {permissionMercado ? <ConsultaMercado /> : <Pix />}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Expenses />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Running />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <GoalsExpenses />
        </Grid>
      </Grid>
    </Wrapper>
  );
}
