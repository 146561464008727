import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import DateRange from '@material-ui/icons/DateRange';
import formattedMoney from '../../util/FormatteMoney/index';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
    borderRadius: '15px',
    overflow: 'hidden',
  },
  rootIsChart: {
    height: 310,
    textTransform: 'none',
    alignItems: 'start',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
    borderRadius: '15px',
    overflow: 'hidden',
  },
  metaDespesaRoot: {
    borderRadius: '20px',
    boxShadow: 'none',
    border: 'none',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
  },
  alerts: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  alerts2: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  actions: {
    marginTop: '0',
  },
}));

export const DashboardCard = ({
  title,
  icon,
  carregando,
  children,
  isMetaDespesa,
  isChart = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card
      className={
        isMetaDespesa
          ? classes.metaDespesaRoot
          : isChart
          ? classes.rootIsChart
          : classes.root
      }
      elevation={3}
    >
      <CardHeader
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {icon}
            <Typography
              variant="body"
              style={{
                color: theme.palette.primary.main,
                fontSize: '1.2rem',
              }}
              component="h2"
            >
              {title}
            </Typography>
          </div>
        }
        subheader=""
      />
      {!carregando ? (
        <CardContent className={classes.alerts}>{children}</CardContent>
      ) : (
        <CardContent className={classes.alerts2}>
          <CircularProgress />
        </CardContent>
      )}
      <CardActions className={classes.actions}></CardActions>
    </Card>
  );
};
