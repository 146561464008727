import styled from 'styled-components';
import { Form } from '@unform/web';
import { styled as MuyStyled } from '@material-ui/core';

export const ContainerPopUp = styled(Form)`
    position: fixed;
    top:0px;
    left:0px;
    z-index: 99999;
    min-height: 100vh;
    min-width: 100vw;
    width: 100%;
    height: 100%;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.8);
    flex-direction: column;
    overflow: hidden;
    transition: .5s;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    position: absolute;
    z-index: 99999;
    min-height: 100vh;
    min-width: 100vw;
    width: 100%;
    height: 100%;
    display: ${({show}) => show ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.8);
  }
  @media only screen and (min-width: 1440px) {
    position: absolute;
    z-index: 99999;
    min-height: 100vh;
    min-width: 100vw;
    width: 100%;
    height: 100%;
    display: ${({show}) => show ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.8);
  }
`;


export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 30px 0px 0px;



  @media only screen and (min-width: 1024px) {
    padding: 15px;
    svg{
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 1440px) {
  }

`;

export const InfoPopUp = MuyStyled('div')(({ theme }) => {
  return {
    width: '30%',
    borderRadius: '20px',
    transition: '.5s',
    border: `solid 1px ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    top: '20%',
    left: '35%',

    '@media (min-width:  1524px)': {
      top: '20%',
      left: '40%',
    },

    '@media (max-width:  1024px)': {
      width: '50%',
      left: '25%',
    },
    '@media (max-width:  604px)': {
      marginBottom: '50%',
      width: '80%',
      left: '10%',
    },
  };
});
