import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';

import { MdSwapVert } from 'react-icons/md';

import Buttons from '../../components/Buttons/ConfirmButton';
import { InputMoney } from '../../components/Inputs/Money';
import SmsToken from '../../components/Inputs/SmsToken';
import InputText from '../../components/InputText';
import RadioGroup from '../../components/RadioButton';
import {
  api_multipague_transacao,
  api_multipague_conta,
} from '../../services/api';
import { listarTodosFavoritosRequest } from '../../store/modules/beneficiarios/listar_todos/actions';
import { listarCentroCustoRequest } from '../../store/modules/centroCusto/listar/actions';
import { extratoTedRequest } from '../../store/modules/extratoTed/actions';
import { listarFinalidadeRequest } from '../../store/modules/finalidade/actions';
import {
  hideLoadingButton,
  showLoadingButton,
} from '../../store/modules/loading/actions';
import PopUp from '../../components/PopUp';
import DateComponent from '../../components/InputDate';
import getValidationErros from '../../util/FormattedError';
import { showPopUp } from '../../store/modules/popUp/actions';
import Password from '../../components/Password';
import AutoCompleteUnform from '../../components/AutoCompleteUnform';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import Alert from '@material-ui/lab/Alert';
import { useAuthContext } from '../../Context/AuthContext';
import { MdMonetizationOn } from 'react-icons/md';
import {
  Dialog,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { saldoRequest } from '../../store/modules/saldo/actions';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { ThumbUp } from '@material-ui/icons';

const Transferencia = () => {
  const { permissionAdmin, permissionBackEmpresa } = useAuthContext();
  const token = localStorage.getItem('tkn-access');
  const dispatch = useDispatch();
  const submitFormRef = useRef(null);
  const history = useHistory();
  const finalidadesList = useSelector(state => state.finalidades);
  const { contaPadrao } = useSelector(state => state.contas);
  const { loadingButton } = useSelector(state => state.loading);
  const listCentroCusto = useSelector(state => state.centroCusto);
  const { userAccount } = useSelector(state => state.user);
  const listagemFavoritos = useSelector(state => state.listTodosFavoritos);
  const saldo = useSelector(state => state.saldo);
  const [loadingButtonSms, setLoadingButtonSms] = useState(false);
  const [favoritos, setFavoritos] = useState([
    {
      label: 'Carregando...',
    },
  ]);
  const [finalidade, setFinalidade] = useState([]);
  const [centroCusto, setCentroCusto] = useState([]);
  const [dataAgendamento, setDataAgendamento] = useState('');
  const infoRedux = useSelector(state => state.contas);
  const [open, setOpen] = React.useState(false);
  const [valorSms, setValorSms] = useState('');
  const [openModalBMP, setOpenModalBMP] = useState(false);
  const [confirmTedBPM, setConfirmTedBPM] = useState(false);
  const [favorecido, setFavorecido] = useState({
    nome: '',
    banco: '',
  });

  function handleOpen() {
    // Manda o sms pro cliente
    setOpen(true);
    api_multipague_conta
      .post(`/token/send-token/${contaPadrao.id}`)
      .then(response => {
        setOpen(true);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleClose() {
    setOpen(false);
  }

  const [paymentDate, setPaymentDate] = useState([
    {
      label: 'Hoje',
      value: uuidv4(),
      selected: true,
      agendado: false,
    },
    {
      label: 'Agendamento',
      value: uuidv4(),
      selected: false,
      agendado: true,
    },
  ]);
  const [agendamentoFormaPagamento, setAgendamentoFormaPagamento] = useState(
    {},
  );

  const [valorTed, setValorTed] = useState({
    value: 0,
    error: '',
    disabled: false,
  });

  useEffect(() => {
    dispatch(listarFinalidadeRequest());
  }, [dispatch]);

  useEffect(() => {
    if (listagemFavoritos.length) {
      const formatted = listagemFavoritos.map(list => ({
        label: `${list.nomeBanco} - ${list.nome}`,
        value: list.id,
        ...list,
      }));
      console.log(
        '🚀 ~ file: index.js:135 ~ formatted ~ formatted:',
        formatted,
      );
      setFavoritos(formatted);
    }
  }, [listagemFavoritos]);

  useEffect(() => {
    if (userAccount?.id) {
      dispatch(
        listarTodosFavoritosRequest({
          id: userAccount.id,
          ativo: true,
          situacao: 'APROVADO',
          idClienteConta: contaPadrao.id,
        }),
      );
      dispatch(listarCentroCustoRequest(userAccount?.id));
    }
  }, [userAccount]);

  useEffect(() => {
    const formatted = finalidadesList.map(data => ({
      ...data,
      label: data.descricao,
      value: data.id,
    }));

    setFinalidade(formatted);
  }, [finalidadesList]);

  useEffect(() => {
    const formatted = listCentroCusto.map(data => ({
      ...data,
      label: data.descricao,
      value: data.id,
    }));
    setCentroCusto(formatted);
  }, [listCentroCusto]);

  const mostrarDataAgendamento = useMemo(() => {
    if (
      agendamentoFormaPagamento.agendado &&
      agendamentoFormaPagamento.selected
    ) {
      return (
        <DateComponent
          id="data-pagamento-agendado"
          label="Agendar Pagamento"
          name="agendamento"
          callback={setDataAgendamento}
          value={dataAgendamento}
          defaultValue={dataAgendamento}
        />
      );
    }
  }, [agendamentoFormaPagamento, dataAgendamento]);

  function selecionarDiaPagamento(data) {
    const dataSelecionada = paymentDate.map(val =>
      val.value === data
        ? {
            ...val,
            selected: true,
          }
        : {
            ...val,
            selected: false,
          },
    );
    const filterDalaSelecionada = dataSelecionada.filter(date => date.selected);
    setAgendamentoFormaPagamento(filterDalaSelecionada[0]);
    setPaymentDate(dataSelecionada);
  }

  async function submitData() {
    try {
      const schema = Yup.object().shape({
        idFavorecido: Yup.string().required('Campo Obrigatório'),
        idFinalidade: Yup.string().required('Finalidade é obrigatória'),
      });

      await schema.validate(submitFormRef.current.getData(), {
        abortEarly: false,
      });
      if (valorTed.value < 1) {
        setValorTed({
          ...valorTed,
          error: 'Digite um valor',
        });
        return;
      }

      if (!permissionAdmin) {
        submitTed('');
      } else if (
        permissionAdmin &&
        infoRedux.contaPadrao?.tokenPorTransacao == true
      ) {
        handleOpen();
      } else {
        dispatch(showPopUp(true));
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErros(err);
        submitFormRef.current?.setErrors(erros);
        if (valorTed.value < 2) {
          setValorTed({
            ...valorTed,
            error: 'Digite um valor',
          });
          return;
        }
      } else {
        toastComponentError(
          ` Código: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  }

  async function submitTed(senha) {
    dispatch(showPopUp(false));
    dispatch(showLoadingButton(true));
    const formInfo = submitFormRef.current.getData();
    try {
      const body = {
        ...formInfo,
        dataAgendamento:
          agendamentoFormaPagamento.agendado &&
          agendamentoFormaPagamento.selected
            ? moment(dataAgendamento).format('DD/MM/YYYY')
            : moment(Date.now()).format('DD/MM/YYYY'),

        clienteConta: {
          id: Number(contaPadrao.id),
        },
        valor: valorTed.value,
        centroCusto: {
          id: Number(formInfo.idCentroCusto),
        },
        favorecido: {
          id: Number(formInfo.idFavorecido),
        },
        finalidade: {
          id: Number(formInfo.idFinalidade),
        },
      };

      const { data } = await api_multipague_transacao.post(
        '/transferencia',
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ContentType: 'application/json',
            IdempotencyKey: uuidv4(),
            'x-assinatura-financeira': senha,
            token: valorSms,
          },
        },
      );

      if (data.id) {
        if (data.esteira) {
          toastComponentSuccess('Transferência encaminhada para análise!');
          history.push('/');
        } else {
          if (
            agendamentoFormaPagamento.agendado &&
            agendamentoFormaPagamento.selected
          ) {
            toastComponentSuccess(
              'A transação será efetivada mediante saldo disponível as 8:00 horas da data agendada',
            );
            window.open(`/ComprovanteTransferencia`);
            history.push('/');
          } else {
            if (permissionAdmin) {
              toastComponentSuccess('Transferência realizada com sucesso');
              dispatch(extratoTedRequest(data.id));
              dispatch(saldoRequest(contaPadrao.id, saldo.showSaldo));
              history.push('/');
              window.open(`/ComprovanteTransferencia`);
            } else {
              toastComponentSuccess('Transferência enviada para análise');
              history.push(`/`);
            }
          }
        }
      }
      dispatch(hideLoadingButton(false));
    } catch (err) {
      dispatch(hideLoadingButton(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  function enviarSms() {
    handleClose();
    dispatch(showPopUp(true));
  }

  return (
    <Wrapper
      title="Transferência"
      crumb={[
        {
          name: 'Minhas Contas',
          link: '/myaccounts',
        },
      ]}
    >
      <PopUp>
        <Password callback={data => submitTed(data)} />
      </PopUp>
      <Form ref={submitFormRef}>
        <Box>
          <Alert severity="warning">
            Horário limite para TED até às 17:00 horas
          </Alert>
          <div style={{ height: '1rem' }} />

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Insira o token que você recebeu via SMS
            </DialogTitle>
            <DialogContent style={{ margin: 'auto', textAlign: 'center' }}>
              <SmsToken
                id="outlined-basic"
                variant="outlined"
                placeholder="******"
                size="small"
                type="password"
                name="smsToken"
                inputProps={{ maxLength: 6 }}
                onChange={e => setValorSms(e.target.value)}
              />
            </DialogContent>
            <DialogActions style={{ margin: 'auto' }}>
              <Buttons
                color="primary"
                width={150}
                title={'Prosseguir'}
                style={{ marginTop: '20px', marginBottom: '10px' }}
                onClick={() => enviarSms()}
                isLoading={loadingButtonSms}
                startIcon={
                  <MdMonetizationOn
                    color={useTheme().palette.background.paper}
                  />
                }
              ></Buttons>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openModalBMP}
            onClose={() => setOpenModalBMP(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent style={{ padding: '1.5rem' }}>
              <div style={{ paddingBottom: '10px' }}>
                <Typography variant="body" style={{ fontWeight: 700 }}>
                  Confirma transferência entre contas do mesmo banco?
                </Typography>
              </div>
              <div>
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  Favorecido:
                  <Typography
                    variant="P"
                    style={{ fontWeight: 400, paddingLeft: '.5rem' }}
                  >
                    {favorecido?.nome}
                  </Typography>
                </Typography>
              </div>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Banco:
                <Typography
                  variant="P"
                  style={{ fontWeight: 400, paddingLeft: '.5rem' }}
                >
                  {favorecido?.banco}
                </Typography>
              </Typography>
            </DialogContent>
            <DialogActions style={{ margin: 'auto', padding: 20 }}>
              <Buttons
                color="primary"
                title={'OK'}
                onClick={() => {
                  setConfirmTedBPM(true);
                  setOpenModalBMP(false);
                  submitData();
                }}
                startIcon={
                  <ThumbUp
                    style={{ color: useTheme().palette.background.paper }}
                    color="background"
                  />
                }
              ></Buttons>
            </DialogActions>
          </Dialog>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <AutoCompleteUnform
                name="idFavorecido"
                label="Favorecido"
                renderOption={option => (
                  <ListItem style={{ padding: 0 }}>
                    <ListItemText
                      primary={option.label}
                      secondary={`Agência ${option.agencia} / Conta: ${option.conta}-${option.digitoConta}`}
                    />
                  </ListItem>
                )}
                options={favoritos}
                id="idFavorecido"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoCompleteUnform
                name="idCentroCusto"
                label="Centro de Custo"
                options={centroCusto}
                id="idCentroCusto"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoCompleteUnform
                name="idFinalidade"
                label="Finalidade"
                options={finalidade}
                id="idFinalidade"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputMoney
                value={valorTed.value}
                onChange={val =>
                  setValorTed({
                    ...valorTed,
                    value: val,
                    error: '',
                  })
                }
                error={valorTed.error}
                disabled={valorTed.disabled}
                label="Valor"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RadioGroup
                optionDefault={paymentDate[0].value}
                titulo="Data Pagamento"
                options={paymentDate}
                name="data_pagamento"
                callback={selecionarDiaPagamento}
              />
              {mostrarDataAgendamento}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputText
                id="descricaoHistorico"
                label="Histórico"
                name="descricaoHistorico"
                multiline
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Buttons
                color="primary"
                variant="contained"
                width={100}
                title={'Transferir'}
                isLoading={loadingButton}
                onClick={() => {
                  if (
                    favoritos
                      .filter(
                        item =>
                          item.id ===
                          submitFormRef.current.getData().idFavorecido,
                      )
                      .map(item => item.nrBanco)[0] === 274
                  ) {
                    const favoritosSelected = favoritos
                      .filter(
                        item =>
                          item.id ===
                          submitFormRef.current.getData().idFavorecido,
                      )
                      .map(item => item)[0];
                    setFavorecido({
                      nome: favoritosSelected.nome,
                      banco: `${favoritosSelected.nrBanco} ${favoritosSelected.nomeBanco} / Agência ${favoritosSelected.agencia} / Conta ${favoritosSelected.conta} -
                        ${favoritosSelected.digitoConta}`,
                    });
                    setOpenModalBMP(true);
                  } else {
                    submitData();
                  }
                }}
                type="submit"
                startIcon={<MdSwapVert color={'background'} />}
              ></Buttons>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </Wrapper>
  );
};

export default Transferencia;
