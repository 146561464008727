import { all, takeLatest, call, put } from 'redux-saga/effects';
import { api_multipague_conta } from '../../../services/api';
import { saldoSuccess, saldoError } from './actions';

function* saldo({ payload }) {
  const { saldo, showSaldo } = payload;
  try {
    const { data } = yield call(
      api_multipague_conta.get,
      `/saldo/cliente-conta/${saldo}`,
    );

    if (typeof data !== undefined) {
      yield put(saldoSuccess(data, showSaldo));
    } else {
      yield put(saldoError());
    }
  } catch (err) {
    yield put(saldoError("erro"));
  }
}

export default all([takeLatest('SALDO_REQUEST', saldo)]);
