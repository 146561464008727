import React, { useRef, useState, useEffect, useMemo } from 'react';
import { FormattFloatNumber } from '../../../util/FormatToFloatNumber';
import {
  Container,
  Label,
  Input,
  ContainerInput,
  ContainerError,
  Message,
} from './styles';

export function InputMoney({ onChange, value, error, disabled, label }) {
  const inputRef = useRef(null);

  const [hasFocus, setHasFocus] = useState(false);
  const [valueComponent, setValueComponent] = useState(value);
  const [errorComponent, setErrorComponent] = useState(error);

  useEffect(() => {
   setValueComponent(value)
  },[value])

  useEffect(() => {
    if (hasFocus && !!error) {
      setErrorComponent('');
    }
  }, [hasFocus]);

  useEffect(() => {
    setErrorComponent(error);
  }, [error]);

  return (
    <Container focus={hasFocus} disabled={disabled} error={errorComponent}>
      {!!label && (
        <Label>
          <p>{label}</p>
        </Label>
      )}
      <ContainerInput>
        <Input
          // prefix="R$"
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          decimalSeparator=","
          thousandSeparator="."
          ref={inputRef}
          value={valueComponent}
          // onChange={val => {
          //   onChange(val);
          //   setValueComponent(val);
          // }}
          onChange={val => {
            onChange(Number(FormattFloatNumber(val, false)));
            // setValueComponent(Number(FormattFloatNumber(val, false)));
            setValueComponent(val);
          }}
          disabled={disabled}
        />
      </ContainerInput>
      {!!errorComponent && (
        <ContainerError>
          <Message>{errorComponent}</Message>
        </ContainerError>
      )}
    </Container>
  );
}
