/* eslint-disable */
import React from 'react';
import { GlobalStyle } from './styles';
import Routes from './routes/index';
import {
  createTheme,
  ThemeProvider,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';

import { ptBR } from '@material-ui/core/locale';
import { Provider, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import store from './store';
import Loading from './components/Loading';
import 'react-toastify/dist/ReactToastify.css';
import CountProvider from './Context/CountTime';
import { AccountProvider } from './Context/AccountContext';
import { AuthProvider } from './Context/AuthContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { getTheme } from './theme/api.theme';
import { makeStyles, useTheme } from '@material-ui/core';

const generateClassName = createGenerateClassName({
  productionPrefix: 'solucoes',
  seed: 'mfe',
});

const Main = () => {
  const { modeTheme } = useSelector(state => state.app);
  const [themeApp, setThemeApp] = useState(null);
  useEffect(() => {
    getTheme().then(theme => {
      theme.palette.type = modeTheme;
      const customTheme = createTheme({ ...theme }, ptBR);
      setThemeApp(customTheme);
    });
  }, [modeTheme]);

  const useStyles = makeStyles(theme => ({
    customToast: {
      '& .Toastify__toast': {
        background: themeApp?.palette.background.paper,
      },
      '& .Toastify__toast--error': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.text.primary
            : themeApp?.palette.error.main,
        background: themeApp?.palette.background.paper,
      },
      '& .Toastify__toast--warning': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.text.primary
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& div.Toastify__toast--error div.Toastify__toast-body > div > svg': {
        fill: themeApp?.palette.error.main,
      },
      '& div.Toastify__toast--success div.Toastify__toast-body > div > svg': {
        fill:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.primary.main
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__toast--success': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.text.primary
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__toast--warning': {
        color: '#e4a643',
      },
      '& .Toastify__progress-bar--success': {
        background:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.primary.main
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__progress-bar--error': {
        background: themeApp?.palette.error.dark,
      },
      '& .Toastify__progress-bar--warning': {
        background: '#e4a643',
      },
      '& .Toastify__close-button--success': {
        color:
          themeApp?.palette.type === 'dark'
            ? themeApp?.palette.primary.main
            : themeApp?.palette.appBar[themeApp?.palette.type].bgMenu,
      },
      '& .Toastify__close-button--error': {
        color: themeApp?.palette.text.primary,
      },
      '& .Toastify__close-button--warning': {
        color: '#e4a643',
      },
    },
  }));

  const classes = useStyles();

  return (
    <StylesProvider generateClassName={generateClassName}>
      {themeApp && (
        <CountProvider>
          <ThemeProvider theme={themeApp}>
            <AccountProvider>
              <AuthProvider>
                <GlobalStyle />
                <Loading />
                <ToastContainer className={classes.customToast} />
                <Routes />
              </AuthProvider>
            </AccountProvider>
          </ThemeProvider>
        </CountProvider>
      )}
    </StylesProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export default App;
