import React from 'react';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import imgConsultaMercado from '../images/ico-consulta-mercado.png';
import { MdSearch } from 'react-icons/md';
import ConfirmButton from './Buttons/ConfirmButton';
import { DashboardCard } from './DashboardCards';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
    flexDirection: 'column',
    borderRadius: '15px',
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
    textTransform: 'none',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function RecipeReviewCard() {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <DashboardCard
      title={'Consulta Mercado'}
      icon={
        <MdSearch
          size={30}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      children={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '1rem',
          }}
        >
          <div style={{ marginTop: '-25px' }}>
            <img src={imgConsultaMercado} alt="Microsoft-Teams-image" />
          </div>
          <div style={{ paddingLeft: '10px', width: '280px' }}>
            <Typography class="font-titulo">
              Faça sua análise de risco CNPJ de forma automática pela nossa{' '}
              <strong>Consulta de Mercado</strong>
            </Typography>
            <Link to="/consulta-mercado">
              <ConfirmButton
                title="Consultar"
                variant="contained"
                color="primary"
                startIcon={<MdSearch color="background" />}
              ></ConfirmButton>
            </Link>
          </div>
        </div>
      }
    />
  );
}
