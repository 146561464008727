import React, { useState, useRef, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import * as Yup from 'yup';
import { api_multipague_configuracao } from '../../services/api';
import ContainerComponent from '../../components/Container';
import WarningIcon from '@material-ui/icons/Warning';
import { Dialog, useTheme } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../components/Buttons/ConfirmButton';
import CPF from '../../components/Inputs/CPF';
import DDD from '../../components/Inputs/DDD';
import Telefone from '../../components/Inputs/Telefone';
import InputText from '../../components/InputText';
import SelectComponent from '../../components/Select';
import validarCpf from '../../util/vlidarCpfCnpj';
import Alert from '@material-ui/lab/Alert';
import getValidationErros from '../../util/FormattedError';
import {
  Container,
  Title,
  Main,
  ContainerDisabled,
  ContainerButton,
  InfoContainer,
} from './styles';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { MdSave, MdArrowBack } from 'react-icons/md';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

export const NovoUsuario = () => {
  const history = useHistory();
  const { contaPadrao } = useSelector(state => state.contas);
  const { userAccount } = useSelector(state => state.user);
  const editingInfo = JSON.parse(localStorage.getItem('edicao_usuario'));
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [enviouConfirmacao, setEnviouConfirmacao] = useState(false);

  const root = {
    path: [
      {
        name: 'Usuários',
        caminho: '/',
      },
    ],
    pagAtual: 'Novo Registro',
  };
  const [situacao, setSituacao] = useState([
    {
      label: 'Administrador',
      value: 'correntista_administrador',
    },
    {
      label: 'Operador',
      value: 'correntista_operador',
    },
    {
      label: 'Analista de Crédito',
      value: 'analista_credito',
    },
  ]);

  const onSubmit = async body => {
    try {
      dispatch(showLoading(true));
      const schmeCadastro = Yup.object().shape({
        nome: Yup.string().required('Campo obrigatorio'),
        usuario: Yup.string().required('Campo obrigatorio'),
        email: Yup.string().required('Campo obrigatorio'),
        ddd: Yup.string().required('Campo obrigatorio'),
        telefone: Yup.string().required('Campo obrigatorio'),
        perfil: Yup.string().required('Campo obrigatorio'),
      });

      await schmeCadastro.validate(body, {
        abortEarly: false,
      });

      if (!validarCpf(body.usuario)) {
        formRef.current.setFieldError('usuario', 'CPF inválido');
        return;
      }

      const bodyResquest = {
        ...body,
        atributos: {
          profileBackOffice: false,
        },
        confirmacaoSenha: null,
        confirmacaoSenhaFinanceira: null,
        ativo: disabled,
        idCliente: editingInfo?.id ?? null,
        master: false,
        senha: null,
        senhaFinanceira: null,
        usuario: body.usuario.replace(/[^\d]/g, ''),
      };
      if (!!editingInfo?.id) {
        const updateBody = {
          email: body.email,
          nome: body.nome,
          ddd: body.ddd,
          telefone: body.telefone,
          sobrenome: '',
        };

        await api_multipague_configuracao.put(
          `/usuario/${editingInfo.id}`,
          updateBody,
        );
      } else {
        const { data } = await api_multipague_configuracao.post(
          `/usuario?idCliente=${userAccount.id}`,
          bodyResquest,
        );
        /*
        if (!!data?.id) {
          const responseAssociarUsuario = await api_multipague_configuracao.post(
            `/usuario/${data.id}/associar?idCliente=${userAccount.id}`,
          );
        }*/
      }

      localStorage.removeItem('edicao_usuario');
      history.push('/Usuarios');
      toastComponentSuccess('Usuário cadastrado com sucesso');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErros(err);
        formRef.current?.setErrors(erros);
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
        localStorage.removeItem('edicao_usuario');
        console.log(err);
      }
    } finally {
      dispatch(hideLoading(false));
    }
  };

  function openConfirm() {
    setOpenModal(true);
  }
  function closeConfirm() {
    setOpenModal(false);
  }

  return (
    <Wrapper
      title="Cadastro de Usuário"
      crumb={[
        { name: 'Dashboard', link: '/' },
        { name: 'Usuário', link: '/Usuario' },
      ]}
      crumbActive="Cadastro"
    >
      <Container ref={formRef} onSubmit={onSubmit}>
        <Box>
          <Main>
            <InputText
              variant="outlined"
              label="Nome Completo"
              name="nome"
              id="nome"
            />
            <DDD variant="outlined" label="DDD" name="ddd" id="ddd" />
            <Telefone
              variant="outlined"
              label="Telefone"
              name="telefone"
              id="telefone"
            />
            <InputText
              variant="outlined"
              label="E-mail"
              name="email"
              id="email"
            />
            <CPF name="usuario" id="usuario" variant="outlined" label="CPF" />
            <SelectComponent
              itens={situacao}
              id="perfil"
              name="perfil"
              label="Perfil"
            />
          </Main>
          <InfoContainer>
            <div>
              <span>Administrador: </span>
              <p>
                Acesso total, inclusão/liberação de pagamentos e transações e
                cadastro de usuários
              </p>
            </div>
            <div>
              <span>Operador: </span>
              <p>Acesso a consultas, inclusão de pagamentos e transações.</p>
            </div>
          </InfoContainer>
          <ContainerDisabled>
            <FormControlLabel
              control={
                <Switch
                  onChange={event => setDisabled(event.target.checked)}
                  checked={disabled}
                  color="primary"
                />
              }
              label="Ativo"
            />
          </ContainerDisabled>

          {!openModal && (
            <ContainerButton>
              <Button
                color="default"
                variant="contained"
                type="button"
                title={'Voltar'}
                onClick={() => history.goBack()}
                startIcon={
                  <MdArrowBack color={useTheme().palette.background.paper} />
                }
              >
              </Button>
              {!editingInfo ? (
                <Button
                  color="primary"
                  variant="contained"
                  title={'Salvar'}
                  onClick={() => setOpenModal(true)}
                  startIcon={
                    <MdSave color={useTheme().palette.background.paper} />
                  }
                ></Button>
              ) : (
                <Button
                  color="primary"
                  title={'Salvar'}
                  variant="contained"
                  type="submit"
                  onClick={() => setOpenModal(true)}
                  startIcon={
                    <MdSave color={useTheme().palette.background.paper} />
                  }
                >
                  Salvar
                </Button>
              )}
            </ContainerButton>
          )}
          {openModal && (
            <>
              <Alert severity="warning">
                Confirma o cadastro de usuário? Em caso de novo usuário será
                encaminhado um email.
              </Alert>
              <ContainerButton>
                <Button
                  color="default"
                  variant="contained"
                  width={150}
                  style={{ marginTop: '20px', marginBottom: '10px' }}
                  onClick={() => setOpenModal(false)}
                  // isLoading={loadingButtonCancelar}
                  startIcon={<MdArrowBack />}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  width={150}
                  style={{ marginTop: '20px', marginBottom: '10px' }}
                  type="submit"
                  startIcon={<MdSave />}
                >
                  Prosseguir
                </Button>
              </ContainerButton>
            </>
          )}
        </Box>
      </Container>
    </Wrapper>
  );
};
