import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {
  api_multipague_esteira,
  api_multipague_transacao,
  api_multipague_transacaoPaged,
} from '../../../src/services/api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { maskCpfCnpj } from '../../util/mask';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { Cancel, HistoryOutlined } from '@material-ui/icons';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const ListaTransferencia = () => {
  const [rowCount, setRowCount] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const history = useHistory();
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [textNoRows, setTextNoRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const date = new Date();
  const [dataDe, setDataDe] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataAte, setDataAte] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataPagDe, setDataPagDe] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataPagAte, setDataPagAte] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    ),
  );
  const [nomeFavorecido, setNomeFavorecido] = useState('');
  const [docBeneficiario, setDocBeneficiario] = useState('');
  const [situacao, setSituacao] = useState('');
  const [selectedSituacao, setSelectedSituacao] = useState(null);
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const selecionarSituacao = [
    {
      id: 'PENDENTE',
      descricao: 'Pendente',
    },
    {
      id: 'AGUARDANDO_EFETIVACAO',
      descricao: 'Aguardando Efetivação',
    },
    {
      id: 'EFETIVADO',
      descricao: 'Efetivado',
    },
    {
      id: 'REJEITADO',
      descricao: 'Rejeitado',
    },
    {
      id: 'CANCELADO',
      descricao: 'Cancelado',
    },
    {
      id: 'EXPIRADO',
      descricao: 'Expirado',
    },
  ];

  const columnsTable = [
    { field: 'documento', headerName: 'CNPJ/CPF', width: 140 },
    { field: 'nome', headerName: 'Nome', flex: 1, minWidth: 150 },
    {
      field: 'banco',
      headerName: 'Banco',
      type: 'string',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'agencia',
      headerName: 'Agencia',
      type: 'string',
      width: 130,
    },
    {
      field: 'dataCadastros1',
      headerName: 'Data Cadastro',
      type: 'string',
      width: 130,
    },
    {
      field: 'dataAgendamento1',
      headerName: 'Data Pagamento',
      type: 'string',
      width: 130,
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 130,
      renderCell: event => (
        <>
          {event.row.status == 'REJEITADO' ? (
            <>
              <p>{event.row.status}</p>
              {event.row?.transferenciaErro != null ? (
                <Tooltip title={event.row?.transferenciaErro?.mensagemErro}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              ) : (
                <Tooltip title={'Houve um erro!'}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <p>{event.row.status}</p>
            </>
          )}
        </>
      ),
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 130,
      renderCell: event =>
        event.row.valor.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 100,
      align: 'center',
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <div>
                <Tooltip title="Histórico de aprovações">
                  <button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {loadingHistorico && event.row.id === rowId ? (
                      <CircularProgress size={24} />
                    ) : (
                      <HistoryOutlined
                        onClick={() => handleHistorico(event.row.id)}
                        color="primary"
                        size={24}
                      />
                    )}
                  </button>
                </Tooltip>
                {event.row.situacao === 'PENDENTE' ? (
                  <Tooltip title="Cancelar">
                    <button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {loadingCancel && event.row.id === rowId ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Cancel
                          onClick={() => handleCancel(event.row)}
                          color="primary"
                          size={24}
                        />
                      )}
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Cancelamento não permitido">
                    <button
                      disabled
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Cancel color="disabled" size={24} />
                    </button>
                  </Tooltip>
                )}
              </div>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoadingTable(true);
    api_multipague_transacaoPaged
      .get(
        `/transferencia?page=${rowsState.page}&size=${
          rowsState.pageSize
        }&clienteConta=${
          contaPadrao.id
        }&dataCadastroInicial=${dataDe}&dataCadastroFinal=${dataAte}&dataPagamentoInicial=${dataPagDe}&dataPagamentoFinal=${dataPagAte}&status=${situacao}&nomeFavorecido=${nomeFavorecido}&documentoFavorecido=${docBeneficiario.replace(
          /[^\d]/g,
          '',
        )}`,
      )
      .then(response => {
        setLoadingTable(false);
        const qtdItems = response.data.totalElements;
        const formattedData = response.data.content.map(dados => ({
          id: uuidv4(),
          documento:
            dados.favorecido != null
              ? maskCpfCnpj(dados.favorecido?.cnpjCpf)
              : 'Sem informações',
          nome:
            dados.favorecido != null
              ? dados.favorecido.nome
              : 'Sem informações',
          situacao: dados.status != null ? dados.status : 'Sem informações',
          dataAgendamento1: dados.dataAgendamento,
          dataCadastros1: moment(dados.dataCadastro).format('DD/MM/YYYY'),
          agencia:
            dados.clienteConta != null
              ? dados.clienteConta.agencia
              : 'Sem informações',
          banco:
            dados.clienteConta != null
              ? dados.clienteConta.banco.nome
              : 'Sem informações',
          valor: dados.valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          ...dados,
        }));
        setRowCount(qtdItems);
        setRowsTable(formattedData);
      })
      .catch(err => {
        setTextNoRows(
          err.response.status === 404
            ? 'Nenhuma transferência encontrada'
            : null,
        );
        setLoadingTable(false);
        setRowsTable([]);
        setRowCount(0);
        console.log(err);
      });
  }, [contaPadrao, rowsState]);

  function limpar() {
    setNomeFavorecido('');
    setSituacao('');
    setSelectedSituacao(null);
    setDocBeneficiario('');
    setDataDe(
      moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
        'YYYY-MM-DD',
      ),
    );
    setDataAte(
      moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
        'YYYY-MM-DD',
      ),
    );
    setDataPagDe(
      moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
        'YYYY-MM-DD',
      ),
    );
    setDataPagAte(
      moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
        'YYYY-MM-DD',
      ),
    );
    setRowsState({ page: 0, pageSize: rowsState.pageSize });
  }

  const handlePageSize = pageSize => {
    setRowsState({ page: 0, pageSize: pageSize });
  };

  const handleCancel = value => {
    setRowId(value.id);
    const idTransferencia = value.id;
    const cancelCliente = value?.clienteConta?.cliente?.id;
    setLoadingCancel(true);
    api_multipague_transacao
      .put(
        `/transferencia/${idTransferencia}/cancelar?cliente=${cancelCliente}`,
      )
      .then(response => {
        toastComponentSuccess('Evento cancelado com sucesso!');
        setLoadingCancel(false);
        setRowsState({ page: rowsState.page, pageSize: rowsState.pageSize });
      })
      .catch(error => {
        setLoadingCancel(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const handleHistorico = idTransacao => {
    setRowId(idTransacao);
    setLoadingHistorico(true);
    api_multipague_esteira
      .get(`/administracao/transferencia?codigoTransferencia=${idTransacao}`)
      .then(response => {
        setLoadingHistorico(false);
        history.push('/historico-teds', {
          historic: response.data,
        });
      })
      .catch(err => {
        setLoadingHistorico(false);
        toastComponentError(err.response?.data?.mensagem);
      });
  };

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={nomeFavorecido}
            label="Nome Favorecido"
            onChange={e => {
              setNomeFavorecido(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 3, lg: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="CPF/CNPJ"
            id="outlined-size-small"
            value={docBeneficiario}
            onChange={e => {
              setDocBeneficiario(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 3, lg: 3 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-box-demo"
            size="small"
            options={selecionarSituacao}
            value={selectedSituacao}
            getOptionLabel={option => option.descricao}
            onChange={(event, option) => {
              setSituacao(option ? option.id : '');
              setSelectedSituacao(option);
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Status"
                placeholder={'Selecione o Status'}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataDe"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - De:"
            value={dataDe}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataDe(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataAte"
            placeholder="DD/MM/AAAA"
            label="Data Cadastro - Até:"
            value={dataAte}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataAte(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataDe"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - De:"
            value={dataPagDe}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataPagDe(e.target.value);
            }}
            InputProps={{
              style: { borderRadius: '20px' },
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            variant="outlined"
            size="small"
            required
            id="dataAte"
            placeholder="DD/MM/AAAA"
            label="Data Pagamento - Até:"
            value={dataPagAte}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setDataPagAte(e.target.value);
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
    ],
    searchButton: {
      searchButton: () =>
        setRowsState({ page: 0, pageSize: rowsState.pageSize }),
    },
    cleanButton: {
      cleanButton: () => limpar(),
    },
  };

  return (
    <DataGridPage
      title="Lista de TED's"
      crumb={[{ name: 'Dashboard', link: '/' }, { name: "TED's" }]}
      newRegister={() => history.push('/Transferencia')}
      formData={filterInputs}
      rows={rowsTable}
      key={rowsTable.key}
      rowCount={rowCount}
      setDatagrid={setRowsState}
      columns={columnsTable}
      loading={loadingTable}
      minHeight={'600px'}
      pagination
      {...rowsState}
      paginationMode="server"
      onPageChange={onPage}
      componentsProps={{
        pagination: { classes: null },
      }}
      onRowClick={params => setRowId(params.id)}
      textNoRows={textNoRows}
      rowsPerPageOptions={[10, 20, 50]}
      onPageSizeChange={newPageSize => handlePageSize(newPageSize)}
    />
  );
};

export default ListaTransferencia;
