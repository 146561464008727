import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import { styled as MuyStyled} from '@material-ui/core';

export const Container = styled.div`
  min-width: 97%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FormContainer = styled(FormControl).attrs(props => ({
  ref: props.ref,
}))`
  min-width: 100%;
`;
export const Label = styled(InputLabel)`
  min-width: 100%;
`;
export const Itens = styled(MenuItem)`
  min-width: 100%;
`;
export const SelectOptions = styled(Select)`
  width: 100%;
`;

export const MessageError = MuyStyled('p')(({ theme }) => {
  return {
    margin: '0',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    marginLeft: '14px',
    marginRight: '14px',
    color: theme.palette.system.dark,
  };
});
