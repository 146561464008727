/* eslint-disable */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import CadastrarFavoritos from '../pages/CadastroFavorecido';
import CNAB from '../pages/CNAB';
import Novidades from '../pages/Novidades';
import MyAccounts from '../pages/MyAccounts/MyAccounts';
import Extract from '../pages/Extract';
import PdfExtract from '../pages/Extract/pdf';
import PdfExtractDiario from '../pages/Extract/pdfDiario';
import MeuPerfil from '../pages/MeuPerfil';
import PagarBoleto from '../pages/PayTicket';
import ConsultaBoleto from '../pages/ConsultaBoleto';
import ImprimirComprovanteCancelamento from '../pages/ConsultaBoleto/comprovanteCancelamento';
import ListarFavoritos from '../pages/TabelaBeneficiarios';
import Transferencia from '../pages/Transferencia';
import ListaTransferencia from '../pages/ListaTransferencia';
import ListaBoleto from '../pages/ListaBoleto';
import Carteira from '../pages/Carteira';
import ProcessamentoCnab from '../pages/ProcessamentoArquivosCnab';
import DetalhamentoCnab from '../pages/DetalhamentoArquivosCnab';
import NewHomeCnab from '../pages/NewHomeCnab';
import CentroDeCusto from '../pages/CentroDeCusto';
import Estera from '../pages/Esteira';
import CentroDeCustoCadastro from '../pages/CentroDeCusto/Cadastro';
import CentroDeCustoEditar from '../pages/CentroDeCusto/Editar';
import MetasDespesasNovo from '../pages/MetasDespesasNovo';
import CancelarBoleto from '../components/CancelarBoleto';
import CentroCusto from '../pages/CentroDeCusto';
import ConsultaMercado from '../pages/ConsultaMercado';
import ConsultaMercadoConsulta from '../pages/ConsultaMercado/Consulta';
import ImprimirComprovante from '../pages/ImprimirComprovante';
import ImprimirComprovanteLote from '../pages/ImprimirComprovanteLote';
import ImprimirComprovanteBoleto from '../pages/ImprimirComprovanteBoleto';
import ImprimirComprovantePagamento from '../pages/ImprimirComprovantePagamento';
import ComprovanteTED from '../pages/ImprimirComprovantev2';
import comprovanteBoleto from '../pages/comprovanteBoleto';
import NewFinancialPassword from '../pages/NewFinancialPassword';
import InsertCodeFinancialPassword from '../pages/InsertCodeFInancialPassword';
import Faq from '../pages/Faq';
import { Usuarios } from '../pages/Usuarios';
import { EditUsuarios } from '../pages/Edicao';
import { NovoUsuario } from '../pages/NovoUsuario';
import SelectAccount from '../pages/SelectAccount';
import VisualizarBoleto from '../pages/InformacoesBoleto';
import VisualizarTed from '../pages/InformacoesTransferencia';

import { PagamentoLote } from '../pages/PagamentoLote';
import { DetalhePagamentoLote } from '../pages/DetalhePagamentoLote';
import RetornoPagamento from '../pages/RetornoPagamento';

import RetornoRemessa from '../pages/RetornoRemessa';
import Pagador from '../pages/Pagador';

import { useDispatch } from 'react-redux';
import { setMenu } from '../store/modules/app';

const CustomRoute = ({ menu, children, ...rest }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setMenu(menu || ''));
  }, [menu]);
  return <Route {...rest}>{children}</Route>;
};

import ImpressaoBoleto from '../pages/ImpressaoBoleto';
import ImpressaoBoletosEmLoteComErro from '../pages/ImpressaoBoletosErros';
import HistoricoTeds from '../pages/HistoricoTeds';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <CustomRoute menu="Dashboard" path="/" exact component={Dashboard} />

        <CustomRoute
          path="/myaccounts"
          menu="Minhas Contas"
          component={MyAccounts}
        />
        <CustomRoute path="/extrato" menu="Minhas Contas" component={Extract} />

        <CustomRoute
          menu="Transferência"
          path="/Listagem/Favorecidos"
          component={ListarFavoritos}
        />
        <CustomRoute
          menu="Transferência"
          path="/Cadastro/Favorecidos"
          component={CadastrarFavoritos}
        />
        <CustomRoute
          menu="Transferência"
          path="/Editar/Favorecidos/:clienteId"
          component={CadastrarFavoritos}
        />

        <CustomRoute
          menu="Transferência"
          path="/ListaTransferencia"
          component={ListaTransferencia}
        />
        <CustomRoute
          menu="Transferência"
          path="/Transferencia"
          component={Transferencia}
        />
        <CustomRoute
          path="/Usuarios"
          menu="Gestão de Usuários"
          component={Usuarios}
        />
        <CustomRoute
          path="/Cadastro/Usuario"
          menu="Gestão de Usuários"
          component={NovoUsuario}
        />
        <CustomRoute
          path="/Edicao"
          menu="Gestão de Usuários"
          component={EditUsuarios}
        />

        <CustomRoute
          path="/Pagamento/Boleto"
          menu="Pagamento"
          component={PagarBoleto}
        />
        <CustomRoute
          menu="Pagamento"
          path="/ListaBoleto"
          component={ListaBoleto}
        />
        <CustomRoute
          path="/Pagamento/Lote"
          menu="Pagamento"
          component={PagamentoLote}
        />
        <CustomRoute
          path="/Pagamento-Lote/Detalhe"
          menu="Pagamento"
          component={DetalhePagamentoLote}
        />
        <CustomRoute
          path="/Pagamento-Retorno"
          menu="Pagamento"
          component={RetornoPagamento}
        />

        <CustomRoute
          path="/Home/NewCnab"
          menu="Cob. Simples"
          component={NewHomeCnab}
        />
        <CustomRoute
          menu="Cob. Simples"
          path="/boleto/impressao-em-lote"
          component={ImpressaoBoleto}
        />
        <CustomRoute
          menu="Cob. Simples"
          path="/boleto/impressao-lote-erros"
          component={ImpressaoBoletosEmLoteComErro}
        />

        <CustomRoute menu="Cob. Simples" exact path="/Cobranca/Simples">
          <CNAB isEdit={false} />
        </CustomRoute>
        <CustomRoute
          menu="Cob. Simples"
          exact
          path="/Cobranca/Simples/Editar/:idBoleto"
        >
          <CNAB isEdit={true} />
        </CustomRoute>
        <CustomRoute
          menu="Cob. Simples"
          path="/Processamento/Cnab"
          component={ProcessamentoCnab}
        />

        <CustomRoute
          menu="Cob. Simples"
          path="/Detalhamento/Cnab/:idArquivo"
          component={DetalhamentoCnab}
        />
        <CustomRoute
          menu="Cob. Simples"
          path="/Buscar/Boleto"
          component={ConsultaBoleto}
        />
        <CustomRoute
          path="/imprimir-boleto-cancelamento"
          component={ImprimirComprovanteCancelamento}
        />
        <CustomRoute
          menu="Cob. Simples"
          path="/Cancelar/Boleto/:isCancelamento/:idBoleto"
          component={CancelarBoleto}
        />
        <CustomRoute
          menu="Cob. Simples"
          path="/Carteira"
          component={Carteira}
        />
        <CustomRoute
          menu="Cob. Simples"
          path="/retorno/remessa"
          component={RetornoRemessa}
        />

        <CustomRoute menu="Cob. Simples" path="/pagador" component={Pagador} />

        <CustomRoute menu="Esteira" path="/Esteira" component={Estera} />

        <CustomRoute menu="Faq" path="/faq" component={Faq} />

        {/** configuracoes */}
        <CustomRoute
          menu="Configurações"
          path="/centro-de-custo"
          exact
          component={CentroDeCusto}
        />
        <CustomRoute
          menu="Configurações"
          path="/centro-de-custo/cadastro"
          component={CentroDeCustoCadastro}
        />
        <CustomRoute
          menu="Configurações"
          path="/centro-de-custo/editar/:info"
          component={CentroDeCustoEditar}
        />

        {/*###################### CONFIGURACAO DE ROTAS SEM O MENU ANTIGAS ##########################*/}

        <CustomRoute path="/select-account" exact component={SelectAccount} />

        <CustomRoute path="/pdf-extrato" component={PdfExtract} />
        <CustomRoute path="/pdf-extrato-diario" component={PdfExtractDiario} />
        <CustomRoute path="/centroCusto" component={CentroCusto} />
        <CustomRoute path="/consulta-mercado" component={ConsultaMercado} />
        <CustomRoute
          path="/consulta-mercado-consulta"
          component={ConsultaMercadoConsulta}
        />
        <CustomRoute
          path="/insert-code-financial-password"
          component={InsertCodeFinancialPassword}
        />
        <CustomRoute
          path="/new-financial-password"
          component={NewFinancialPassword}
        />
        <CustomRoute path="/meu-perfil" component={MeuPerfil} />
        <CustomRoute path="/novidades-da-versao" component={Novidades} />
        <CustomRoute
          path="/imprimir-comprovante"
          component={ImprimirComprovante}
        />
        <CustomRoute
          path="/imprimir-comprovante-lote"
          component={ImprimirComprovanteLote}
        />
        <CustomRoute
          path="/imprimir-comprovante-boleto"
          component={ImprimirComprovanteBoleto}
        />
        <CustomRoute
          path="/imprimir-comprovante-pagamento"
          component={ImprimirComprovantePagamento}
        />

        <CustomRoute path="/metas-despesas-new" component={MetasDespesasNovo} />

        <CustomRoute
          path="/ComprovanteTransferencia"
          component={ComprovanteTED}
        />
        <CustomRoute
          path="/ComprovanteBoleto/:idConta/:idBoleto"
          component={comprovanteBoleto}
        />

        <CustomRoute
          menu="Pagamento"
          path="/Pagamento-Lote"
          component={PagamentoLote}
        />

        <CustomRoute
          path="/Boleto/Visualizar/:idBoleto"
          component={VisualizarBoleto}
        />
        <CustomRoute path="/Ted/Visualizar/:idTed" component={VisualizarTed} />
        <CustomRoute path="/historico-teds" component={HistoricoTeds} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
