import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '../../components/Buttons/ConfirmButton';
import moment from 'moment';
import formattValue from '../../util/FormatteMoney';
import { MdPrint } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  colorText: {
    color: theme.palette.appBar[theme.palette.type].bgMenu,
  },
  icons: {
    marginTop: '3px',
  },
}));

export default function ImprimirComprovanteTED() {
  const classes = useStyles();
  const theme = useTheme();
  const extratoTransferencia = JSON.parse(
    localStorage.getItem('extratoTransferencia'),
  );
  const [info, setInfo] = useState([]);
  useEffect(() => {
    setInfo(extratoTransferencia);
  }, []);

  const printDiv = () => {
    const css = '@page { size: portrait; }';
    const style = document.createElement('style');
    style.setAttribute('media', 'print');
    style.textContent = css;
    document.head.appendChild(style);
    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printButton').style.display = 'inline';
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <div id="printableArea">
          <img alt="" height="64" src={theme.config.loginLogo} />
          <Typography className={classes.colorText} variant="h5" component="h5">
            {extratoTransferencia &&
              (moment(extratoTransferencia.dataPagamento).isAfter(Date.now())
                ? 'Transferência Agendada com Sucesso'
                : 'Comprovante de Transferência')}
          </Typography>
          <Typography className={classes.colorText} component="body2">
            {extratoTransferencia
              ? moment(extratoTransferencia.dataPagamento).format('DD/MM/YYYY')
              : 'xx/xx/xxxx'}
          </Typography>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Valor
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {formattValue(info?.valor)}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Finalidade
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.finalidade?.descricao}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Autenticação
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.codigoTransacao}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Observação
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.descricaoHistorico}
              </Typography>
            </div>
          </div>
          <Divider />
          <div className={classes.comprovante}>
            <Typography className={classes.colorText} component="subtitle1">
              <LocalAtmIcon className={classes.icons} />
              <ArrowDropDownIcon className={classes.icons} />
              Destino
            </Typography>
          </div>
          <Divider />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Nome
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.favorecido?.nome}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Documento Federal
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.favorecido?.documentoFederal
                  ? String(info?.favorecido?.documentoFederal).length > 11
                    ? String(info?.favorecido?.documentoFederal).replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        '$1.$2.$3/$4-$5',
                      )
                    : String(info?.favorecido?.documentoFederal).replace(
                        /(\d{3})?(\d{3})?(\d{3})?(\d{2})/,
                        '$1.$2.$3-$4',
                      )
                  : '--'}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Banco
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.favorecido?.banco?.nome}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Agência
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.favorecido?.agencia}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Conta
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.favorecido?.conta}-{info?.favorecido?.digitoConta}
              </Typography>
            </div>
          </div>

          <Divider />
          <div className={classes.comprovante}>
            <Typography className={classes.colorText} component="subtitle1">
              <LocalAtmIcon className={classes.icons} />
              <ArrowDropUpIcon className={classes.icons} />
              Origem
            </Typography>
          </div>
          <Divider />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Nome
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.clienteConta?.cliente?.nome}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Documento Federal
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.clienteConta?.cliente?.cnpjCpf
                  ? String(info?.clienteConta?.cliente?.cnpjCpf).length > 11
                    ? String(info?.clienteConta?.cliente?.cnpjCpf).replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        '$1.$2.$3/$4-$5',
                      )
                    : String(info?.clienteConta?.cliente?.cnpjCpf).replace(
                        /(\d{3})?(\d{3})?(\d{3})?(\d{2})/,
                        '$1.$2.$3-$4',
                      )
                  : '--'}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Banco
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.clienteConta?.banco?.nome}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Agência
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.clienteConta?.agencia}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Conta
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.clienteConta?.conta}-{info?.clienteConta?.digitoConta}
              </Typography>
            </div>
          </div>
        </div>

        <div
          className={classes.comprovante}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20PX',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            id="printButton"
            startIcon={<MdPrint color='background'/>}
            title={"Imprimir"}
            onClick={() => printDiv()}
          >
            Imprimir
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
}
