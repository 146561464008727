import DeleteIcon from '@material-ui/icons/Delete';
import React, { useRef, useState } from 'react';
import { MdFileUpload, MdSave } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { api_multipague_arquivo } from '../../services/api';
import Alert from '@material-ui/lab/Alert';
import { FiUpload } from 'react-icons/fi';

import {
  hideLoadingButton,
  showLoadingButton,
} from '../../store/modules/loading/actions';
import Buttons from '../Buttons/ConfirmButton';
import {
  Container,
  ContainerButton,
  ContainerIcon,
  ContainerNomeFiles,
  DeleteFile,
  Files,
  NameFile,
  Subcontainer,
} from './styles';
import { useTheme } from '@material-ui/core/styles';
import { toastComponentError, toastComponentSuccess } from '../Toast';

const InputCNBA = () => {
  const history = useHistory();
  const theme = useTheme();
  const { loadingButton } = useSelector(state => state.loading);
  const { contaPadrao } = useSelector(state => state.contas);
  const dispatch = useDispatch();
  const [uploadFiles, setUploadFiles] = useState([]);
  const fileRef = useRef(null);

  function chosenFile() {
    if (fileRef.current) fileRef.current.click();
  }
  function setInputFiles(data) {
    const formattedArrayFile = [...data].map(teste => ({
      itemFile: teste,
      id: uuidv4(),
    }));
    setUploadFiles([...uploadFiles, formattedArrayFile[0]]);
  }

  async function sendFiles() {
    try {
      dispatch(showLoadingButton(true));

      const formData = new FormData();

      formData.append('file', uploadFiles[0].itemFile);
      formData.append('clienteConta', contaPadrao.id);
      formData.append('tipoOperacao', localStorage.getItem('tipodaOperacao'));
      const { data } = await api_multipague_arquivo.post(
        '/boleto-cobranca/cnab/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      toastComponentSuccess('Upload realizado com sucesso');
      history.push('/Buscar/Boleto');
      dispatch(hideLoadingButton(false));
      if (data.length) {
        toastComponentSuccess('Upload realizado com sucesso');
        history.push('/Processamento/Cnab');
      }
    } catch (err) {
      dispatch(hideLoadingButton(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  return (
    <Container>
      <Subcontainer>
        <ContainerIcon cursor={uploadFiles.length <= 0}>
          <div>
            <FiUpload
              color={theme.palette.primary.main}
              size={30}
              onClick={() => chosenFile()}
            />
          </div>
          <span style={{ padding: '10px 0' }}>Selecione um arquivo </span>
          <input
            type="file"
            id="arquivos_input"
            name="arquivos_input"
            accept=".rem, .REM"
            onChange={() => setInputFiles(fileRef.current?.files)}
            onClick={() => !!fileRef.current && (fileRef.current.value = '')}
            ref={fileRef}
            disabled={uploadFiles.length > 0}
          />
        </ContainerIcon>

        <ContainerNomeFiles>
          {uploadFiles.length > 0 ? (
            uploadFiles.map(file => (
              <Files key={file.id}>
                <NameFile>
                  <p>{file.itemFile.name}</p>
                </NameFile>
                <DeleteFile>
                  <DeleteIcon
                    color="error"
                    size={15}
                    onClick={() => setUploadFiles([])}
                  />
                </DeleteFile>
              </Files>
            ))
          ) : (
            <Alert severity="info">Nenhum arquivo selecionado!</Alert>
          )}
        </ContainerNomeFiles>

        {uploadFiles.length > 0 && (
          <ContainerButton>
            <Buttons
              color="primary"
              variant="contained"
              title={'Salvar'}
              width={100}
              style={{ color: theme.palette.background.paper }}
              onClick={() => sendFiles()}
              isLoading={loadingButton}
              startIcon={<MdSave color={theme.palette.background.paper} />}
            ></Buttons>
          </ContainerButton>
        )}
      </Subcontainer>
    </Container>
  );
};

export default InputCNBA;
