import axios from 'axios';
import { getService, createApiService } from './index';
export default axios;
export const baseUrlCobranca = getService('api_multipague_cobranca');
export const api_multipague_conta_paged = createApiService(
  'api_multipague_conta',
  { Accept: 'application/group+json;type=data' },
);
export const api_multipague_conta_paged_favoritos = createApiService(
  'api_multipague_conta',
  { Accept: 'application/paged+json' },
);
export const api_multipague_conta = createApiService('api_multipague_conta');

export const api_multipague_consulta_mercado = createApiService(
  'api_multipague_consulta_mercado',
);
export const api_multipague_transacao = createApiService(
  'api_multipague_transacao',
);
export const api_multipague_transacaoPaged = createApiService(
  'api_multipague_transacao',
  { Accept: 'application/paged+json' },
);
export const api_multipague_agenda_financeira = createApiService(
  'api_multipague_agenda_financeira',
);
export const api_multipague_cobranca = createApiService(
  'api_multipague_cobranca',
);
export const api_multipague_cobranca_cnab = createApiService(
  'api_multipague_cobranca',
  { Accept: 'application/paged+json' },
);
export const api_multipague_configuracao = createApiService(
  'api_multipague_configuracao',
);
export const api_multipague_configuracaoCentro = createApiService(
  'api_multipague_configuracao',
);
export const api_multipague_configuracaoPaged = createApiService(
  'api_multipague_configuracao',
  { Accept: 'application/paged+json' },
);
export const api_multipague_despesa = createApiService(
  'api_multipague_despesa',
);
export const api_multipague_esteira = createApiService(
  'api_multipague_esteira',
);

export const api_multipague_esteira_paged = createApiService(
  'api_multipague_esteira',
  { Accept: 'application/paged+json' },
);
export const api_multipague_cep = createApiService('api_multipague_cep');
export const api_multipague_arquivo = createApiService(
  'api_multipague_arquivo',
);
export const api_multipague_arquivo_paged = createApiService(
  'api_multipague_arquivo',
  { Accept: 'application/paged+json' },
);
