import React from 'react';
import { Container } from './style';
import { useRetornoRemessa } from '../../Context/RetornoRemessaContext';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import Data from '../../components/InputDate';
import RenderActionsCell from '../../components/DataGrid/DataGridPaginationServer/Actions';
import { MdDownload } from 'react-icons/md';
import { IconButton, Tooltip } from '@material-ui/core';

const Content = () => {
  const {
    rowsTable,
    loading,
    rowCount,
    rowsState,
    onPage,
    handlePageSize,
    textNoRows,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    setRowsState,
    clearFilter,
    submitFilter,
  } = useRetornoRemessa();

  const [rowId, setRowId] = React.useState(null);
  const [openMenuActions, setOpenMenuActions] = React.useState(false);

  const columnsTable = [
    { field: 'codigo', headerName: 'Código', width: 100 },
    { field: 'nomeArquivo', headerName: 'Arquivo', flex: 1 },
    { field: 'dataReferencia', headerName: 'Data', width: 250 },
    {
      field: 'acoes',
      align: 'center',
      headerName: 'Ações',
      width: 100,
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <Tooltip title="Download">
                <IconButton
                  target="retorno"
                  variant="outlined"
                  color="primary"
                  size="small"
                  href={event.row.url}
                >
                  <MdDownload variant="contained" />
                </IconButton>
              </Tooltip>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <Data
            callback={setDataInicial}
            label="Data Inicial"
            id="data-inicial"
            value={dataInicial}
            dataMinima={'1999-01-01'}
            variant="outlined"
            size="small"
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <Data
            callback={setDataFinal}
            size="small"
            label="Data Final"
            id="data-final"
            value={dataFinal}
            dataMinima={'1999-01-01'}
            variant="outlined"
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
    ],
    searchButton: {
      searchButton: () => submitFilter(),
    },
    cleanButton: {
      cleanButton: () => clearFilter(),
    },
  };

  return (
    <Container>
      <DataGridPage
        title="Retorno Remessa"
        crumb={[{ name: 'Dashboard', link: '/' }, { name: 'Cobrança Simples' }]}
        formData={filterInputs}
        minHeight="600px"
        rows={rowsTable}
        setDatagrid={setRowsState}
        columns={columnsTable}
        loading={loading}
        rowCount={rowCount}
        onRowClick={params => setRowId(params.id)}
        {...rowsState}
        onPageChangeFunction={onPage}
        textNoRows={textNoRows}
        onPageSizeChange={newPageSize => handlePageSize(newPageSize)}
      />
    </Container>
  );
};

export default Content;
