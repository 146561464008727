import React, { useState, useRef, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { BiDetail, BiError } from 'react-icons/bi';
import { api_multipague_cobranca_cnab } from '../../services/api';
import formattedMoney from '../../util/FormatteMoney';
import { ButtonTable } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';

import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';

import { newEditCnab } from '../ConsultaBoleto';
import { Box, Paper } from '@material-ui/core';
import { toastComponentError } from '../../components/Toast';

const DetalhamentoArquivosCnab = () => {
  const history = useHistory();
  const { idArquivo } = useParams();
  const dispatch = useDispatch();
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [textNoRows, setTextNoRows] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const columnsTable = [
    {
      field: 'cpf_cnpf',
      headerName: 'CPF/CNPJ',
      width: 150,
    },
    {
      field: 'nosso_numero',
      headerName: 'Nosso número',
      width: 200,
    },
    {
      field: 'nome_pagador',
      headerName: 'Nome Pagador',
      flex: 1,
    },
    { field: 'data_vencimento', headerName: 'Data Vencimento', width: 120 },
    {
      field: 'documento',
      headerName: 'Documento',
      width: 150,
    },
    {
      field: 'valor_total',
      headerName: 'Valor total',
      width: 150,
    },
    {
      field: 'error',
      headerName: 'Erro',
      sortable: false,
      width: 110,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <ButtonTable
            hasError={!!event.row.codigoErro}
            onClick={() => newEditCnab(event.row, history, contaPadrao)}
          >
            <BiError
              size={24}
              color={!!event.row.codigoErro ? '#dc3545' : '#dcdcdc'}
            />
          </ButtonTable>
        );
      },
    },
  ];

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
  };

  useEffect(() => {
    localStorage.removeItem('data_pagamento_boleto2');
  }, []);

  useEffect(() => {
    if (idArquivo && contaPadrao?.id) {
      pesquisarArquivos(idArquivo, contaPadrao?.id, rowsState);
    }
  }, [contaPadrao, rowsState]);

  const pesquisarArquivos = useCallback(
    async (arquivoId, contaId, stateRow) => {
      dispatch(showLoading(true));

      try {
        const { data } = await api_multipague_cobranca_cnab.get(
          `/cobranca-simples/arquivo/${arquivoId}/boleto/erros?clienteConta=${contaId}&page=${stateRow.page}&size=${stateRow.pageSize}`,
        );

        const formattedRows = data.content.map(date => ({
          data_vencimento: moment(date.dataVencimento).format('DD/MM/YYYY'),
          nosso_numero: date.nossoNumero,
          valor_total: formattedMoney(date.valorTitulo),
          documento: date.numeroTitulo,
          nome_pagador: date.nomePagador,
          cpf_cnpf: date.documentoFederal,
          id: uuidv4(),
          ...date,
        }));
        const qtdItems = data.totalElements;
        setRowCount(qtdItems);
        setRowsTable(formattedRows);

        dispatch(hideLoading(false));
      } catch (err) {
        dispatch(hideLoading(false));
        if (err.response.status === 404) {
          setTextNoRows('Nenhum boleto com erro encontrado');
        } else {
          toastComponentError(
            ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
          );
        }
      }
    },
    [],
  );

  return (
    <Wrapper
      title="Detalhamento CNAB"
      crumb={[
        { name: 'Minhas Contas', link: '/myaccounts' },
        {
          link: '/Processamento/Cnab',
          name: 'Processamento CNAB',
        },
      ]}
    >
      <Paper style={{ padding: 20, borderRadius: '20px', marginTop: 25 }}>
        <DataGrid
          rows={rowsTable}
          columns={columnsTable}
          height={600}
          rowCount={rowCount}
          pagination
          {...rowsState}
          paginationMode="server"
          onPageChange={onPage}
          rowsPerPageOptions={[10, 20, 50]}
          componentsProps={{
            pagination: { classes: null },
          }}
          textNoRows={textNoRows}
          onPageSizeChange={newPageSize =>
            setRowsState({ page: 0, pageSize: newPageSize })
          }
        />
      </Paper>
    </Wrapper>
  );
};

export default DetalhamentoArquivosCnab;
