import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
//import { DataGrid } from '@material-ui/data-grid';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import formattedMoney from '../../util/FormatteMoney/index';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import LoadingBtn from '../../components/Buttons/ConfirmButton'
import EditIcon from '@material-ui/icons/Edit';
import MaskedInput from 'react-text-mask'
import moment from 'moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import TopBar from '../../components/TopBar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { api_multipague_consulta_mercado } from '../../services/api';
// import { Cookies } from 'react-cookie'
import { ToastContainer, toast } from 'react-toastify';
import { CSVLink, CSVDownload } from 'react-csv';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import { MdSearch,MdClear } from 'react-icons/md';

import Box from "../../components/Box";
import Wrapper from "../../components/Wrapper";
import DataGrid from "../../components/DataGrid";
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

// const cookies = new Cookies()
// const tknAccess = cookies.get('tkn-access')

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  cnpj: {
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    paddingLeft: '15px',
    paddingTop: '4.5px',
    paddingBottom: '4.5px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    alignItems: 'center',
    fontFamily: "Roboto, Helvetica, Arial,sans-serif",
    fontWeight: 400,
    lineHeight: '1.1876em',
    letterSpacing:'0.00938em',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fff',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fixedHeight: {
    height: '300px',
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  addButton: {
    marginTop: theme.spacing(3),
  },
  searchButton: {
    // margin: theme.spacing(1),
    // backgroundColor: "#65c4d5"
    float: 'right'
  },
  rootform: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  btnprint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  btnpdf: {
    marginRight: '10px',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: "10px",
    width: '100%',
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  positive: {
    color: "#00ff00"
  },
  btnpdf: {
    marginRight: '10px',
    cursor: 'pointer',
  },
  grid: {
    marginBottom: '20px'
  },
  cancelIcon: {
    color: '#e9a645',
    cursor: 'pointer'
  },
  topoLegal: {
      display: 'flex',
      justifyContent: 'space-between'
  }
}));

function TextMaskCustomCnpj(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

export default function DataGridDemo() {
  const history = useHistory()
  const { contaPadrao } = useSelector(state => state.contas);
  const contaIdCliente = contaPadrao.id
  const [open, setOpen] = React.useState(false);
  const [openProsseguir, setOpenProsseguir] = React.useState(false);
  const [openProsseguir2, setOpenProsseguir2] = React.useState(false);
  const [ultimaConsulta, setUltimaConsulta] = React.useState('');
  const [openPdf, setOpenPdf] = React.useState(false);
  const [idCliente, setIdCliente] = useState(localStorage.getItem('id-cliente'))

  const headersTable = [
    { label: 'ID', key: 'id' },
    { label: 'CPF/Cnpj', key: 'cpfCnpj' },
    { label: 'Valor', key: 'valor' },
    { label: 'Vencimento', key: 'vencimento' },
    { label: 'Situação', key: 'situacao' },
  ];

  const columns = [
    // { field: 'finalidade', headerName: 'Finalidade', flex: 1 },
    { field: 'id', headerName: 'ID' },
    { field: 'cnpj', headerName: 'CPF/CNPJ', width:200 },
    { field: 'razaoSocial', headerName: 'Nome',width:350 },
    { field: 'vencimento', headerName: 'Data Consulta', width:180 },
    { field: 'status', headerName: 'Status', width:150 },
    {
      field: 'situacao',
      headerName: 'Ações',
      width:150,
      type: 'text',
      renderCell: event => {
        return (
              <>
                <LoadingBtn
                    variant="contained"
                    color="primary"
                    // className={classes.addButton}
                    isLoading={loadButton}
                    startIcon={!loadButton&& <SearchIcon />}
                    onClick={() => {
                        handleEditar(event.row);
                    }}
                    // onClick={() => {
                    //   downloadPDF()
                    // }}
                >
                  Abrir
                </LoadingBtn>

                  {/* <EditIcon
                  className={classes.cancelIcon}
                  style={{marginLeft:'5px'}}
                  onClick={() => {
                    handleEditar(event.row);
                  }}
                  /> */}
              </>
        );
      },
    },
  ];

  const [inputValuesCnpj, setInputValuesCnpj] = React.useState({
    textmaskcnpj: ''
  });
  const handleChangeInputCnpj = (event) => {
    setInputValuesCnpj({
      ...inputValuesCnpj,
      [event.target.name]: event.target.value,
    });
    setCnpj(event.target.value)
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenProsseguir = () => {
    setOpenProsseguir(false);
  };

  const handleOpenPdf = () => {
    setOpenPdf(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseProsseguir = () => {
    setOpenProsseguir(false);
  };
  const handleCloseProsseguir2 = () => {
    setOpenProsseguir2(false);
  };
  const handleClosePDF = () => {
    setOpenPdf(false);
  };

  const classes = useStyles();
//   const cookies = new Cookies()

  const [rows, setRows] = useState([]);
  const [existeCnpj, setExisteCpnj] = useState('')
  const [ip, setIp] = useState('')
  const [infoCliente, setInfoCliente] = useState([])
  // const [userId, setUserId] = useState([])
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem("valorIdDetalhes", valorInput)
//   const permissionUser = cookies.get('roles')


  const date =  new Date()
  const [loadButton, setLoadButton] = useState(false)
  const [pdf, setPdf] = useState('')
  const [pdfExists, setPdfExists] = useState('')
  const [termo, setTermo] = useState(false)
  const [loadButtonAccount, setLoadButtonAccount] = useState(false)
  const [loadButtonEmail, setLoadButtonEmail] = useState(false)
  const [situacao, setSituacao] = useState('')
  const [nome, setNome] = useState('')
  const [pagamento, setPagamento] = useState('')
  const [transacao, setTransacao] = useState('')
  const [situacaoPadrao, setSituacaoPadrao] = useState('Situação Padrão')
  const [selecionarSituacao, setSelecionarSituacao] = useState([
    {
      descricao: "Situação 1"
    }
  ])
  const [abrirTermos, setAbrirTermos] = useState(false)
  const [abrirTermos1, setAbrirTermos1] = useState(false)
  const [codigo, setCodigo] = useState('')
  const [descricao, setDescricao] = useState('')
  const [linkPdf, setLink] = useState('')
  const [usuario, setUsuario] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [dataSolicitacaoDe, setDataSolicitacaoDe] = useState('')
  const [dataSolicitacaoAte, setDataSolicitacaoAte] = useState('')
  const handleNome = (event) => {
    setNome(event.target.value);
  };
  const handleSituacao = (event) => {
    setSituacao(event.target.value);
  };
  const handleSolicitacaoDe = (event) => {
    setDataSolicitacaoDe(event.target.value);
  };
  const handleSolicitacaoAte = (event) => {
    setDataSolicitacaoAte(event.target.value);
  };
  const handlePagamento = (event) => {
    setPagamento(event.target.value);
  };
  const handleTransacao = (event) => {
    setTransacao(event.target.value);
  };
  function novoRegistro(){
    history.push('/backoffice/eventos-cadastro')
  }
  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URLAlfa = `https://00cb-189-39-31-233.ngrok.io/multipague-consulta-mercado/v1/relatorio?idClienteConta=2`
  const URL = `/relatorio?idClienteConta=${contaIdCliente}`;
  const URLPesquisa = `/relatorio?idClienteConta=${contaIdCliente}&dataInicial=${dataSolicitacaoDe}&dataFinal=${dataSolicitacaoAte}&razaoSocial=${descricao}&cnpj=${codigo}`;

    useEffect(() => {
      setLoadButton(true)
      api_multipague_consulta_mercado
      // axios
        .get(URL, { headers: { Authorization: AuthStr }})
        .then(response => {
          setLoadButton(false)
          setLoadButtonAccount(false)
          setLoadButtonEmail(false)
          const monitorFilter = response.data
          console.log(monitorFilter)

           if(monitorFilter.length){
              const aquiTable = monitorFilter.map((data) => ({
                ...data,
                id:data.id,
                cpfCnpj: data.cnpj,
                razaoSocial: data.razaoSocial,
                vencimento: moment(new Date(data.data)).format('DD/MM/YYYY'),
                status: data.status
              }))
              setRows(aquiTable)
            }else{
              toastComponentError("Nenhum dado disponível para essa data!")
              }
        })
        .catch(error => {
          setLoadButton(false)
          // if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
          //   toastComponentError(error.response.data.mensagem, {
          //     position: "top-right",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //   }
          //     );
          // }
        });
    }, [contaIdCliente]);

    useEffect(() => {
      api_multipague_consulta_mercado
        .get(`/relatorio/${ultimaConsulta}/pdf?idClienteConta=${contaIdCliente}`)
          .then(response => {
            const pdf64Exists = response.data
            setPdfExists(pdf64Exists)
            console.log("PDF!!! :",pdfExists )
          })
          .catch(error => {
            setLoadButton(false)
            if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
              toastComponentError(error.response.data.mensagem);
            }
          });
    },[ultimaConsulta])

    function limpar() {
      setLoadButton(true)
      window.location.href = '/consulta-mercado';
    }

  function pesquisar() {
    setLoadButton(true)
    api_multipague_consulta_mercado
      .get(URLPesquisa)
      .then(response => {
        setLoadButton(false)
        const monitorFilter = response.data

        if(monitorFilter.length){
          const aquiTable = monitorFilter.map((data) => ({
            ...data,
            id:data.id,
            cpfCnpj: data.cnpj,
            razaoSocial: data.razaoSocial,
            vencimento: moment(new Date(data.data)).format('DD/MM/YYYY'),
            status: data.status
          }))
          setRows(aquiTable)
        }else{
          toastComponentError("Nenhum dado disponível para essa data!")
          }
      })
      .catch(error => {
        setLoadButton(false)
        if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
          toastComponentError(error.response.data.mensagem)
        }
       setRows([])
      })
  }

  function json(url) {
    return fetch(url).then(res => res.json());
  }

  let apiKey = '8dc4a62c86a09cd7519c9db6fb8436127d13e50f497ca11628cc130d';
  json(`https://api.ipdata.co?api-key=${apiKey}`).then(data => {
    console.log(data.ip);
    setIp(data.ip)
    console.log(data.city);
    console.log(data.country_code);
    // so many more properties
  });

  function novaConsultaCnpj(consult) {
    return consult.cnpj === cnpj;
  }

  console.log(rows.find(novaConsultaCnpj));

  const fazerNovaConsulta = value =>  {
    json()
    setLoadButton(true)
    api_multipague_consulta_mercado
      .post(`/relatorio?idClienteConta=${contaIdCliente}&cnpj=${cnpj.replace(/[^0-9]+/g, '')}&ip=${ip}`)
      .then(response => {
        // refreshConsulta()
        setLoadButton(false)
        setOpenProsseguir(false)
        toastComponentSuccess("Enviada solicitação de consulta!");
          setTimeout(function(){ window.location.href = "/consulta-mercado" },3000)
      })
      .catch(error => {
        setLoadButton(false)
        if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
          toastComponentError(error.response.data.mensagem);
        }
      })
    // ) : (
    // console.log("Já existe", existeCnpj)
    // )
  }

  const novaConsulta = value => {
    json()
    setLoadButton(true)
    api_multipague_consulta_mercado
      .get(`/relatorio?idClienteConta=${contaIdCliente}&cnpj=${cnpj.replace(/[^0-9]+/g, '')}&dataInicial=${moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')}&dataFinal=${moment(new Date(Date.now())).format('YYYY-MM-DD')}&status=CONCLUIDO`)
      .then(response => {
        // refreshConsulta()
        setLoadButton(false)
        setOpen(false);
        setOpenProsseguir(true)
        setUltimaConsulta(response.data[0].id)
        // toast.success("Nova consulta realizada!", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // }
          // );
      })
      .catch(response => {
        setLoadButton(false)
        setOpen(false);
        setOpenProsseguir2(true)
        setUltimaConsulta(response.data[0].id)
        // setAbrirTermos1(true)
        // fazerNovaConsulta()
        // if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
        //   toastComponentError(error.response.data.mensagem, {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   }
        //     );
        // }
      })
    // ) : (
    // console.log("Já existe", existeCnpj)
    // )
  }

  function refreshConsulta() {
    api_multipague_consulta_mercado
      // axios
        .get(URL, { headers: { Authorization: AuthStr }})
        .then(response => {
          setLoadButton(false)
          setLoadButtonAccount(false)
          setLoadButtonEmail(false)
          const monitorFilter = response.data
          console.log(monitorFilter)

           if(monitorFilter.length){
              const aquiTable = monitorFilter.map((data) => ({
                ...data,
                id:data.id,
                cpfCnpj: data.cnpj,
                razaoSocial: data.razaoSocial,
                vencimento: moment(new Date(data.data)).format('DD/MM/YYYY'),
                status: data.status
              }))
              setRows(aquiTable)
            }else{
              toastComponentError("Nenhum dado disponível para essa data!")
              }
        })
        .catch(error => {
          setLoadButton(false)
          if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
            toastComponentError(error.response.data.mensagem);
          }
        });
  }

  const abrindoTermos = value => {
    setAbrirTermos(true)
  }

  const imprimirExistente = value => {
      api_multipague_consulta_mercado
      .get(`/relatorio/${ultimaConsulta}/pdf?idClienteConta=${idCliente}`)
        .then(response => {
          setLoadButton(false)
          const pdf64Exists = response.data
          setPdfExists(pdf64Exists)
          setTermo(true)
          // setOpenPdf(true)
          // window.location.href = `data:application/pdf;base64,${pdfExists}`
        })
        .catch(error => {
          setLoadButton(false)
          if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
            toastComponentError(error.response.data.mensagem);
          }
        });
    }

  const handleEditar = value => {
    const pdfId = value.id
      api_multipague_consulta_mercado
      // axios
        .get(`/relatorio/${pdfId}/pdf?idClienteConta=${contaIdCliente}`)
        .then(response => {
          setLoadButton(false)
          const pdf64 = response.data
          setPdf(pdf64)
          setOpenPdf(true)
        })
        .catch(error => {
          setLoadButton(false)
          if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
            toastComponentError(error.response.data.mensagem);
          }
        });
    }


  return (
    <Wrapper
      title="Consulta de Mercado"
      crumb={[
        {name:"Dashboard",link:"/dashboard"}
      ]}
      newRegister={handleOpen}
      newRegisterLabel="Nova Consulta"
    >


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPdf}
        onClose={handleClosePDF}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPdf}>
          <div className={classes.paper} style={{maxWidth:'70vw'}}>
            <h2 id="transition-modal-title">Deseja fazer o download do PDF ?</h2>
            {/* <a download='pdfTitle' href={`data:application/pdf;base64,${pdf}`} title='Download pdf document'>Download PDF</a> */}
            {linkPdf}
            {/* <Document
              file={`data:application/pdf;base64,${pdf}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber='1' />
            </Document> */}
            {/* <embed style={{ width:'100%', height:'100%' }} name="plugin" src={pdf} /> */}
                <div style={{display:'flex', justifyContent:'space-around'}}>
                <LoadingBtn

                    variant="contained"
                    color="secondary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                    onClick={handleClosePDF}
                >
                    Cancelar
                </LoadingBtn>
                <LoadingBtn
                    href={`data:application/pdf;base64,${pdf}`}
                    download='pdfTitle'
                    variant="contained"
                    color="primary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                >
                    Download PDF
                </LoadingBtn>
            </div>
          </div>

        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{maxWidth:'70vw'}}>
            <span style={{marginTop:'20px'}}>Digite abaixo o CNPJ que deseja consultar</span>
            <Input
                fullWidth
                label="CNPJ"
                id="outlined-size-small"
                className={classes.cnpj}
                // value={inputValuesCnpj.textmaskcnpj}
                inputComponent={TextMaskCustomCnpj}
                onChange={handleChangeInputCnpj}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
                <div style={{display:'flex', justifyContent:'space-between'}}>
                <LoadingBtn
                    variant="contained"
                    color="secondary"
                    className={classes.addButton}
                    isLoading={loadButton}
                    onClick={handleClose}

                >
                    Cancelar
                </LoadingBtn>
                <LoadingBtn
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                    isLoading={loadButton}
                    onClick={novaConsulta}
                >
                    Prosseguir
                </LoadingBtn>
                {abrirTermos1 && (
            <div>
              <h1>Termo de Aceite</h1>
              <p>De acordo com o contrato do Multisoluções, estou aderindo à plataforma
                 do Credit Box no MultiSoluções , estou ciente e concordo que as consultas
                  ficarão registradas através da minha senha de acesso, com data e hora e
                   autorizo a cobrança de R$ 8,00 do serviço prestado.</p>
              <LoadingBtn
                    onClick={fazerNovaConsulta}
                    // href={`data:application/pdf;base64,${pdfExists}`}
                    // download='pdfTitle'
                    variant="contained"
                    color="primary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                >
                    Aceito e Consultar
                </LoadingBtn>
            </div>
            )}
            </div>
          </div>

        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openProsseguir}
        onClose={handleCloseProsseguir}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProsseguir}>
          <div className={classes.paper} style={{maxWidth:'70vw'}}>
            <div style={{margin:'auto'}}>
            <span style={{marginTop:'20px'}}>Já existem consultas referente a este CNPJ nos últimos 10 dias</span>
            </div>

                <div style={{display:'flex', justifyContent:'space-around', marginBottom:'50px'}}>
                {!abrirTermos && (
                <LoadingBtn
                    variant="contained"
                    color="secondary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                    // onClick={fazerNovaConsulta}
                    onClick={abrindoTermos}
                >
                    Fazer Nova Consulta
                </LoadingBtn>
                )}
                <LoadingBtn
                    href={`data:application/pdf;base64,${pdfExists}`}
                    download='pdfTitle'
                    variant="contained"
                    color="primary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                    // onClick={imprimirExistente}
                    // onClick={setAbrirTermos(true)}
                >
                    Ver Consulta Existente
                </LoadingBtn>
            </div>
            <div style={{textAlign:'center'}}>
            <LoadingBtn
                    variant="contained"
                    color="default"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                    // onClick={fazerNovaConsulta}
                    onClick={handleCloseProsseguir}
                >
                    Cancelar
                </LoadingBtn>
            </div>
            {abrirTermos && (
            <div>
              <h3>Termo de Aceite</h3>
              <p>De acordo com o contrato do Multisoluções, estou aderindo à plataforma
                 do Credit Box no MultiSoluções , estou ciente e concordo que as consultas
                  ficarão registradas através da minha senha de acesso, com data e hora e
                   autorizo a cobrança de R$ 8,00 do serviço prestado.</p>
                   <div style={{display: 'flex', justifyContent:"space-between"}}>
              <LoadingBtn
                    onClick={handleCloseProsseguir}
                    // href={`data:application/pdf;base64,${pdfExists}`}
                    // download='pdfTitle'
                    variant="contained"
                    color="secondary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                >
                    Cancelar
                </LoadingBtn>
              <LoadingBtn
                    onClick={fazerNovaConsulta}
                    // href={`data:application/pdf;base64,${pdfExists}`}
                    // download='pdfTitle'
                    variant="contained"
                    color="primary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                >
                    Aceito e Consultar
                </LoadingBtn>
                </div>
            </div>
            )}
          </div>

        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openProsseguir2}
        onClose={handleCloseProsseguir2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProsseguir2}>
          <div className={classes.paper} style={{maxWidth:'70vw'}}>
            {/* <div style={{margin:'auto', textAlign:'center'}}>
              <span style={{marginTop:'20px'}}>Confira o termo de aceite</span>
            </div> */}
                <div>
                <h1>Termo de Aceite</h1>
              <p>De acordo com o contrato do Multisoluções, estou aderindo à plataforma
                 do Credit Box no MultiSoluções , estou ciente e concordo que as consultas
                  ficarão registradas através da minha senha de acesso, com data e hora e
                   autorizo a cobrança de R$ 8,00 do serviço prestado.</p>
                   <div style={{display:'flex', justifyContent: 'space-between'}}>
                   <LoadingBtn
                    onClick={handleCloseProsseguir2}
                    // href={`data:application/pdf;base64,${pdfExists}`}
                    // download='pdfTitle'
                    variant="contained"
                    color="secondary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                >
                    Cancelar
                </LoadingBtn>
              <LoadingBtn
                    onClick={fazerNovaConsulta}
                    // href={`data:application/pdf;base64,${pdfExists}`}
                    // download='pdfTitle'
                    variant="contained"
                    color="primary"
                    width="185"
                    className={classes.addButton}
                    isLoading={loadButton}
                >
                    Aceito e Consultar
                </LoadingBtn>
                </div>
            </div>
          </div>

        </Fade>
      </Modal>
      <Box>
          <Grid container spacing={3} style={{marginBottom:"0.8rem"}}>
            <Grid item xs={12} md={2} lg={2}>
            <TextField
                    fullWidth
                    label="CPF/CNPJ"
                    id="outlined-size-small"
                    onChange={(e) => { setCodigo(e.target.value) }}
                    // defaultValue="Small"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                    fullWidth
                    label="Nome Completo"
                    id="outlined-size-small"
                    onChange={(e) => { setDescricao(e.target.value) }}
                    // defaultValue="Small"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <TextField
                fullWidth
                label="Data Inicial:"
                id="outlined-size-small"
                // defaultValue="Small"
                onChange={handleSolicitacaoDe}
                variant="outlined"
                type="date"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <TextField
                fullWidth
                label="Data Final:"
                id="outlined-size-small"
                // defaultValue="Small"
                variant="outlined"
                onChange={handleSolicitacaoAte}
                type="date"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12} style={{display: 'flex',gap:"1rem",justifyContent:"flex-end"}}>
            <LoadingBtn
                variant="contained"
                color="default"
                width="115"
                className={classes.searchButton}
                isLoading={loadButton}
                startIcon={<MdClear />}
                onClick={() => {
                  limpar();
                }}
              >Limpar
              </LoadingBtn>
              <LoadingBtn
                variant="contained"
                color="primary"
                width="115"
                className={classes.searchButton}
                isLoading={loadButton}
                startIcon={!loadButton&& <MdSearch />}
                onClick={() => {
                  pesquisar();
                }}
              >
                Pesquisar
              </LoadingBtn>
            </Grid>
          </Grid>
        </Box>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loadButton}
          pageSize={10}
          height="500px"
          />
    </Wrapper>
  );
}
