import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const AccountContext = createContext({});

export const AccountProvider = (props) => {
    const infoRedux = useSelector(state => state.contas);
    useEffect(() => {
        // !!infoRedux.contasCliente.length &&
        const listandoContas = infoRedux.contasCliente.map((item, idx) => {
            return item
        // const fundoSelecionado = (item.id == infoRedux.contaPadrao?.id) ? true : false
        })
        
        setListaContas(listandoContas);
    }, [infoRedux]);
    useEffect(() => {
        // !!infoRedux.contasCliente.length &&
        const listandoPadrao = infoRedux.contaPadrao
        
        setListaContaPadrao(listandoPadrao);
    }, [infoRedux]);
    
  const [listaContas, setListaContas] = useState([]);
  const [listaContaPadrao, setListaContaPadrao] = useState();

    // setDarkState(darkState === true ? false : true);
    console.log("context contas",listaContas, listaContaPadrao)

  return (
    <AccountContext.Provider value={{ listaContas, setListaContas, listaContaPadrao, setListaContaPadrao }}>{props.children}</AccountContext.Provider>
  );
}
export const accountForm = () => useContext(AccountContext)
