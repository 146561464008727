import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { api_multipague_agenda_financeira } from '../../src/services/api';
import formattedMoney from '../util/FormatteMoney/index';
import { useSelector } from 'react-redux';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import DateRange from '@material-ui/icons/DateRange';
import { DashboardCard } from './DashboardCards';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';

export default function AgendaFinanceira() {
  const theme = useTheme();
  const infoRedux = useSelector(state => state.contas);
  const [error, setError] = React.useState(false);
  const [mensagemErro, setMensagemErro] = React.useState('');
  const [carregando, setCarregando] = useState(true);

  const [informacoes, setInformacoes] = useState({
    valorTotalEntrada: '0,00',
    valorTotalSaida: '0,00',
    agendasClienteContas: [],
  });

  useEffect(() => {
    if (infoRedux.contaPadrao?.cliente?.id && infoRedux.contaPadrao?.id) {
      setCarregando(true);
      const idClientePadrao = infoRedux.contaPadrao;
      const hoje = new Date();
      const final = new Date().setDate(hoje.getDate() + 365);
      api_multipague_agenda_financeira
        .get(
          `/agenda-financeira?cliente=${
            idClientePadrao.cliente.id
          }&clienteConta=${idClientePadrao.id}&dataInicial=${moment(
            new Date(hoje),
          ).format('YYYY-MM-DD')}&dataFinal=${moment(new Date(final)).format(
            'YYYY-MM-DD',
          )}`,
        )
        .then(response => {
          setInformacoes(response.data);
          setCarregando(false);
          localStorage.setItem('infoReduxInfo', JSON.stringify(infoRedux));
        })
        .catch(err => {
          setError(true);
          setCarregando(false);
          setMensagemErro(
            err?.response?.data?.mensagem
              ? err?.response?.data?.mensagem
              : 'Não foi possível conectar-se ao servidor',
          );
        });
    }
  }, [infoRedux]);

  return (
    <DashboardCard
      title="Agenda Financeira"
      icon={
        <DateRange
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      children={
        <>
          {error ? (
            <MessageErrorComponent mensagemErro={mensagemErro} />
          ) : (
            <>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.text.primary,
                    fontWeight: 'bold',
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                  }}
                >
                  <AiOutlinePlusCircle
                    size={25}
                    style={{ paddingRight: '5px' }}
                    color={'green'}
                  />
                  Valor de entrada:
                </Typography>
                {informacoes.valorTotalEntrada == 0
                  ? 'R$ 0,00'
                  : formattedMoney(informacoes.valorTotalEntrada)}
              </Box>
              <Divider />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: theme.palette.text.primary,
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                  }}
                >
                  <AiOutlineMinusCircle
                    size={25}
                    style={{ paddingRight: '5px' }}
                    color="red"
                  />
                  Valor de saída:
                </Typography>
                {informacoes.valorTotalSaida == 0
                  ? 'R$ 0,00'
                  : formattedMoney(informacoes.valorTotalSaida)}
              </Box>
            </>
          )}
        </>
      }
      carregando={carregando}
    />
  );
}
