import moment from 'moment';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { IoCopy } from 'react-icons/io5';
import { v4 as uuidv4 } from 'uuid';
import Buttons from '../../components/Buttons/ConfirmButton';
import ContainerComponent from '../../components/Container';
import Boleto from '../../components/Inputs/Boleto';
import CPF from '../../components/Inputs/CPF';
import Money from '../../components/Inputs/Money';
import InputText from '../../components/InputText';
import RadioGroup from '../../components/RadioButton';
import SelectComponent from '../../components/Select';
import DateComponent from '../../components/InputDate';
import { api_multipague_cobranca } from '../../services/api';
import * as Yup from 'yup';
import getValidationErros from '../../util/FormattedError';
import { FormattFloatNumber } from '../../util/FormatToFloatNumber';
import PopUp from '../../components/PopUp';
import Password from '../../components/Password';
import { api_multipague_esteira } from '../../services/api';

import {
  AgendamentoPagamento,
  BancoPagador,
  Container,
  ContainerButton,
  CpfCnpjBeneficiario,
  DadosBoleto,
  DadosCadastrais,
  DadosSecundariosBoleto,
  Data,
  DataVencimento,
  DigitoBoleto,
  NomeFantasia,
  Subcontainer,
  Valor,
} from './styles';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
function PayTicket() {
  const root = {
    path: [
      {
        name: 'Esteira',
        caminho: '/backoffice/Esteira',
      },
    ],
    pagAtual: 'Pesquisar',
  };
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { idBoleto } = useParams();
  const md5 = require('md5');
  const history = useHistory();
  const [centroCusto, setCentroCusto] = useState([]);
  const [infosPagamento, setInfosPagamento] = useState({});
  const [dataAgendamento, setDataAgendamento] = useState(Date.now());
  const [dataVencimento, setDataVencimento] = useState(Date.now());
  const [paymentDate, setPaymentDate] = useState([
    {
      label: 'Hoje',
      value: uuidv4(),
      selected: false,
      agendado: false,
    },
    {
      label: 'Agendamento',
      value: uuidv4(),
      selected: false,
      agendado: true,
    },
  ]);
  const [agendamentoFormaPagamento, setAgendamentoFormaPagamento] = useState(
    {},
  );
  const [carregarBoleto, setCarregarBoleto] = useState(false);

  const [valueState, setValueState] = useState({
    // value: 1278.1,
    value: 0,
    error: '',
  });

  useEffect(() => {
    ListDadosBoleto();
  }, []);

  async function ListDadosBoleto() {
    try {
      dispatch(showLoading(true));
      const { data } = await api_multipague_esteira.get(
        `/administracao/pagamento-boleto?codigoPagamentoBoleto=${idBoleto}`,
      );
      if (data.length) {
        formRef.current.setData({
          linhaDigitavel: data[0].dadosPagamento.linhaDigitavel,
          nomePagador: data[0].pagador.nome || '',
          tituloDocumentoFederal: data[0].pagador.documentoFederal || '',
          bancoPagador: data[0].banco.nome || '',
          valorTitulo: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          }).format(data[0].dadosPagamento.valorTitulo),
          descricaoHistorico: data[0].historico || '',
          centroCusto: data[0].dadosPagamento.descricaoCentroCusto || '',
          valorPagamento: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          }).format(data[0].dadosPagamento.valorPagamento),
          dataVencimento: moment(data[0].dadosPagamento.dataVencimento).format(
            'DD/MM/YYYY',
          ),
          dataPagamento: moment(data[0].dadosPagamento.dataPagamento).format(
            'DD/MM/YYYY',
          ),
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(hideLoading(false));
    }
  }

  function selecionarDiaPagamento(data) {
    const dataSelecionada = paymentDate.map(val =>
      val.value === data
        ? {
            ...val,
            selected: true,
          }
        : {
            ...val,
            selected: false,
          },
    );
    const filterDalaSelecionada = dataSelecionada.filter(date => date.selected);
    setAgendamentoFormaPagamento(filterDalaSelecionada[0]);
    setPaymentDate(dataSelecionada);
  }

  const mostrarDataAgendamento = () => {
    if (
      agendamentoFormaPagamento.agendado &&
      agendamentoFormaPagamento.selected
    ) {
      return (
        <DateComponent
          id="data-pagamento-agendado"
          label="Agendar Pagamento"
          callback={setDataAgendamento}
          value={dataAgendamento}
        />
      );
    }
  };

  async function submitTed(senha) {}

  function callBackLinhaDigitavel(value) {
    console.log('============================>', value);
  }

  return (
    <>
      <PopUp>
        <Password callback={data => submitTed(data)} />
      </PopUp>
      <ContainerComponent root={root}>
        <Container ref={formRef} initialData={infosPagamento}>
          <Subcontainer>
            <DigitoBoleto>
              <Boleto
                name="linhaDigitavel"
                id="linhaDigitavel"
                variant="outlined"
                label="Linha digitável"
                callback={callBackLinhaDigitavel}
                isLoading={carregarBoleto}
                disabled
              />
            </DigitoBoleto>
            <DadosCadastrais>
              <NomeFantasia>
                <InputText
                  id="nome-fantasia-beneficiario"
                  variant="outlined"
                  label="Nome fantasia Beneficiario"
                  name="nomePagador"
                  disabled
                />
              </NomeFantasia>
              <InputText
                id="valorPagamento"
                label="Valor pagamento"
                name="valorPagamento"
                disabled
              />
              <CpfCnpjBeneficiario>
                <CPF
                  id="tituloDocumentoFederal"
                  label="CPF/CNPJ Beneficiario"
                  name="tituloDocumentoFederal"
                  disabled
                />
              </CpfCnpjBeneficiario>
            </DadosCadastrais>
            <DadosBoleto>
              <BancoPagador>
                <InputText
                  id="bancoPagador"
                  variant="outlined"
                  label="Banco pagador"
                  name="bancoPagador"
                  disabled
                />
              </BancoPagador>

              <DataVencimento>
                <InputText
                  id="dataVencimento"
                  label="Data vencimento"
                  name="dataVencimento"
                  disabled
                />
              </DataVencimento>
              <Valor>
                <InputText
                  id="valorTitulo"
                  label="Valor titulo"
                  name="valorTitulo"
                  disabled
                />
              </Valor>
            </DadosBoleto>
            <DadosSecundariosBoleto>
              <InputText
                label="Centro de custo"
                id="list_centro_custo"
                name="centroCusto"
                disabled
              />
              <InputText
                id="historico"
                label="Historico"
                multiline
                rows={4}
                variant="outlined"
                name="descricaoHistorico"
                disabled
              />
            </DadosSecundariosBoleto>
            <AgendamentoPagamento>
              <InputText
                id="dataPagamento"
                name="dataPagamento"
                label="Data pagamento"
                disabled
              />
            </AgendamentoPagamento>
            <ContainerButton>
              {/* <Button color="primary" variant="contained" width={150}>
              Cancelar
            </Button> */}
              <Buttons
                variant="contained"
                disabled={false}
                width={100}
                onClick={() => history.goBack()}
                type="button"
              >
                Voltar
              </Buttons>
            </ContainerButton>
          </Subcontainer>
        </Container>
      </ContainerComponent>
    </>
  );
}

export default PayTicket;
