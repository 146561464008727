import styled, { css } from 'styled-components';
import CurrencyInput from 'react-currency-input';
import { styled as MuiStyled, Box } from '@material-ui/core';

export const Container = MuiStyled(Box)(({ focus, error, theme }) => {
  console.log('focus error', focus, error, theme);
  return {
    width: '100%',
    height: ' 38px',
    fontSize: '14px',
    position: 'relative',
    borderRadius: '20px',
    border: 'solid 1px rgba(0, 0, 0, 0.23)',
    ...(focus && {
      border: `solid 2px ${theme.palette.primary[theme.palette.type]}`,
    }),
    ...(error && {
      border: `solid 1px  ${theme.palette.error[theme.palette.type]}`,
    }),
  };
});

export const Label = MuiStyled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    padding: '5px',
    top: '-12px',
    left: '8px',
    backgroundColor: theme.palette.background.paper,
    '& p': {
      color:
        theme.palette.type === 'dark'
          ? theme.palette.text.primary
          : theme.palette.text.secondary,
      padding: '0',
      fontSize: '1rem',
      fontFamily: "'CoreLigth', sans-serif !important",
      fontWeight: '400',
      lineHeight: '1',
      letterSpacing: '0.00938em',
      fontSize: '10px',
    },
  };
});

export const ContainerInput = styled.div`
  width: 90%;
  padding-left: 14px;
  padding-top: 4px;
`;

export const Input = MuiStyled(CurrencyInput)(({ theme }) => {
  return {
    width: '100%',
    height: ' 30px',
    border: ' none',
    fontSize: ' inherit',
    color:
      theme.palette.type === 'dark'
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
    backgroundColor: 'transparent !important',
    ' &:focus': {
      outline: 'none',
    },
  };
});

export const ContainerError = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Message = MuiStyled('p')(({ theme }) => {
  return {
    margin: '0',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    marginLeft: '14px',
    marginRight: '14px',
    color: theme.palette.system.dark,
  };
});
