/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DadosBoleto from '../../components/DadosBoleto';
import DadosPagador from '../../components/DadosPagador';
import CNBAOption from '../../components/InputCNBA';
import Intrucoes from '../../components/Intrucoes';
import RadioButton from '../../components/RadioButton';
import {
  api_multipague_cobranca,
  api_multipague_configuracao,
} from '../../services/api';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';

import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import {
  Abas,
  ContainerAbas,
  ContainerAbasSelection,
  OptionSelected,
  ContainerErrorDescription,
} from './styles';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CNAB = ({ isEdit }) => {
  const { idBoleto } = useParams();
  useEffect(() => {
    if (!isEdit) {
      localStorage.removeItem('data_pagamento_boleto');
      localStorage.removeItem('data_pagamento_boleto2');
      localStorage.removeItem('data_pagador');
      localStorage.removeItem('data_instrucao');
    }
  }, [isEdit]);
  const history = useHistory();
  const dispatch = useDispatch();

  const infoDigitacaoBoleto = useSelector(state => state.boletoDigitacao);
  const { contaPadrao } = useSelector(state => state.contas);
  const { userAccount } = useSelector(state => state.user);
  const [isSalvarDadosDigitacao, setIsSalvarDadosDigitacao] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [optionTitle, setOptionTitle] = useState([
    {
      label: 'CNAB',
      value: '04b27d14-1080-4789-bf59-e39d60197a30',
      selected: isEdit ? false : true,
    },
    {
      label: 'Digitação',
      value: '4e520851-7136-4a5b-ad51-c47d4c329d0d',
      selected: isEdit ? true : false,
    },
  ]);

  const [optionSelected, setOptionSelected] = useState({});

  useEffect(() => {
    const filter = optionTitle.filter(val => val.selected);
    if (filter.length) {
      setOptionSelected(filter[0]);
    }
  }, []);

  const [abasDescricao, setAbasDescricao] = useState([
    {
      label: 'Dados Boleto',
      id: 1,
      select: true,
    },
    {
      label: 'Dados Pagador',
      id: 2,
      select: false,
    },
    {
      label: 'Instruções',
      id: 3,
      select: false,
    },
  ]);

  const [tipoOperacao, setTipoOperacao] = useState('COBRANCA_SIMPLES');

  function SetOption(data) {
    const formattedOptions = optionTitle.map(options => ({
      ...options,
      selected: data === options.value,
    }));

    const filterSelected = formattedOptions.filter(
      selected => selected.selected === true,
    );

    if (filterSelected.length) {
      setOptionSelected(filterSelected[0]);
    }

    setOptionTitle(formattedOptions);
  }

  function abaSelecionadaDesktop() {
    const abaEscolhida = abasDescricao.filter(aba => aba.select === true);
    if (abaEscolhida.length) {
      return renderizaConteudoAba(abaEscolhida[0].id);
    }
    return null;
  }

  useEffect(() => {
    if (isSalvarDadosDigitacao) {
      salvarDadosBoleto();
    }
  }, [infoDigitacaoBoleto]);

  const renderizaConteudoAba = useCallback(id => {
    switch (id) {
      case 1:
        return (
          <DadosBoleto
            selecteAba={callBackSelectAba}
            isSalve={setIsSalvarDadosDigitacao}
            isEditing={isEdit}
          />
        );
      case 2:
        return (
          <DadosPagador
            selecteAba={callBackSelectAba}
            isSalve={setIsSalvarDadosDigitacao}
            isEditing={isEdit}
          />
        );
      case 3:
        return (
          <Intrucoes
            selecteAba={callBackSelectAba}
            sendSubmitForm={salvarDadosBoleto}
            isEditing={isEdit}
            idBoleto={idBoleto}
            isSalve={setIsSalvarDadosDigitacao}
          />
        );
      default:
        return null;
    }
  }, []);

  function RenderizaOpcoesDigitacao() {
    if (windowSize >= 1024) {
      return (
        <ContainerAbasSelection>
          <ContainerAbas>
            {abasDescricao.map(aba => (
              <Abas key={aba.id} active={aba.select}>
                <p>{aba.label}</p>
              </Abas>
            ))}
          </ContainerAbas>
          {abaSelecionadaDesktop()}
        </ContainerAbasSelection>
      );
    }
    return (
      <ContainerAbasSelection>
        {abasDescricao.map(aba => (
          <ContainerAbas key={aba.id}>
            <Abas key={aba.id} active={aba.select}>
              <p>{aba.label}</p>
            </Abas>

            {aba.select === true ? renderizaConteudoAba(aba.id) : ''}
          </ContainerAbas>
        ))}
      </ContainerAbasSelection>
    );
  }

  const callBackSelectAba = useCallback(id => {
    const abaSelected = abasDescricao.map(data => ({
      ...data,
      select: id === data.id,
    }));
    setAbasDescricao(abaSelected);
  }, []);

  const salvarDadosBoleto = useCallback(async () => {
    if (
      Object.keys(infoDigitacaoBoleto.data.boleto).length != 0 &&
      Object.keys(infoDigitacaoBoleto.data.instrucoes).length != 0 &&
      Object.keys(infoDigitacaoBoleto.data.pagador).length != 0 &&
      isSalvarDadosDigitacao
    ) {
      dispatch(showLoading(true));

      try {
        const body = {
          ...infoDigitacaoBoleto.data.boleto,
          ...infoDigitacaoBoleto.data.pagador,
          mensagem1: infoDigitacaoBoleto.data.instrucoes.mensagem1,
          mensagem2: infoDigitacaoBoleto.data.instrucoes.mensagem2,
          observacao: infoDigitacaoBoleto.data.instrucoes.observacao,
          percAbatimento: Number(
            infoDigitacaoBoleto.data.boleto.percAbatimento,
          ),
          codigoClienteConta: contaPadrao.id,
          instrucaoBeneficiario: '',
          tipoOperacao: localStorage.getItem('tipodaOperacao'),
          instrucoes: [],
          valorAbatimento: Number(
            infoDigitacaoBoleto.data.boleto.valorAbatimento,
          ),
          valorTitulo: Number(infoDigitacaoBoleto.data.boleto.valorTitulo),
          id: isEdit ? Number(idBoleto) : null,
          codigoMotivo:
            isEdit && Number(infoDigitacaoBoleto.data.instrucoes.motivo),
          codigoTipoInstrucao:
            isEdit && Number(infoDigitacaoBoleto.data.instrucoes.instrucao),
        };

        infoDigitacaoBoleto.data.instrucoes.tipo_calculo_juros != 'ISENTO' &&
          body.instrucoes.push({
            data:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_juros != 'ISENTO'
                ? infoDigitacaoBoleto.data.instrucoes.dataJurosFormatted
                : null,
            tipo:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_juros != 'ISENTO'
                ? infoDigitacaoBoleto.data.instrucoes.tipo_calculo_juros
                : null,
            id: isEdit
              ? Number(infoDigitacaoBoleto.data.instrucoes.id_juros)
              : null,
            valor:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_juros != 'ISENTO'
                ? Number(infoDigitacaoBoleto.data.instrucoes.valor_juros)
                : null,
          });

        infoDigitacaoBoleto.data.instrucoes.tipo_calculo_multa != 'ISENTO' &&
          body.instrucoes.push({
            data:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_multa != 'ISENTO'
                ? infoDigitacaoBoleto.data.instrucoes.dataMultaFormatted
                : null,
            tipo:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_multa != 'ISENTO'
                ? infoDigitacaoBoleto.data.instrucoes.tipo_calculo_multa
                : null,
            id: isEdit
              ? Number(infoDigitacaoBoleto.data.instrucoes.id_multa)
              : null,
            valor:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_multa != 'ISENTO'
                ? Number(infoDigitacaoBoleto.data.instrucoes.valor_multa)
                : null,
          });

        infoDigitacaoBoleto.data.instrucoes.tipo_calculo_desconto != 'ISENTO' &&
          body.instrucoes.push({
            data:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_desconto !=
              'ISENTO'
                ? infoDigitacaoBoleto.data.instrucoes.dataDescontoFormatted !=
                  'Data inválida'
                  ? infoDigitacaoBoleto.data.instrucoes.dataDescontoFormatted
                  : null
                : null,
            tipo:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_desconto !=
              'ISENTO'
                ? infoDigitacaoBoleto.data.instrucoes.tipo_calculo_desconto
                : null,
            id: isEdit
              ? Number(infoDigitacaoBoleto.data.instrucoes.id_desconto)
              : null,
            valor:
              infoDigitacaoBoleto.data.instrucoes.tipo_calculo_desconto !=
              'ISENTO'
                ? Number(infoDigitacaoBoleto.data.instrucoes.valor_desconto)
                : null,
          });

        if (isEdit) {
          const { data } = await api_multipague_cobranca.put(
            `cobranca-simples/${idBoleto}`,
            body,
          );
        } else {
          const { data } = await api_multipague_cobranca.post(
            `cobranca-simples`,
            body,
          );
        }

        toastComponentSuccess('Boleto salvo com sucesso');
        localStorage.removeItem('data_pagamento_boleto');
        localStorage.removeItem('data_pagador');
        localStorage.removeItem('data_instrucao');

        history.push('/Buscar/Boleto');

        dispatch(hideLoading(false));
      } catch (err) {
        dispatch(hideLoading(false));
        toastComponentError(
          `Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  }, [infoDigitacaoBoleto, isSalvarDadosDigitacao]);

  const handleMudarOperacao = event => {
    setTipoOperacao(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem('tipodaOperacao', tipoOperacao);
  }, [tipoOperacao]);

  useEffect(() => {
    if (!!userAccount?.id && !!contaPadrao?.id && !isEdit) {
      loadDefaultValueCarteira();
    }
  }, [isEdit, userAccount?.id, contaPadrao?.id]);

  async function loadDefaultValueCarteira() {
    try {
      dispatch(showLoading(true));
      const { data } = await api_multipague_configuracao.get(
        `/carteira?cliente=${userAccount.id}&clienteConta=${contaPadrao.id}`,
      );
      console.log(data);
      if (data.length) {
        localStorage.setItem('carteira_cliente', JSON.stringify(data[0]));
        const tipoJuros =
          (data[0].tipoJuros === 'VALOR' && 'VALOR_JUROS') ||
          (data[0].tipoJuros === 'PERCENTUAL_MES' && 'PERCENTUAL_JUROS_MES') ||
          (data[0].tipoJuros === 'PERCENTUAL_DIA' && 'PERCENTUAL_JUROS_DIA') ||
          (data[0].tipoJuros === 'ISENTO' && 'ISENTO');
        const tipoMulta =
          (data[0].tipoMulta === 'VALOR_FIXO' && 'VALOR_MULTA') ||
          (data[0].tipoMulta === 'PERCENTUAL' && 'PERCENTUAL_MULTA') ||
          (data[0].tipoMulta === 'ISENTO' && 'ISENTO');

        const localStorageData = {
          valor_juros: data[0].taxaJuros,
          valor_multa: data[0].taxaMulta,
          tipo_calculo_juros: tipoJuros,
          tipo_calculo_multa: tipoMulta,
        };
        localStorage.setItem(
          'data_instrucao',
          JSON.stringify(localStorageData),
        );
      }
      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }
  const classes = useStyles();

  return (
    <Wrapper
      title={`${isEdit ? 'Edição de Boleto' : 'Novo Boleto'}`}
      crumb={[
        {
          name: 'Dashboard',
          link: '/',
        },
        {
          name: 'CNAB',
          link: '/Processamento/Cnab',
        },
      ]}
    >
      <Box style={{ boxShadow: 'none', borderRadius: '20px', padding: 20 }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <FormControl variant="outlined" style={{ flex: 1 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo Operação
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={tipoOperacao}
              onChange={handleMudarOperacao}
              label="Tipo Operação"
              size="small"
            >
              <MenuItem
                selected={tipoOperacao == 'COBRANCA_SIMPLES' ? 'selected' : ''}
                value={'COBRANCA_SIMPLES'}
              >
                Cobrança Simples
              </MenuItem>
              <MenuItem
                selected={tipoOperacao == 'OPERACAO_GARANTIA' ? 'selected' : ''}
                value={'OPERACAO_GARANTIA'}
              >
                Operação Garantia
              </MenuItem>
            </Select>
          </FormControl>
          <div style={{ flex: 1 }}>
            <RadioButton
              optionDefault={
                isEdit ? optionTitle[1].value : optionTitle[0].value
              }
              titulo="Tipo de registro"
              options={optionTitle}
              callback={SetOption}
              styleContainerRadio="display: flex; justify-content: space-between; align-items: center;"
            />
          </div>
        </div>

        {isEdit && (
          <ContainerErrorDescription>
            <p>
              {
                JSON.parse(
                  localStorage.getItem('data_pagamento_boleto2') != null
                    ? localStorage.getItem('data_pagamento_boleto2')
                    : localStorage.getItem('data_pagamento_boleto'),
                ).descricaoErro
              }
            </p>
          </ContainerErrorDescription>
        )}
      </Box>
      <Box style={{ boxShadow: 'none', borderRadius: '20px', padding: 20 }}>
        <OptionSelected>
          {optionSelected.value === '04b27d14-1080-4789-bf59-e39d60197a30' ? (
            <CNBAOption />
          ) : (
            RenderizaOpcoesDigitacao()
          )}
        </OptionSelected>
      </Box>
    </Wrapper>
  );
};

export default CNAB;
