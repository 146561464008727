import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { api_multipague_esteira } from '../../src/services/api';
import moment from 'moment';
import { DashboardCard } from './DashboardCards';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';

const useStyles = makeStyles(theme => ({
  rootChart: {
    marginBottom: 20,
    '& svg': {
      '& text': {
        fontSize: 11,
        flexWrap: 'wrap',
        fill: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '& rect:nth-child(1)': {
        fill: theme.palette.background.paper,
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Aprovacao() {
  const theme = useTheme();
  const classes = useStyles();
  const chartContainerRef = useRef(null);
  const [error, setError] = React.useState(false);
  const [mensagemErro, setMensagemErro] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [carregando, setCarregando] = React.useState(false);
  const optionsChart = {
    width: 450,
    backgroundColor: 'transparent',
    titleTextStyle: {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    legend: {
      position: 'left',
      textStyle: {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
    title: 'Esteira',
    colors: [theme.palette.primary.main, '#394c59', '#2962ff', '#ffab00'],
  };

  const [options, setOptions] = useState(optionsChart);

  const [informacoes, setInformacoes] = useState({
    etapasConsolidada: [],
  });
  const [infoGraficos, setInfoGraficos] = useState([]);

  const dataInicial = moment(new Date().getFullYear() + '-01-01').format(
    'YYYY-MM-DD',
  );
  const dataFinal = moment(new Date().getFullYear() + '-12-31').format(
    'YYYY-MM-DD',
  );
  const URL = `/esteira/resumo?cliente=${localStorage.getItem(
    'id-cliente',
  )}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`;

  useEffect(() => {
    setOptions(optionsChart);
  }, [theme.palette.type]);

  useEffect(() => {
    setCarregando(true);
    api_multipague_esteira
      .get(URL)
      .then(response => {
        setCarregando(false);
        setError(false);
        setInformacoes(response.data);

        const situacoesConsolidadas = response.data.situacoesConsolidada;
        const situacoesExcluidas = [
          'AGUARDANDO_PROCESSAMENTO_ARQUIVO',
          'PARCIALMENTE_APROVADO',
          'ARQUIVO_SEM_REGISTRO_SUCESSO',
        ];
        const situacoes = [
          'APROVADO',
          'NEGADO',
          'EXPIRADO',
          'PENDENTE',
          'CANCELADO',
          'REJEITADO',
        ];

        const formattedInfo = situacoes.map(situacao => {
          const situacaoEncontrada = situacoesConsolidadas.find(
            item => item.situacao === situacao,
          );
          return [
            situacao,
            situacaoEncontrada ? situacaoEncontrada.quantidadeRegistros : 0,
          ];
        });

        const missingSituacoes = situacoes.filter(
          situacao => !formattedInfo.find(item => item[0] === situacao),
        );

        missingSituacoes.forEach(situacao => {
          formattedInfo.push([situacao, 0]);
        });

        formattedInfo.unshift(['Situação', 'Registros']);

        const filteredInfo = formattedInfo.filter(
          item => item[1] !== 0 || !situacoesExcluidas.includes(item[0]),
        );

        setInfoGraficos(filteredInfo);
      })
      .catch(err => {
        setCarregando(false);
        setError(true);
        setMensagemErro(
          err?.response?.data?.mensagem
            ? err?.response?.data?.mensagem
            : 'Não foi possível conectar-se ao servidor',
        );
      });
  }, []);

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    if (chartContainer) {
      chartContainer.style.backgroundColor = theme.palette.primary.main;
    }
  }, [theme.palette.primary.main]);

  return (
    <DashboardCard
      children={
        <>
          <TabPanel value={value} index={0}>
            {error ? (
              <div
                style={{
                  backgroundColor: 'transparent',
                  transform: 'translate(0%, 200%)',
                }}
              >
                <MessageErrorComponent mensagemErro={mensagemErro} />
              </div>
            ) : infoGraficos.length < 0 ? (
              <CircularProgress color="primary" style={{ margin: 'auto' }} />
            ) : (
              <div ref={chartContainerRef} className={classes.rootChart}>
                <Chart
                  className={classes.rootChart}
                  width={'100%'}
                  chartType="Bar"
                  data={infoGraficos}
                  allowHtml
                  isStacked
                  options={{
                    ...options,
                  }}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </>
      }
      icon={
        <AssignmentTurnedInIcon
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      title={'Aprovação'}
      carregando={carregando}
      isChart
    />
  );
}

export default Aprovacao;
