import { styled } from '@material-ui/core';

export const Container = styled('div')(({ theme, error }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    '& fieldset': {
      ...(error && {
        borderColor: theme.palette.system.dark,
      }),
    },
  };
});

export const MessageError = styled('p')(({ theme }) => {
  return {
    margin: '0',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    marginLeft: '14px',
    marginRight: '14px',
    color: theme.palette.system.dark,
  };
});
