import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import PubSub from 'pubsub-js';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import formattedMoney from '../util/FormatteMoney/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { api_multipague_conta } from '../../src/services/api';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { saldoRequest } from '../store/modules/saldo/actions';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    fontSize: '1rem',
    backdropFilter: 'blur(2px)',
    borderRadius: '15px',
    letterSpacing: '0.01071em',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    boxShadow: `${alpha(theme.palette.text.primary, 0.2)} 1px 5px 5px`,
  },
  verSaldo: {
    height: '44px',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  saldoError: {
    color: theme.palette.system.dark,
    fontSize: '11px',
  },
}));

// const {REACT_APP_SALDO_DASH} = process.env

export default function SimpleAlerts() {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const infoRedux = useSelector(state => state.contas);
  const dateToFormat = new Date();
  const { contaPadrao } = useSelector(state => state.contas);
  const saldo = useSelector(state => state.saldo);
  const [dataInformacoes, setDataInformacoes] = useState({
    idCliente: '',
  });
  const [error, setError] = useState({ isError: false, errorMessage: '' });

  useEffect(() => {
    var mySpecificSubscriber = function (msg, data) {
      const teste = data;
      setDataInformacoes(teste);
    };
    // subscribe only to 'car.drive' topics
    PubSub.subscribe('captureItem', mySpecificSubscriber);
    const infoStorage = localStorage.getItem('dataInformacoes');
    if (infoStorage) {
      setDataInformacoes(JSON.parse(infoStorage));
    }
  }, []);

  const [informacoes, setInformacoes] = useState({
    saldo: 0,
    idCliente: '',
  });

  useEffect(() => {
    if (!!contaPadrao?.id) {
      api_multipague_conta
        .get(`/saldo/cliente-conta/${contaPadrao?.id}`)
        .then(response => {
          setInformacoes(response.data);
          dispatch(saldoRequest(contaPadrao?.id, saldo.showSaldo));
        })
        .catch(err =>
          setError({
            isError: true,
            errorMessage: err.response.data.mensagem
              ? err.response.data.mensagem
              : 'Não foi possível conectar-se ao servidor',
          }),
        );
    }
  }, [contaPadrao, infoRedux]);

  return (
    <Grid spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Card elevation={3} className={classes.root}>
          <div className={classes.verSaldo}>
            <MdAccountBalanceWallet
              color={
                theme.palette.type === 'light'
                  ? theme.palette.primary.main
                  : theme.palette.text.primary
              }
              size={30}
            />
            {error.isError ? (
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  fontSize: '20px',
                  alignContent: 'center',
                  display: 'flex',
                  fontWeight: 'bold',
                  alignItems: 'center',
                }}
              >
                Saldo total:
                <span
                  style={{
                    color: theme.palette.text.primary,
                    fontSize: '14px',
                    display: 'flex',
                    fontWeight: 'light',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <AiOutlineExclamationCircle
                    size={30}
                    style={{ padding: '5px' }}
                    color={'red'}
                  />
                  {error.errorMessage}
                </span>
              </Typography>
            ) : (
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                Saldo total:{' '}
                <span style={{ color: theme.palette.text.primary }}>
                  {informacoes.saldo == 0
                    ? 'R$ 0,00'
                    : formattedMoney(parseFloat(informacoes.saldo))}
                </span>
              </Typography>
            )}
          </div>
          <Hidden xsDown>
            <Typography
              style={{
                fontSize: '12px',
                marginTop: '0px',
                fontWeight: 300,
                color: theme.palette.text.primary,
              }}
            >
              Dados atualizados:{' '}
              <span style={{ fontWeight: 900 }}>
                <Moment date={dateToFormat} format="DD/MM/YYYY" />
              </span>
            </Typography>
          </Hidden>
        </Card>
      </Grid>
    </Grid>
  );
}
