import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Button from '@material-ui/core/Button';
import CPF from '../../components/Inputs/CPF';
import CNPJ from '../../components/Inputs/CNPJ';
import InputText from '../../components/InputText';
import { editarFavoritosRequest } from '../../store/modules/beneficiarios/editar/actions';
import { listarFavoritosRequest } from '../../store/modules/beneficiarios/listar/actions';
import { pesquisarFavoritosRequest } from '../../store/modules/beneficiarios/pesquisar/actions';
import { useTheme } from '@material-ui/core/styles';
import { Form } from '@unform/web';

import validationCpfCnpj from '../../util/vlidarCpfCnpj';
import RadioButton from '../../components/RadioButton';

import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';

import { maskCpfCnpj } from '../../util/mask';
import { columnsTable } from './dataTable';
import { FaEye } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';

const ListagemFavorecidos = () => {
  const theme = useTheme();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const listagemFavoritos = useSelector(state => state.listFavorito);
  const resultBuscarFavorecidos = useSelector(state => state.pesquisarFavorito);
  const { userAccount } = useSelector(state => state.user);
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [recarregaFavorecidos, setRecarregaFavorecidos] = useState(true);
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const optionsTypePerson = [
    {
      label: 'Pessoa Física',
      value: 'PF',
      selected: true,
    },
    {
      label: 'Pessoa Jurídica',
      value: 'PJ',
      selected: false,
    },
  ];

  const [typePersonSelected, setTypePersonSelected] = useState(
    optionsTypePerson[0].selected
      ? optionsTypePerson[0].value
      : optionsTypePerson[1].value,
  );

  function formatResultFavorecidos() {
    try {
      const formattedData = resultBuscarFavorecidos.content.map(dados => ({
        id: dados.id,
        key: uuidv4(),
        documento: maskCpfCnpj(dados.cnpjCpf),
        nome: dados.nome,
        agencia: dados.agencia,
        conta: dados.conta + '-' + dados.digitoConta,
        chave_pix: dados.chavePix,
        banco: dados.nrBanco,
        situacao: dados.situacao,
        ativoStatus: dados.ativo ? 'Sim' : 'Não',
      }));
      setRowsTable(formattedData);
      setRowCount(resultBuscarFavorecidos.totalElements);
    } catch {
      setRowsTable([]);
      setRowCount(0);
    }
  }

  useEffect(() => {
    formatResultFavorecidos();
  }, [resultBuscarFavorecidos]);

  useEffect(() => {
    if (userAccount?.id && recarregaFavorecidos) {
      dispatch(
        listarFavoritosRequest({
          id: userAccount.id,
          ativo: '',
          situacao: '',
          idClienteConta: contaPadrao.id,
          page: rowsState.page,
          size: rowsState.pageSize,
        }),
      );
    } else if (userAccount?.id && !recarregaFavorecidos) {
      dispatch(
        pesquisarFavoritosRequest({
          cpf_cnpj: formRef?.current?.getData()?.cpf_cnpj.replace(/[^\d]/g, ''),
          idCliente: userAccount.id,
          idFavorecido: '',
          idClienteConta: contaPadrao.id,
          nome_completo: formRef?.current?.getData()?.nome_completo,
          page: rowsState.page,
          size: rowsState.pageSize,
        }),
      );
    }
  }, [userAccount, rowsState]);

  useEffect(() => {
    formatListaFavoritos();
  }, [listagemFavoritos]);

  function formatListaFavoritos() {
    try {
      const formattedData = listagemFavoritos.content.map(dados => ({
        id: dados.id,
        documento: maskCpfCnpj(dados.cnpjCpf),
        nome: dados.nome,
        key: uuidv4(),
        agencia: dados.agencia,
        conta: dados.conta + '-' + dados.digitoConta,
        chave_pix: dados.chavePix,
        banco: dados.nrBanco,
        situacao: dados.situacao,
        ativoStatus: dados.ativo ? 'Sim' : 'Não',
      }));

      setRowsTable(formattedData);
      setRowCount(listagemFavoritos.totalElements);
    } catch {
      setRowsTable([]);
      setRowCount(0);
    }
  }

  function editarFavorecido(data) {
    dispatch(editarFavoritosRequest(data));
    history.push(`/Editar/Favorecidos/${data.id}`);
  }

  function filtrarFavorecidos(infos) {
    setRecarregaFavorecidos(false);
    setRowsState(prev => ({ ...prev, page: 0 }));
    const { cpf_cnpj } = infos;
    const responseCpfValidation = validationCpfCnpj(cpf_cnpj);

    if (!!cpf_cnpj && !responseCpfValidation) {
      formRef.current.setErrors({
        cpf_cnpj: 'CPF/CNPJ inválidos',
      });
      return;
    }
    dispatch(
      pesquisarFavoritosRequest({
        ...infos,
        cpf_cnpj: infos.cpf_cnpj.replace(/[^\d]/g, ''),
        idCliente: userAccount.id,
        idFavorecido: '',
        idClienteConta: contaPadrao.id,
        page: rowsState.page,
      }),
    );
  }

  function clearFilter() {
    setTypePersonSelected('PF');
    formatListaFavoritos();
    setRowsState(prev => ({ ...prev, page: 0 }));
    formRef.current.reset();
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <RadioButton
            titulo=""
            options={optionsTypePerson}
            optionDefault={typePersonSelected}
            callback={setTypePersonSelected}
          />
        ),
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      },
      {
        html: () => (
          <InputText
            variant="outlined"
            label={
              typePersonSelected === 'PF' ? 'Nome Completo' : 'Razão Social'
            }
            InputProps={{
              style: {
                borderRadius: '20px',
              },
            }}
            name="nome_completo"
            id="nome_completo"
          />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
      {
        html: () =>
          typePersonSelected === 'PF' ? (
            <CPF
              name="cpf_cnpj"
              id="cpf_cnpj_id"
              variant="outlined"
              label="CPF"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          ) : (
            <CNPJ
              name="cpf_cnpj"
              id="cpf_cnpj_id"
              variant="outlined"
              label="CNPJ"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
    ],
    searchButton: {
      searchButton: () => filtrarFavorecidos(),
    },
    cleanButton: {
      cleanButton: () => clearFilter(),
    },
  };

  return (
    <Form ref={formRef} onSubmit={filtrarFavorecidos}>
      <DataGridPage
        title="Favorecidos"
        crumb={[{ name: 'Dashboard', link: '/' }, { name: 'Transferência' }]}
        newRegister={() => history.push('/Cadastro/Favorecidos')}
        formData={filterInputs}
        rows={rowsTable}
        columns={[
          ...columnsTable,
          {
            field: 'detalhes',
            headerName: 'Detalhes',
            sortable: false,
            width: 90,
            disableClickEventBubbling: true,
            renderCell: event => {
              return (
                <IconButton
                  variant="outlined"
                  onClick={() => editarFavorecido(event.row)}
                  color="primary"
                >
                  <FaEye size={20} color={theme.palette.primary.main} />
                </IconButton>
              );
            },
          },
        ]}
        key={rowsTable.key}
        rowCount={rowCount}
        {...rowsState}
        setDatagrid={setRowsState}
        minHeight={'600px'}
      />
    </Form>
  );
};

export default ListagemFavorecidos;
