import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api_multipague_conta } from '../../services/api';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FinancialAgenda from '../../components/AgendaFinanceiraConta';
import SimpleCharges from '../../components/CobrancaSimplesConta';
import Expenses from '../../components/DespesasConta';
import Running from '../../components/EsteiraConta';
import GoalsExpenses from '../../components/MetasDespesas';
import Payment from '../../components/Pagamento';
import Pix from '../../components/Pix';
import SaldoAtual from '../../components/SaldoAtual';
import TopBar from '../../components/TopBar';
import TotalPayroll from '../../components/TotalFolhaPag';
import Transfer from '../../components/Transferencia';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  rootcontent: {
    '& > *': {
      margin: theme.spacing(2),
      // width: '30%'
    },
  },
  rootcard: {
    minWidth: 275,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 100,
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
}));

import Wrapper from '../../components/Wrapper';

export default function MinhasContas() {
  const classes = useStyles();
  const clienteAccount = useSelector(state => state.contas);
  const bull = <span className={classes.bullet}>•</span>;
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    if (clienteAccount.contaPadrao?.agencia) {
    }
  }, [clienteAccount]);

  useEffect(() => {
    api_multipague_conta.get(
      `/saldo/conta?nrBanco=${274}&agencia=${
        clienteAccount.contaPadrao.agencia
      }&conta=${clienteAccount.contaPadrao.conta}&digitoConta=${
        clienteAccount.contaPadrao.digitoConta
      }`
    )
    .then(response => {
      console.log("API de identificar token expirado")
    })
  },[clienteAccount])

  return (
    <Wrapper
      title="Minhas Contas"
      crumb={[
        {name:"Dashboard",link:"/"},
        {name:"Minhas Contas"},
      ]}
      crumbActive="Geral"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <SaldoAtual />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Transfer />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Payment />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <TotalPayroll />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <FinancialAgenda />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <SimpleCharges />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Pix />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Expenses />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Running />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <GoalsExpenses />
        </Grid>
      </Grid>

    </Wrapper>
  );
}
