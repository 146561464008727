import React, { useCallback, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { VscLoading } from 'react-icons/vsc';
import { AiFillEdit } from 'react-icons/ai';
import { RiFileExcel2Fill, RiCloseCircleLine } from 'react-icons/ri';
import { AiTwotonePrinter } from 'react-icons/ai';
import { SiGooglecalendar } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Switch from '@material-ui/core/Switch';
import ContainerComponent from '../../components/Container';
import Button from '../../components/Buttons/ConfirmButton';
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputText from '../../components/InputText';
import DateComponent from '../../components/InputDate';
import { maskCpfCnpj } from '../../util/mask';
import jwt_decode from 'jwt-decode';
import {
  api_multipague_cobranca,
  api_multipague_cobranca_cnab,
  api_multipague_configuracao,
  baseUrlCobranca,
} from '../../services/api';
import formatMoney from '../../util/FormatteMoney';
import TextField from '@material-ui/core/TextField';
import DifferenceBetweenDate from '../../util/CalcularDiferencaDatas';
import CPF from '../../components/Inputs/CPF';
import CNPJ from '../../components/Inputs/CNPJ';
import RadioButton from '../../components/RadioButton';
import { ButtonTooltip } from '../../components/Buttons/ButtonWithTooltip';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattFloatNumber } from '../../util/FormatToFloatNumber';
import {FaRegFileExcel,FaRegFilePdf} from "react-icons/fa";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import {Button as ButtonMD}  from '@material-ui/core';
import {
  FormPesquisa,
  FormRow,
  FormCol,
  CheckFilter,
  ButtonTable,
  ContainerActionsTable,
  LoadingIcon,
} from './styles';
import {
  hideLoading,
  hideLoadingButton,
  showLoading,
  showLoadingButton,
} from '../../store/modules/loading/actions';

import Box from "../../components/Box";
import Wrapper from "../../components/Wrapper";
import DataGrid from "../../components/DataGrid";
import {MdSearch,MdClear,MdPrint} from "react-icons/md"

import * as ImpressaoBoletoService from "../../services/ImpressaoBoletosService"

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAuthContext } from '../../Context/AuthContext';


const NewHomeCnab = () => {
  const {permissionBackEmpresa} = useAuthContext();
  const root = {
    path: [
      {
        name: 'Minhas Contas',
        caminho: '/myaccounts',
      },
      {
        name: 'Cob. Simples',
        caminho: '/Buscar/Boleto',
      },
    ],
    pagAtual: 'CNAB',
  };
  const theme = useTheme();
  const matchesPoint = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();
  const history = useHistory();
  const { loadingButton } = useSelector(state => state.loading);
  const { userAccount } = useSelector(state => state.user);
  const { contaPadrao } = useSelector(state => state.contas);
  const formRef = useRef(null);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [vencidosFilter, setVencidosFilter] = useState(false);
  const [emDiaFilter, setEmDiaFilter] = useState(false);
  const [aVencerFilter, setAVencerFilter] = useState(false);
  const [filterRegistrados, setFilterRegistrados] = useState(false);
  const [rowsTableTudo, setRowsTableTudo] = useState([]);
  const [rowsTable, setRowsTable] = useState([]);
  const [rowsTableExcel, setRowsTableExcel] = useState([]);
  const accessToken = localStorage.getItem('tkn-access');
  const [novaPermissao, setNovaPermissao] = useState(false);
  const { realm_access, permissaoContas } = jwt_decode(accessToken);
  const [disabledActions, setDisabledActions] = useState(false);
  const [page, setPage] = React.useState(0);
  const [podeExcel, setPodeExcel] = useState(false);
  const [carregandoExcel, setCarregandoExcel] = useState(false);
  const [filtrouExcel, setFiltrouExcel] = useState(false);
  const [rowCount, setRowCount] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [filtrouPagina, setFiltrouPagina] = useState(0)
  const [infoCnab, setInfoCnab] = useState()
  const [situacao, setSituacao] = useState('')
  const [situacaoPadrao, setSituacaoPadrao] = useState('Selecione a situação')
  const [selecionarSituacao, setSelecionarSituacao] = useState([
    {
      id: "",
      descricao: "  "
    },
    {
      id: "PENDENTE_REGISTRO",
      descricao: "Pendente Registro"
    },
    {
      id: "REGISTRADO",
      descricao: "Registrado"
    },
    {
      id: "ERRO_REGISTRO",
      descricao: "Erro Registro"
    },
    {
      id: "PAGO",
      descricao: "Pago"
    },
    {
      id: "BAIXADO_DATA_LIMITE_PAGTO",
      descricao: "Baixado Data Limite Pagamento"
    },
    {
      id: "PENDENTE_BAIXA_CEDENTE",
      descricao: "Pendente Baixa Cedente"
    },
    {
      id: "BAIXADO_CEDENTE",
      descricao: "Baixado Cedente"
    },
    {
      id: "ERRO_BAIXA_CEDENTE",
      descricao: "Erro Baixa Cedente"
    },
    {
      id: "ALTERACA_PENDENTE",
      descricao: "Alteração Pendente"
    },
    {
      id: "ERRO_ALTERACAO",
      descricao: "Erro Alteração"
    },
  ])
  const [optionsTypePerson, setOptionsTypePerson] = useState([
    {
      label: 'Pessoa Física',
      value: 'PF',
      selected: true,
    },
    {
      label: 'Pessoa Jurídica',
      value: 'PJ',
      selected: false,
    },
  ]);
  const [typePersonSelected, setTypePersonSelected] = useState(
    optionsTypePerson[0].selected
      ? optionsTypePerson[0].value
      : optionsTypePerson[1].value,
  );


  useEffect(() => {
    localStorage.removeItem('data_pagamento_boleto');
  },[])

  return (
    <Wrapper
        backButton
        title="New Cnab"
        crumb={[
          {
            name: 'Minhas Contas',
            link: '/myaccounts',
          },
          {
            name: 'Cob. Simples',
            link: '/Buscar/Boleto',
          },
        ]}
        newRegister={()=>history.push('/Cobranca/Simples')}
      >


      <Box>
        {/* Box de filtro */}
        <Grid container spacing={3}>
            <Grid item xs={12} md={5} lg={3} sm={12}>
            <TextField
                fullWidth
                size="small"
                id="cpf"
                label="CPF"
                value=""
                onChange={(e) => setcpfCnpj(e.target.value)} variant="outlined"
             />
            </Grid>
            <Grid item xs={12} md={5} lg={3} sm={12}>
                <DateComponent
                    callback={setDataInicial}
                    label="Data Inicial"
                    id="data-inicial-filtro"
                    value={dataInicial}
                    size="small"
                    variant="outlined"
                    onChange={(e) => setDataInicial(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={5} lg={3} sm={12}>
                <DateComponent
                    callback={setDataFinal}
                    label="Data Final"
                    id="data-final-filtro"
                    value={dataFinal}
                    size="small"
                    variant="outlined"
                    onChange={(e) => setDataFinal(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={5} lg={3} sm={12}>
                <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={selecionarSituacao}
                    getOptionLabel={(option) => option.descricao}
                    onChange={(event, option) => {
                        setSituacao(option.id);
                    }}
                    style={{ width: '100%' }}

                    renderInput={(params) => <TextField {...params} label="Status"
                            placeholder={situacaoPadrao}
                            InputLabelProps={{ shrink: true, }}  variant="outlined" />
                    }
                />
            </Grid>
            <Grid item xs={12} md={3} lg={3} sm={12} style={{display:'flex', justifyContent:'flex-end'}}>
            <Button
                color="default"
                variant="contained"
                type="button"
                // onClick={reset}
                style={{marginRight:'10px'}}
                startIcon={<MdClear />}
            >
                Limpar
            </Button>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<MdSearch />}
            >
                Buscar
            </Button>
            </Grid>
        </Grid>
        {/* Fim Box de filtro */}


          </Box>
          {/* <DataGrid
            headerComponent={()=>{
              return(
                <>
              <Button
                color="primary"
                variant="outlined"
                startIcon={<MdPrint />}
                onClick={imprimirBoletosEmLote}
              > Boletos em Lote
              </Button>
              {!podeExcel ? (
                <Button
                color="primary"
                variant="outlined"
                startIcon={<FaRegFileExcel />}
                onClick={solicitarExcel}
              > {!carregandoExcel ? ("Solicitar Excel") : ("...carregando")}
              </Button>
              ) : (
                <ExcelFile element={
                  <ButtonMD
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<FaRegFileExcel />}
                    style={{textTransform:"capitalize"}}

                  >
                    Exportar Excel
                  </ButtonMD>
                }>
                  {filtrouExcel ? (
                    <ExcelSheet data={rowsTableExcel} name="MeuExtratoCnab">
                      <ExcelColumn label="Sacado" value="nomeRazaoPagadorExcel"/>
                      <ExcelColumn label="CPF/CNPJ" value="documentoFederalExcel"/>
                      <ExcelColumn label="Valor" value="valorExcel"/>
                      <ExcelColumn label="Vencimento" value="dataVencimentoFormattedExcel"/>
                      <ExcelColumn label="Dif Dias" value="diferencaDiasFormattedExcel"/>
                      <ExcelColumn label="Situação" value="situacaoTableExcel"/>
                  </ExcelSheet>
                  ) : (
                    <ExcelSheet data={rowsTableExcel} name="MeuExtratoCnab">
                      <ExcelColumn label="Sacado" value="nomeRazaoPagadorExcel"/>
                      <ExcelColumn label="CPF/CNPJ" value="documentoFederalExcel"/>
                      <ExcelColumn label="Valor" value="valorExcel"/>
                      <ExcelColumn label="Vencimento" value="dataVencimentoFormattedExcel"/>
                      <ExcelColumn label="Dif Dias" value="diferencaDiasFormattedExcel"/>
                      <ExcelColumn label="Situação" value="situacaoTableExcel"/>
                  </ExcelSheet>
                    // <ExcelSheet data={rowsTable} name="MeuExtratoCnab">
                    //     <ExcelColumn label="Sacado" value="nomeRazaoPagador"/>
                    //     <ExcelColumn label="CPF/CNPJ" value="documentoFederal"/>
                    //     <ExcelColumn label="Valor" value="valor"/>
                    //     <ExcelColumn label="Vencimento" value="dataVencimentoFormatted"/>
                    //     <ExcelColumn label="Dif Dias" value="diferencaDiasFormatted"/>
                    //     <ExcelColumn label="Situação" value="status"/>
                    // </ExcelSheet>
                  )}
                </ExcelFile>
              )}
              </>
              )
            }}
            rows={filtrouExcel ? rowsTableExcel : rowsTable}
            columns={filtrouExcel ? columnsTableExcel : columnsTable}
            checkboxSelection
            pageSize={10}
            rowsPerPageOptions={[10]}
            isRowSelectable = {(params) => params.row.registrado}
            height="550px"
            onSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
            selectionModel={selectionModel}
            rowCount={rowCount}
            pagination
            {...rowsState}
            paginationMode="server"
            onPageChange={onPage}

          /> */}
    </Wrapper>
  );
};

export default NewHomeCnab;
