import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    borderRadius: '15px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
  },
  rootList: {
    width: '100%',
    maxWidth: 570,
    backgroundColor: theme.palette.background.paper,
  },
  alerts: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  button: {
    marginBottom: theme.spacing(2),
    width: '80%',
    margin: '0 10%',
    fontWeigth: '500',
    fontSize: '14px',
    textTransform: 'none',
    color: theme.palette.getContrastText(theme.palette.text.primary),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    padding: '0.6rem',
  },
  actions: {
    marginTop: '0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.appBar[theme.palette.type].bgMenu}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function RecipeReviewCard() {
  const theme = useTheme();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card className={classes.root} elevation={3}>
      <CardHeader
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ImportExportIcon
              size={50}
              style={{
                marginRight: 5,
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="body"
              style={{
                color: theme.palette.primary.main,
                fontSize: '1.2rem',
              }}
              component="h2"
            >
              Transferências
            </Typography>
          </div>
        }
      />

      <CardContent>
        <Link to="/Cadastro/Favorecidos">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<PersonAddIcon />}
          >
            Adicionar Favorecido
          </Button>
        </Link>
        <Link to="/Transferencia">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<ExitToAppIcon />}
          >
            Nova Transferência
          </Button>
        </Link>
      </CardContent>
      <CardActions className={classes.actions}></CardActions>
    </Card>
  );
}
