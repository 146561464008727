import React,{useRef,useEffect,useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from '@unform/core'
import {Container,MessageError} from "./style"
/**
 *
 * EX: <AutoCompleteUnform name="idFavorecido" defaultValue={403} label="Favorecido" options={favoritos}  id="idFavorecido" />
 *
 */

const AutoCompleteUnform = ({name,label,options, renderOption, defaultValue,disabled}) => {
    const { fieldName, registerField,error,clearError } = useField(name)

    const [value,setValue] = useState(defaultValue)

    const onChange = (_event,item)=>{
      setValue(item.value)
    }
    useEffect(()=>{
      console.log("valor",value)
    },[value])

    useEffect(() => {
      registerField({
        name: fieldName,
        getValue: () => {
          return value
        }
      });
    }, [value,defaultValue,options]);


    const getDefaultValue = () => {
      const item = options.find((opt)=>{
        if (opt.value == value)
          return opt;
      })
      return item || {};
    }

    return (
      <Container error={error}>
        <Autocomplete
          onFocus={clearError}
          onChange={onChange}
          options={options}
          size="small"
          disablePortal
          renderOption={renderOption}
          id="combo-box-demo"
          disabled={disabled}
          getOptionLabel={(option) => option.label}
          value={getDefaultValue()}
          renderInput={(params) => <TextField  {...params} label={label} variant="outlined" />}
        />
        <MessageError>{error}</MessageError>
      </Container>
    );
}

export default AutoCompleteUnform
