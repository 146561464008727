/* eslint react/prop-types: 0 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';

import { Container, ButtonComponent } from './styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { useStylesDatagrid } from '../../DataGrid/DataGridPaginationServer/styles';

const ConfirmButton = ({
  variant,
  color,
  disabled,
  children,
  title,
  width,
  isLoading,
  startIcon,
  ...rest
}) => {
  const classes = useStylesDatagrid();

  return (
    <Container>
      <Tooltip title={title}>
        <IconButton
          disabled={disabled}
          isLoading={isLoading}
          variant="contained"
          color="primary"
          className={classes.loadingButton}
          {...rest}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={24} circleDisableShrink />
          ) : (
            startIcon
          )}
        </IconButton>
      </Tooltip>
    </Container>
  );
};

export default ConfirmButton;
