import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { api_multipague_agenda_financeira } from '../../src/services/api';
import formattedMoney from '../util/FormatteMoney/index';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import DateRange from '@material-ui/icons/DateRange';
import { DashboardCard } from './DashboardCards';
import { MessageErrorComponent } from './DashboardCards/ErrorMessage';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  rootList: {
    width: '100%',
    maxWidth: 570,
    backgroundColor: theme.palette.background.paper,
  },
  actions: {
    marginTop: '0',
  },
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.appBar[theme.palette.type].bgMenu}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AgendaFinanceira() {
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [mensagemErro, setMensagemErro] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [carregando, setCarregando] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();

  const infoRedux = useSelector(state => state.contas);

  const handleClose = () => {
    setOpen(false);
  };
  const [informacoes, setInformacoes] = useState({
    valorTotalEntrada: '0,00',
    valorTotalSaida: '0,00',
    agendasClienteContas: [],
  });

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Agenda Financeira</h2>
      <p id="simple-modal-description">
        {informacoes.agendasClienteContas.map(item => {
          return (
            <div>
              <List className={classes.rootList}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AssignmentIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Código da Conta"
                    secondary={item.codigoClienteConta}
                  />
                  <ListItemText
                    primary="Valor de entrada"
                    secondary={item.valorTotalEntrada}
                  />
                  <ListItemText
                    primary="Valor de saída"
                    secondary={item.valorTotalSaida}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            </div>
          );
        })}
      </p>
    </div>
  );

  useEffect(() => {
    setCarregando(true);
    const hoje = new Date();
    const final = new Date().setDate(hoje.getDate() + 365);
    api_multipague_agenda_financeira
      .get(
        `/agenda-financeira?dataInicial=${moment(new Date(hoje)).format(
          'YYYY-MM-DD',
        )}&dataFinal=${moment(new Date(final)).format(
          'YYYY-MM-DD',
        )}&contextoConsolidado=true`,
      )
      .then(response => {
        setError(false);
        setCarregando(false);
        setInformacoes(response.data);
      })
      .catch(err => {
        setCarregando(false);
        if (
          err.response.status == 400 ||
          err.response.status == 404 ||
          err.response.status == 500
        ) {
          setError(true);
          setMensagemErro('Carregando informações');
        }
      });
  }, [infoRedux, dispatch]);

  return (
    <DashboardCard
      icon={
        <DateRange
          size={50}
          style={{
            marginRight: 5,
            color: theme.palette.primary.main,
          }}
        />
      }
      carregando={carregando}
      title={'Agenda Financeira'}
      children={
        <>
          <>
            {error ? (
              <MessageErrorComponent mensagemErro={mensagemErro} />
            ) : (
              <>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.text.primary,
                      fontWeight: 'bold',
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    <AiOutlinePlusCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color={'green'}
                    />
                    Valor de entrada:
                  </Typography>
                  {informacoes.valorTotalEntrada == 0
                    ? 'R$ 0,00'
                    : formattedMoney(informacoes.valorTotalEntrada)}
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      alignItems: 'center',
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    <AiOutlineMinusCircle
                      size={25}
                      style={{ paddingRight: '5px' }}
                      color="red"
                    />
                    Valor de saída:
                  </Typography>
                  {informacoes.valorTotalSaida == 0
                    ? 'R$ 0,00'
                    : formattedMoney(informacoes.valorTotalSaida)}
                </Box>
              </>
            )}
          </>
          <CardActions className={classes.actions}>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
          </CardActions>
        </>
      }
    />
  );
}
