import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { api_multipague_transacao } from '../../../services/api';
import { hideLoading, showLoading } from '../loading/actions';
import { extratoTedSuccess, extratoTedError } from './actions';

import { toastComponentError } from '../../../components/Toast';

function* comprovanteExtrato({ payload }) {
  try {
    yield put(showLoading(true));

    const { data } = yield call(
      api_multipague_transacao.get,
      `/transferencia/comprovante/${payload}`,
    );

    yield put(hideLoading(false));

    if (typeof data != 'undefined') {
      yield put(extratoTedSuccess(data));
      localStorage.setItem('extratoTransferencia', JSON.stringify(data));
    } else {
      yield put(extratoTedError());
    }
  } catch (err) {
    console.log('----------->', err);
    yield put(hideLoading(true));
    toastComponentError(
      ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
    );
    yield put(extratoTedError());
  }
}

export default all([takeLatest('EXTRATO_TED_REQUEST', comprovanteExtrato)]);
