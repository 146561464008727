/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../components/Buttons/ConfirmButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '../../components/Box';
import { useDispatch } from 'react-redux';
import { showLoading } from '../../store/modules/loading/actions';

import { api_multipague_configuracao } from '../../services/api';
import { api_multipague_configuracaoPaged } from '../../services/api';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import { IconButton, Popover } from '@material-ui/core';
import { FaEllipsisV } from 'react-icons/fa';
import { MdArrowBack, MdRemove } from 'react-icons/md';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    cursor: 'pointer',
  },
  field: {
    width: '100%',
  },
  grid: {
    marginBottom: '10px',
  },
  modal: {
    justifyContent: 'center',
    marginTop: '100px',
  },

  bodyDelete: {
    padding: theme.spacing(4),
  },
  buttonsDelete: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonsSearch: {
    display: 'flex',
    gap: '1rem',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
}));

export default function DataGridDemo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [textNoRows, setTextNoRows] = React.useState('');
  const [rowId, setRowId] = useState(null);
  const [idCliente, setIdCliente] = useState(
    localStorage.getItem('id-cliente'),
  );
  const [rows, setRows] = React.useState([]);

  const initialParams = {
    code: '',
    description: '',
  };
  const [params, setParams] = React.useState(initialParams);
  const [openMenuActions, setOpenMenuActions] = useState(false);

  const [dataInfo, setDataInfo] = React.useState('');
  const [loadingTable, setLoadingTable] = React.useState(false);
  const [rowCount, setRowCount] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  //Modal
  function SimpleDialog(props) {
    const classes = useStyles();
    const { open } = props;

    function handleDelete() {
      setLoadingTable(true);
      setOpen(false);
      api_multipague_configuracao
        .delete(`/centroCusto/associa/${dataInfo}`)
        .then(response => {
          setLoadingTable(false);
          console.log(response);
          toastComponentSuccess('Centro de Custo excluído com sucesso!');
          search(rowsState.page, params);
        })
        .catch(err => {
          if (
            err.response.status == 500 ||
            err.response.status == 400 ||
            err.response.status == 404
          ) {
            toastComponentError(err.response.data.mensagem);
            setLoadingTable(false);
            console.log(err);
          }
        });
    }

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle
          id="simple-dialog-title"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          Excluir Centro de Custo
        </DialogTitle>
        <Typography variant="body1" className={classes.bodyDelete}>
          Tem certeza que deseja excluir este centro de custo?
        </Typography>
        <div
          style={{
            paddingBottom: 10,
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            title={'Cancelar'}
            variant="contained"
            color="primary"
            startIcon={<MdArrowBack color="background" />}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            title={'Excluir'}
            startIcon={<MdRemove color="background" />}
            onClick={handleDelete}
          >
            Excluir
          </Button>
        </div>
      </Dialog>
    );
  }

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };

  const handleClose = value => {
    setOpen(false);
  };
  const history = useHistory();
  function openDetails(info) {
    localStorage.setItem('description', info.row.descricao);
    history.push(`/centro-de-custo/editar/${info.id}`);
  }

  const columns = [
    { field: 'codigo', headerName: 'Código', flex: 1 },
    { field: 'descricao', headerName: 'Descrição', flex: 4 },
    {
      field: 'acoes',
      headerName: 'Ações',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: event => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const classes = useStyles();

        const handleClick = event => {
          setAnchorEl(event.currentTarget);
          setOpenMenuActions(true);
        };

        const handleClose = () => {
          setAnchorEl(null);
          setOpenMenuActions(false);
        };

        const open =
          Boolean(anchorEl) && openMenuActions && event.row.id === rowId;
        const id = open ? 'simple-popover' : undefined;
        return (
          <>
            <IconButton onClick={handleClick} sx={{ mt: 2 }}>
              <FaEllipsisV aria-describedby={id} variant="contained" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: '10px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              <>
                <EditIcon
                  variant="contained"
                  color="primary"
                  onClick={() => openDetails(event)}
                  className={classes.button}
                />
                <DeleteIcon
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setOpen(true);
                    setDataInfo(event.id);
                  }}
                  className={classes.button}
                />
              </>
            </Popover>
          </>
        );
      },
    },
  ];

  const search = (page = 0, filter = initialParams) => {
    const rowsformat = [];
    setRows([]);
    setLoadingTable(true);
    api_multipague_configuracaoPaged
      .get(
        `/centroCusto?idCusto=${filter.code}&page=${page}&size=${rowsState.pageSize}&descricao=${filter.description}&idCliente=${idCliente}`,
      )
      .then(response => {
        setLoadingTable(false);
        dispatch(showLoading(false));
        response.data.content.map(custo => {
          rowsformat.push({
            id: custo.id,
            codigo: custo.id,
            descricao: custo.descricao,
          });
        });
        setRowsState(prev => ({ ...prev, page }));
        setRows(rowsformat);
        setRowCount(response.data.totalElements);
      })
      .catch(error => {
        dispatch(showLoading(false));
        setTextNoRows(error.response.data.mensagem);
        if (error.response.status != 404) {
          toastComponentError(error.response.data.mensagem);
        }
        setLoadingTable(false);
        setRows([]);
        setRowCount(0);
      });
  };

  useEffect(() => {
    dispatch(showLoading(true));
    search(0, params);
  }, [rowsState.pageSize]);

  const handleField = e => {
    setParams(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            className={classes.field}
            id="outlined-basic"
            label="Código"
            variant="outlined"
            size="small"
            name="code"
            value={params.code}
            onChange={handleField}
          />
        ),

        grid: { xs: 12, sm: 5, md: 5 },
      },
      {
        html: () => (
          <TextField
            className={classes.field}
            id="outlined-basic"
            label="Descrição"
            variant="outlined"
            size="small"
            name="description"
            value={params.description}
            onChange={handleField}
          />
        ),
        grid: { xs: 12, sm: 5, md: 5 },
      },
    ],
    searchButton: {
      searchButton: () => search(0, params),
    },
    cleanButton: {
      cleanButton: () => {
        setParams(initialParams);
        search();
      },
    },
  };

  return (
    <>
      <SimpleDialog open={open} />
      <DataGridPage
        formData={filterInputs}
        title="Centro de Custo"
        newRegister={() => history.push('/centro-de-custo/cadastro')}
        crumb={[{ name: 'Dashboard', link: '/' }]}
        rows={rows}
        columns={columns}
        minHeight="600px"
        loading={loadingTable}
        rowCount={rowCount}
        {...rowsState}
        rowsPerPageOptions={[10, 20, 50]}
        setDatagrid={setRowsState}
        textNoRows={textNoRows}
        onRowClick={params => setRowId(params.id)}
        onPageSizeChange={newPageSize =>
          setRowsState({ page: 0, pageSize: newPageSize })
        }
      />
    </>
  );
}
