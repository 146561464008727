import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Wrapper from '../../components/Wrapper';
import DataGrid from '../../components/DataGrid';
import { columnsTable } from './dataTable';

import { api_multipague_arquivo } from '../../services/api';
import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';

const ImpressaoBoletosEmLoteComErro = () => {
  const history = useHistory();
  const [rowsTable, setRowsTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const idRow = history?.location?.state;

  const fetchImpressaoBoletosComErro = async () => {
    setLoadingTable(true);
    try {
      const { data } = await api_multipague_arquivo.get(
        `/boleto-cobranca-impresso/${idRow.id}/erros`,
      );
      const formatData = data.map(item => ({
        ...item,
        dataVencimento: item.cobrancaBoleto.dataVencimento,
        id: item.cobrancaBoleto.id,
        nomePagador: item.cobrancaBoleto.nomePagador,
        nossoNumero: item.cobrancaBoleto.nossoNumero,
        numeroTitulo: item.cobrancaBoleto.numeroTitulo,
        valorTitulo: item.cobrancaBoleto.valorTitulo,
        descricaoErro: item.descricaoErro,
      }));
      setLoadingTable(false);
      setRowsTable(formatData);
    } catch {
      setRowsTable([]);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchImpressaoBoletosComErro();
  }, []);

  return (
    <DataGridPage
      title="Boletos com Erro"
      crumb={[
        { name: 'Minhas Contas', link: '/myaccounts' },
        {
          name: 'Impressão de Boleto em Lote',
          link: '/boleto/impressao-em-lote',
        },
      ]}
      rows={rowsTable}
      columns={columnsTable}
      minHeight={"600px"}
      pageSize={pageSize}
      loading={loadingTable}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
    />
  );
};

export default ImpressaoBoletosEmLoteComErro;
