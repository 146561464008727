import React, { useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../services/api';

import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import BoxContainer from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { useAuthContext } from '../../Context/AuthContext';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
  button: {
    alignItems: 'center',
    marginLeft: '10px',
    width: '100px',
  },
  inputBloq: {
    display: 'none',
  },
  inputFree: {
    display: 'block',
  },
}));

export default function MeuPerfil() {
  const dispatch = useDispatch();
  const { permissionAdmin } = useAuthContext();
  const { contaPadrao } = useSelector(state => state.contas);
  const [checked, setChecked] = useState(false);
  const [novoCorrentista, setNovoCorrentista] = useState({
    email: '',
    nome: '',
    cpf: '',
    rg: '',
  });

  const [showNovoCorrentista, setShowNovoCorrentista] = useState(false);
  const [listaCorrentistas, setListaCorrentistas] = useState([]);

  useEffect(() => {
    if (listaCorrentistas.length > 1) {
      setShowNovoCorrentista(true);
    } else {
      setShowNovoCorrentista(false);
    }
  }, [listaCorrentistas]);

  const [state, setState] = React.useState({
    checkedA: false,
  });

  const classes = useStyles();

  const [cnpjCpf, setCnpjCpf] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [select, setSelect] = React.useState('');
  const [paginaWeb, setPaginaWeb] = useState('');
  const [pessoaContatos, setPessoaContatos] = useState('');
  const [pessoaEndereco, setPessoaEndereco] = useState('');
  const [tipoEndereco, setTipoEndereco] = useState('');
  const [tipoResidencia, setTipoResidencia] = useState('');
  const [documentoEstadual, setDocumentoEstadual] = useState('');
  const [documentoMunicipal, setDocumentoMunicipal] = useState('');
  const [dataCadastro, setDataCadastro] = useState('');
  const [administrador, setAdministrador] = useState('');
  const [fundos, setFundos] = useState([]);
  const [clienteCorrentistaCpf, setClienteCorrentistaCpf] = useState('');
  const [clienteCorrentistaRg, setClienteCorrentistaRg] = useState('');
  const [clienteCorrentistaNome, setClienteCorrentistaNome] = useState('');
  const [listaDeContas, setListaDeContas] = useState('');
  const [motivoBloqueio, setMotivoBloqueio] = useState('');
  const [senhaFinanceira, setSenhaFinanceira] = useState('');
  const valorIdDetalhes = localStorage.getItem('valorIdDetalhes');
  const isAccountEscrow = JSON.parse(localStorage.getItem('accounts_default'));
  const [listaTarifas, setListaTarifas] = useState(false);
  const [mostrarTarifas, setMostrarTarifas] = useState();
  const [isTarifado, setIsTarifado] = useState(false);
  const [idConta, setIdConta] = useState(localStorage.getItem('idConta'));

  useEffect(() => {
    const URL = `/eventoClienteConta/lista?idClienteConta=${idConta}&tarifado=${true}`;
    api_multipague_configuracao
      .get(URL)
      .then(function (response) {
        setIsTarifado(true);
        const formatTarifado = response.data.map(usr => {
          return (
            <div
              style={{ display: 'flex', width: '100%', padding: '15px' }}
              key={usr.id}
            >
              <div style={{ width: '100%' }}>
                <p>
                  <strong>Evento: </strong>
                  {usr.evento.descricao}{' '}
                </p>
              </div>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <p>
                  <strong>Valor Tarifa: </strong>
                  {usr.valorTarifa.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}{' '}
                </p>
              </div>
              <Divider />
            </div>
          );
        });
        setListaTarifas(formatTarifado);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idConta]);

  const AuthStr = 'Bearer '.concat(localStorage.getItem('tkn-access'));
  const URL = `/conta/cliente/${localStorage.getItem('id-cliente')}`;

  useEffect(() => {
    dispatch(showLoading(true));
    api_multipague_conta
      .get(URL, {
        headers: {
          Authorization: AuthStr,
        },
      })
      .then(response => {
        setChecked(response.data.clienteContas[0].assinaturaSolidaria);
        setListaCorrentistas(response.data.clienteCorrentistas);
        const formatedCnpj = response.data.cnpjCpf;
        const fieldDataCadastro = response.data.dataCadastro;
        const fieldNomeFantasia = response.data.pessoa.nomeFantasia;
        const fieldRazaoSocial = response.data.cnpjCpf.length < 14  ? response.data.pessoa.nome : response.data.pessoa.razaoSocial;
        const fieldMunicipal = response.data.pessoa.documentoMunicipal;
        const fieldEstadual = response.data.pessoa.documentoEstadual;
        const fieldWeb = response.data.pessoa.pessoaContatos[0].paginaWeb;
        const senhaFin = response.data.clienteLogins[0].senhaFinanceira;
        const pessoasContact = response.data.pessoa.pessoaContatos[0];
        const admin = response.data.clienteContas.filter(
          clientes => clientes.tipoControle === 'ESCROW',
        );
        const pessoasAddress = response.data.pessoa.pessoasEndereco[0];
        const pessoasEnderec = response.data.pessoa.pessoasEndereco[0].endereco;
        const tipoEnderec =
          response.data.pessoa.pessoasEndereco[0].tipoEndereco;
        const tipoResidenc =
          response.data.pessoa.pessoasEndereco[0].tipoResidencia;
        const pessoasCep = response.data.pessoa.pessoasEndereco[0].cep;
        const pessoasComplemento =
          response.data.pessoa.pessoasEndereco[0].complemento;
        const clientCorrentCpf = response.data.clienteCorrentistas[0].cpf;
        const clientCorrentRg = response.data.clienteCorrentistas[0].rg;
        const clientCorrentNome = response.data.clienteCorrentistas[0].nome;
        setDataCadastro(fieldDataCadastro);
        setAdministrador(admin[0]?.administradorFundo);
        setFundos(admin[0]?.fundos.map(fundo => fundo?.nome));
        setDocumentoMunicipal(fieldMunicipal);
        setDocumentoEstadual(fieldEstadual);
        setNomeFantasia(fieldNomeFantasia);
        setRazaoSocial(fieldRazaoSocial);
        setCnpjCpf(formatedCnpj);
        setPaginaWeb(fieldWeb);
        setSenhaFinanceira(senhaFin);
        setPessoaContatos(pessoasContact);
        setPessoaEndereco(pessoasAddress);
        setClienteCorrentistaCpf(clientCorrentCpf);
        setClienteCorrentistaRg(clientCorrentRg);
        setClienteCorrentistaNome(clientCorrentNome);
        setTipoEndereco(tipoEnderec);
        setTipoResidencia(tipoResidenc);
        dispatch(hideLoading(false));
      })
      .catch(error => {
        dispatch(hideLoading(false));
        console.log(error);
      });
  }, []);

  function confirmBlock() {
    api_multipague_conta({
      method: 'post',
      url: `/conta/bloqueio${valorIdDetalhes}`,
      data: {
        motivo: motivoBloqueio,
      },
    });
  }

  function criarConta() {
    const URL = `/aberturaConta/${localStorage.getItem('id-cliente')}`;
    api_multipague_conta
      .post(URL)
      .then(function (response) {
        toastComponentSuccess(response.data.mensagem);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeSelect = event => {
    setSelect(event.target.value);
  };

  async function addNewCorrentista() {
    dispatch(showLoading(true));
    try {
      const body = {
        contato: {
          email: novoCorrentista.email,
          nome: novoCorrentista.nome,
        },
        cpf: novoCorrentista.cpf,
        nome: novoCorrentista.nome,
        representanteLegal: true,
        rg: novoCorrentista.rg,
        assinaturaSolidaria: checked,
      };
      const { data } = await api_multipague_conta.post(
        `conta/${contaPadrao.id}/correntista`,
        body,
      );

      setListaCorrentistas([...listaCorrentistas, body]);
    } catch (err) {
      toastComponentError(err.response.data.mensagem);
      console.log(err);
    } finally {
      dispatch(hideLoading(false));
    }
  }

  return (
    <Wrapper
      title="Meu Perfil"
      crumb={[{ name: 'Dashboard', link: '/' }]}
    >
      <React.Fragment>
        <BoxContainer>
          <Grid container spacing={3} style={{ marginBottom: '40px' }}>
            <Grid item xs={12} md={6} lg={6}>
              {isTarifado && (
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  style={{ float: 'left', width: '180px', marginTop: '15px' }}
                  className={classes.button}
                  onClick={() =>
                    mostrarTarifas
                      ? setMostrarTarifas(false)
                      : setMostrarTarifas(true)
                  }
                >
                  {!mostrarTarifas ? 'Ver Tarifas' : 'Ocultar Tarifas'}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                style={{ float: 'right', width: '180px', marginTop: '15px' }}
                className={classes.button}
                onClick={() => criarConta()}
              >
                Adicionar Conta
              </Button>
            </Grid>
            {mostrarTarifas && (
              <Grid item xs={12} md={12}>
                {listaTarifas}
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">
               Dados da Pessoa {cnpjCpf.length < 14 ? "Física" : "Jurídica"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{ marginBottom: '20px', marginTop: '20px' }}
              className={
                state.checkedA === false ? classes.inputBloq : classes.inputFree
              }
            >
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Qual conta deseja bloquear?
              </InputLabel>
              <FormControl
                variant="outlined"
                size="small"
                fullwidth
                className={classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={select}
                  onChange={handleChangeSelect}
                  fullwidth
                >
                  {listaDeContas}
                </Select>
              </FormControl>
            </Grid>

            <TextField
              fullWidth
              label="Motivo do bloqueio"
              className={
                state.checkedA === false ? classes.inputBloq : classes.inputFree
              }
              multiline
              rows={4}
              variant="outlined"
              placeholder=""
              onChange={e => {
                setMotivoBloqueio(e.target.value);
              }}
            />

            <Grid
              container
              spacing={3}
              className={
                state.checkedA === false ? classes.inputBloq : classes.inputFree
              }
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ textAlign: 'center', marginBottom: '20px' }}
              >
                <br />
                <Button
                  type="submit"
                  style={{ textTransform: 'none', margin: 'auto' }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={confirmBlock}
                >
                  Confirmar Bloqueio
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                disabled
                id="cnpjCpf"
                name="cnpjCpf"
                label={cnpjCpf.length < 14 ? "CPF" : "CNPJ"}
                value={cnpjCpf}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="razaoSocial"
                name="razaoSocial"
                label={cnpjCpf.length < 14 ? "Nome" : "Razão Social"}
                value={razaoSocial}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={6} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="nomeFantasia"
                name="nomeFantasia"
                label="Nome Fantasia"
                value={nomeFantasia}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={6} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                disabled
                id="inscEstadual"
                name="inscEstadual"
                label="Inscrição Estadual"
                value={documentoEstadual}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping address-line2"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="inscMunicipal"
                name="inscMunicipal"
                label="Inscrição Municipal"
                value={documentoMunicipal}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping address-level2"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                id="dataAbertura"
                disabled
                name="dataAbertura"
                label="Data de Abertura - Ex: dd/mm/aaaa"
                value={dataCadastro}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="paginaWeb"
                name="paginaWeb"
                label="Página Web"
                value={paginaWeb}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box marginRight="8px">
                  <Typography variant="subtitle1">
                    Dados do Representante
                  </Typography>
                </Box>

                {listaCorrentistas.length < 2 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => setShowNovoCorrentista(!showNovoCorrentista)}
                  >
                    {permissionAdmin && <BsPlusCircle size={18} />}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="cpf"
                name="cpf"
                label="CPF"
                value={clienteCorrentistaCpf}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="rg"
                name="rg"
                label="RG"
                value={clienteCorrentistaRg}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="nome"
                name="nome"
                label="Nome"
                value={clienteCorrentistaNome}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="shipping postal-code"
              />
            </Grid>
          </Grid>
          <div styke={{ height: '1rem' }} />
          <Box>
            {showNovoCorrentista && (
              <>
                <Box marginRight="8px" marginTop="20px">
                  <Typography variant="subtitle1">
                    Dados do Segundo Representante
                  </Typography>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat( auto-fit, minmax(250px, 1fr) );"
                  gridGap="20px"
                  marginTop="20px"
                >
                  <TextField
                    disabled={!!(listaCorrentistas.length > 1)}
                    variant="outlined"
                    size="small"
                    id="cpf2"
                    name="cpf2"
                    label="CPF"
                    value={
                      !!(listaCorrentistas.length > 1)
                        ? listaCorrentistas[1].cpf
                        : novoCorrentista.cpf
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      setNovoCorrentista({
                        ...novoCorrentista,
                        cpf: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                  <TextField
                    disabled={!!(listaCorrentistas.length > 1)}
                    variant="outlined"
                    size="small"
                    id="rg2"
                    name="rg2"
                    label="RG"
                    value={
                      !!(listaCorrentistas.length > 1)
                        ? listaCorrentistas[1].rg
                        : novoCorrentista.rg
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      setNovoCorrentista({
                        ...novoCorrentista,
                        rg: e.target.value,
                      });
                    }}
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                  <TextField
                    disabled={!!(listaCorrentistas.length > 1)}
                    variant="outlined"
                    size="small"
                    id="nome2"
                    name="name"
                    label="Nome"
                    value={
                      !!(listaCorrentistas.length > 1)
                        ? listaCorrentistas[1].nome
                        : novoCorrentista.nome
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      setNovoCorrentista({
                        ...novoCorrentista,
                        nome: e.target.value,
                      });
                    }}
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                  <TextField
                    disabled={!!(listaCorrentistas.length > 1)}
                    variant="outlined"
                    size="small"
                    id="nome2"
                    name="email-novo-correntista"
                    label="E-mail"
                    value={
                      !!(listaCorrentistas.length > 1)
                        ? listaCorrentistas[1]?.contato?.email
                        : novoCorrentista.email
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      setNovoCorrentista({
                        ...novoCorrentista,
                        email: e.target.value,
                      });
                    }}
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  marginTop="20px"
                >
                  <Checkbox
                    disabled={!!(listaCorrentistas.length > 1)}
                    color="primary"
                    checked={checked}
                    onChange={event => setChecked(event.target.checked)}
                  />
                  <Box marginLeft="10px">
                    <Typography>
                      Assinatura solidária (representantes assinam em conjunto)
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  marginTop="20px"
                >
                  {listaCorrentistas.length < 2 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addNewCorrentista}
                    >
                      Adicionar
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>
        </BoxContainer>
        {isAccountEscrow?.tipoControle === 'ESCROW' ? (
          <BoxContainer>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Dados da Conta
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  disabled
                  label="Administrador"
                  name="administrador"
                  fullWidth
                  value={administrador?.nomeAdministradorFundo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <InputLabel id="demo-multiple-chip-label" shrink>
                  Fundos
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  style={{ width: '100%' }}
                  label="Fundos"
                  disabled
                  value={fundos}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Fundos" />
                  }
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      {selected.map(value => (
                        <Chip
                          size="small"
                          disabled
                          key={value}
                          label={value}
                          variant="default"
                        />
                      ))}
                    </Box>
                  )}
                >
                  {fundos.map(fundo => (
                    <MenuItem key={fundo} value={fundo}>
                      {fundo}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </BoxContainer>
        ) : null}

        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" gutterBottom>
                Dados de Contato
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                id="nome"
                disabled
                name="nome"
                label="Nome"
                fullWidth
                value={pessoaContatos.nomeContato}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                type="email"
                label="Email"
                id="email"
                name="email"
                value={pessoaContatos.email}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                disabled
                required
                id="redeSocial"
                label="Rede Social"
                fullWidth
                name="redeSocial"
                value={pessoaContatos.paginaWeb}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                label="DDD"
                name="ddd1"
                fullWidth
                value={pessoaContatos.ddd}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                label="Telefone"
                name="telefone1"
                fullWidth
                value={pessoaContatos.telefone}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                label="DDD"
                name="ddd2"
                fullWidth
                value={pessoaContatos.ddd}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                label="Telefone 2 (Opcional)"
                name="telefone2"
                disabled
                fullWidth
                value={pessoaContatos.telefone}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                label="DDD"
                name="ddd3"
                value={pessoaContatos.ddd}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                disabled
                label="Telefone 3 (Opcional)"
                name="telefone3"
                value={pessoaContatos.telefone}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" gutterBottom>
                Dados de Endereço
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                required
                id="cep"
                disabled
                label="CEP"
                fullWidth
                name="cep"
                value={pessoaEndereco.cep}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="logradouro"
                label="Logradouro"
                fullWidth
                name="logradouro"
                value={pessoaEndereco.endereco}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                id="complemento"
                disabled
                label="Complemento"
                fullWidth
                name="complemento"
                value={pessoaEndereco.complemento}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="numero"
                label="Número"
                fullWidth
                name="numero"
                value={pessoaEndereco.enderecoNum}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="bairro"
                label="Bairro"
                fullWidth
                name="bairro"
                value={pessoaEndereco.bairro}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="cidade"
                label="Cidade"
                fullWidth
                name="cidade"
                value={pessoaEndereco.cidade}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="uf"
                label="UF"
                fullWidth
                name="uf"
                value={pessoaEndereco.uf}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                required
                disabled
                id="pais"
                label="País"
                fullWidth
                value={pessoaEndereco.pais}
                name="pais"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={6}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Tipo de Endereço
              </InputLabel>
              <FormControl
                variant="outlined"
                size="small"
                fullwidth
                className={classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled
                  value={tipoEndereco}
                  fullwidth
                >
                  <MenuItem value="Residencial">Residencial</MenuItem>
                  <MenuItem value="Comercial">Comercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Tipo de Residência
              </InputLabel>
              <FormControl
                variant="outlined"
                size="small"
                fullwidth
                className={classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled
                  value={tipoResidencia}
                  fullwidth
                >
                  <MenuItem value="Própria">Própria</MenuItem>
                  <MenuItem value="Alugada">Alugada</MenuItem>
                  <MenuItem value="Financiada">Financiada</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" gutterBottom>
                Dados de Acesso
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled
                variant="outlined"
                size="small"
                required
                id="usuario"
                label="Usuário"
                fullWidth
                name="usuario"
                value={cnpjCpf}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                type="password"
                required
                disabled
                id="senhaFinanceira"
                label="Senha Financeira"
                fullWidth
                name="senhaFinanceira"
                value={senhaFinanceira}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxContainer>
      </React.Fragment>
    </Wrapper>
  );
}
