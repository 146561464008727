/* eslint-disable */
import * as React from "react";
import { Link, useParams } from 'react-router-dom';
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import TopBar from "../../components/TopBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from '@material-ui/core/FormLabel';
import { FormControl } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkMaterial from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    alignItems: "center",
    marginLeft: "10px",
    width: '200px'
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: "25px",
  },
  inputform: {
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  field: {
    width: "100%",
  },
  btnprint: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  btnpdf: {
    marginRight: "10px",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  grid: {
    marginBottom: "10px",
  },
  inputsearch: {
    justifyContent: "flex-end",
  },
  inputtext: {
    margin: "15px 15px 0px",
  },
  select: {
    height: '30px'
  },
  radiobox: {
    margin: "15px 15px 0px",
    padding: "0px 10px",
    border: "1px solid #c1c1c1",
    borderRadius: "5px",
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    marginTop: "100px",
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: "300px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const rows = [
  {
    id: 1,
    codigo: '123456',
    descricao: 'Depósito da Multiplica',
  },
  {
    id: 2,
    codigo: '123456',
    descricao: 'Depósito da Multiplica',
  },
  {
    id: 3,
    codigo: '123456',
    descricao: 'Depósito da Multiplica',
  },
  {
    id: 4,
    codigo: '123456',
    descricao: 'Depósito da Multiplica',
  },
  {
    id: 5,
    codigo: '123456',
    descricao: 'Depósito da Multiplica',
  },
];

const columns = [
  { field: 'codigo', headerName: 'Código', flex: 2 },
  { field: 'descricao', headerName: 'Descrição', flex: 3 },
  {
    field: 'acoes',
    headerName: 'Ações',
    sortable: false,
    flex: 1,
    disableClickEventBubbling: true,
    renderCell: () => {
      const classes = useStyles();
      const theme = useTheme()
      const [open, setOpen] = React.useState(false);
      const [value, setValue] = React.useState('ativo');

      const handleChange = event => {
        setValue(event.target.value);
      };

      const handleOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

      return (
        <div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleOpen}
          >
            <EditIcon />
          </Button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                style={{ height: 400, width: '50%', backgroundColor: theme.palette.background.paper }}
              >
                <FormControl size="small" fullWidth>
                  <TextField
                    className={classes.inputtext}
                    id="outlined-basic"
                    label="Código"
                    value="123456"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.inputtext}
                    id="outlined-basic"
                    label="Descrição"
                    value="Essa é uma descrição padrão"
                    variant="outlined"
                  />
                  <FormControl
                    component="fieldset"
                    className={classes.radiobox}
                  >
                    <FormLabel component="legend" class="font-padrao">
                      Situação
                    </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="ativo"
                        control={<Radio />}
                        label="Ativo"
                      />
                      <FormControlLabel
                        value="desativo"
                        control={<Radio />}
                        label="Desativo"
                      />
                    </RadioGroup>
                  </FormControl>
                </FormControl>
                <div
                  style={{
                    marginTop: '15px',
                    marginRight: '15px',
                    float: 'right',
                  }}
                >
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={handleOpen}
                  >
                    <CancelIcon />
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleOpen}
                  >
                    <SaveIcon />
                    Salvar
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      );
    },
  },
];


const drawerWidth = 240;

export default function MetasDespesasNovo() {
  const classes = useStyles();
  const [value, setValue] = React.useState('ativo');
  const [description, setDescription] = React.useState('');
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [state, setState] = React.useState({
    centrocusto: '',
  });
  const {custo} = useParams();

  React.useEffect(() => {
    console.log(custo)
    api_multipague_configuracao
      .get(`/centroCusto/?idCusto=${custo}`)
      .then(response => {
        console.log(response.data[0]);
        setDescription(response.data[0].descricao)
      });
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleChangeSelect = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const [centrocusto, setCentro] = React.useState('');

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.bread}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkMaterial color="inherit" href="/">
              Dashboard
            </LinkMaterial>
            <LinkMaterial color="inherit" href="/metas-despesas">Metas x Despesas</LinkMaterial>
            <Typography color="textPrimary">Novo</Typography>
          </Breadcrumbs>
        </div>
        <Container maxWidth="lg" className={classes.container}>
          <Paper className={classes.paper}>

            <form noValidate autoComplete="off">
              <Grid container spacing={3} className={classes.grid} alignContent='center'>
                <Grid item xs={12} md={4} lg={4}>
                  <br />
                  <TextField
                    className={classes.field}
                    id="outlined-basic"
                    label="Centro de Custo"
                    variant="outlined"
                    size="small"
                    value={description}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <br />
                  <KeyboardDatePicker
        style={{ margin: 8 }}
        clearable
        label="Data Inicial"
        value={selectedDate}
        placeholder="10/10/2018"
        onChange={date => handleDateChange(date)}
        minDate={new Date()}
        format="dd/MM/yyyy"
      />


                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <br />
                  <KeyboardDatePicker
        style={{ margin: 8 }}
        label="Data Final"
        placeholder="2018/10/10"
        value={selectedDate}
        onChange={date => handleDateChange(date)}
        format="dd/MM/yyyy"
      />
                  </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <br />
                  <TextField
                    className={classes.field}
                    id="outlined-basic"
                    label="Valor da Meta"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                        <FormLabel component="legend" class="font-padrao">Tipo de Apuração</FormLabel>
                        <RadioGroup row aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            <FormControlLabel value="desativo" control={<Radio />} label="Anual" />
                            <FormControlLabel value="geral" control={<Radio color="primary" />} label="Mensal" />
                        </RadioGroup>
                </Grid>



                <Grid item xs={12} md={8} lg={8}></Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <br />

                </Grid>
              </Grid>
            </form>
            <div style={{ height: 400, width: '100%' }} class="font-padrao">
            <DataGrid rows={rows} columns={columns} pageSize={5} />
          </div>
          </Paper>
           <Button
                    type="submit"
                    variant="contained"
                    color="default"
                    style={{float:'right'}}
                    className={classes.button}
                  >
                    Cancelar
                  </Button>
                  <br />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Salvar
                  </Button>
        </Container>
      </main>
    </div>
  );
}
