import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '../../components/Buttons/ConfirmButton';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { extratoTedRequest } from '../../store/modules/extratoTed/actions';
import { api_multipague_cobranca } from '../../services/api';
import formattedMoney from '../../util/FormatteMoney';
import { hideLoading, showLoading } from '../../store/modules/loading/actions';
import { MdArrowBack, MdBackHand, MdPrint } from 'react-icons/md';
import { toastComponentError } from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  colorText: {
    color: theme.palette.appBar[theme.palette.type].bgMenu,
  },
  icons: {
    marginTop: '3px',
  },
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const theme = useTheme();
  const { idConta, idBoleto } = useParams();
  const dispatch = useDispatch();
  const { contaPadrao } = useSelector(state => state.contas);
  const { userAccount } = useSelector(state => state.user);
  const extratoTransferencia = useSelector(state => state.extratoTed);
  const [info, setInfo] = useState([]);
  const history = useHistory();

  useEffect(() => {
    buscarInfoBoleto();
  }, []);

  async function buscarInfoBoleto() {
    try {
      dispatch(showLoading(true));
      const { data } = await api_multipague_cobranca.get(
        `/boleto/${idBoleto}/comprovante?clienteConta=${idConta}`,
      );
      console.log('infoBoleto', data);
      setInfo(data);
      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
    }
  }

  function printDiv() {
    var css = '@page { size: portrait; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printButton').style.display = 'inline';
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <div id="printableArea">
          <img alt="" height="64" src={theme.config.loginLogo} />
          <Typography variant="h5" component="h5" className={classes.colorText}>
            {info?.dataPagamento &&
              (moment(info?.dataPagamento).isAfter(Date.now())
                ? 'Pagamento de Boleto Agendado'
                : 'Comprovante de Pagamento de Boleto')}
          </Typography>
          {/* <Typography variant="h5" component="h5">Comprovante de Transferência</Typography> */}
          <Typography component="body2" className={classes.colorText}>
            {info?.dataPagamento
              ? moment(info?.dataPagamento).format('DD/MM/YYYY')
              : 'xx/xx/xxxx'}
          </Typography>
          {/*  */}
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1" className={classes.colorText}>
                Linha Digitável
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.linhaDigitavel}
              </Typography>
            </div>
          </div>
          {/*  */}
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography component="body1" className={classes.colorText}>
                Data de Vencimento
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.dataVencimento &&
                  moment(info?.dataVencimento).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
          {/*  */}
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Data de Pagamento
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.dataPagamento &&
                  moment(info?.dataPagamento).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
          {/*  */}
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Valor Documento
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.valorTitulo && formattedMoney(info?.valorTitulo)}
              </Typography>
            </div>
          </div>
          {/*  */}
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Valor Pagamento
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.valorPagamento && formattedMoney(info?.valorPagamento)}
              </Typography>
            </div>
          </div>

          <Divider />
          <div className={classes.comprovante}>
            <Typography className={classes.colorText} component="subtitle1">
              <LocalAtmIcon className={classes.icons} />
              <ArrowDropDownIcon className={classes.icons} />
              Destino
            </Typography>
          </div>
          <Divider />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Nome
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.nomeBeneficiario}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Banco
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info.banco?.descricao}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                CPF/CNPJ
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.documentoFederalBeneficiario
                  ? String(info?.documentoFederalBeneficiario).length > 11
                    ? String(info?.documentoFederalBeneficiario).replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        '$1.$2.$3/$4-$5',
                      )
                    : String(info?.documentoFederalBeneficiario).replace(
                        /(\d{3})?(\d{3})?(\d{3})?(\d{2})/,
                        '$1.$2.$3-$4',
                      )
                  : '--'}
              </Typography>
            </div>
          </div>

          <Divider />
          <div className={classes.comprovante}>
            <Typography className={classes.colorText} component="subtitle1">
              <LocalAtmIcon className={classes.icons} />
              <ArrowDropUpIcon className={classes.icons} />
              Origem
            </Typography>
          </div>
          <Divider />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Nome
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.pagador?.nome}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                CPF/CNPJ
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.pagador?.documentoFederal
                  ? String(info?.pagador?.documentoFederal).length > 11
                    ? String(info?.pagador?.documentoFederal).replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        '$1.$2.$3/$4-$5',
                      )
                    : String(info?.pagador?.documentoFederal).replace(
                        /(\d{3})?(\d{3})?(\d{3})?(\d{2})/,
                        '$1.$2.$3-$4',
                      )
                  : '--'}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Banco
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.pagador?.banco?.descricao}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Agência
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.pagador?.agencia}
              </Typography>
            </div>
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography className={classes.colorText} component="body1">
                Conta
              </Typography>
            </div>
            <div>
              <Typography component="body1" className={classes.colorText}>
                {info?.pagador?.conta}-{info?.pagador?.digitoConta}
              </Typography>
            </div>
          </div>
        </div>

        <div
          className={classes.comprovante}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            id="printButton"
            startIcon={<MdArrowBack color="background" />}
            title="Voltar"
            onClick={() => history.push('/myaccounts')}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="printButton"
            title="Imprimir"
            startIcon={<MdPrint color="background" />}
            onClick={printDiv}
          >
            Imprimir
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
}
