/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FaSave } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import Typography from '@material-ui/core/Typography';
import { InputMoney } from '../../../components/Inputs/Money';
import Box from '../../../components/Box';
import Wrapper from '../../../components/Wrapper';
import {
  api_multipague_configuracaoCentro,
  api_multipague_conta,
} from '../../../services/api';
import ConfirmButton from '../../../components/Buttons/ConfirmButton';
import { toastComponentError, toastComponentSuccess } from '../../../components/Toast';

// import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    alignItems: 'center',
    marginLeft: '10px',
    width: '200px',
  },
  inputtext: {
    // margin: '15px 15px 0px',
    width: '100%',
  },
  inputtextTitle: {
    margin: '0px 0px 0px 0px',
    width: '100%',
  },
  radiobox: {
    margin: '15px 15px 0px',
    padding: '10px 10px',
    border: '1px solid #c1c1c1',
    borderRadius: '5px',
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    textTransform: 'none',
    color: theme.palette.appBar[theme.palette.type].text,
    width: '130px',
  },
  cancelar: {
    // margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    borderRadius: '20px',
    width: '130px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, contaName, theme) {
  return {
    fontWeight:
      contaName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function Cadastro() {
  const classes = useStyles();
  const [value, setValue] = React.useState('ativo');
  const [code, setCode] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [valor, setValor] = React.useState('');
  const [contas, setContas] = React.useState([]);
  const [contaName, setContaName] = React.useState([]);
  const [loadButton, setLoadButton] = useState(false);
  const theme = useTheme();
  const [state, setState] = React.useState({
    centrocusto: '',
  });
  const { userAccount } = useSelector(state => state.user);
  const { contaPadrao } = useSelector(state => state.contas);

  React.useEffect(() => {
    let contaformat = [];
    api_multipague_conta.get(`/conta`).then(response => {
      response.data.map(item => {
        contaformat.push({
          label:
            'Banco: ' +
            item.banco.nome +
            ' / ' +
            'Conta: ' +
            item.conta +
            ' / ' +
            'Agência ' +
            item.agencia,
          value: item.id,
        });
      });
      setContas(contaformat);
    });
  }, []);

  const createCusto = () => {
    console.log(userAccount.id, contaPadrao);
    let conta = [];
    contaName.map(item => {
      conta.push({ idClienteConta: item.id });
      console.log(item.id);
    });
  };

  const enviaDados = () => {
    setLoadButton(true);
    console.log('Console Henrique: ', contaName);
    const arr = new Array();
    for (var i = 0; i < contaName.length; i++) {
      var obj = { idClienteConta: contaName[i].value };
      arr.push(obj);
    }
    const params = {
      ativo: true,
      descricao: description,
      idcliente: userAccount.id,
      lista: arr,
      valorMeta: valor || 0,
    };
    api_multipague_configuracaoCentro
      .post(`/centroCusto/associa/0`, params)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        console.log('Deu 200!');
        toastComponentSuccess('Centro de custo cadastrado com sucesso!');
        setTimeout(function () {
          window.location.href = `/centroCusto`;
        }, 3000);
      })
      .catch(err => {
        if (err.response.status == 400 || err.response.status == 404) {
          toastComponentError(err.response.data.mensagem);
        }
      });
  };
  const [disableButton, setDisableButton] = useState(true);

  const handleMultipleSelect = value => {
    setContaName(value);
    setDisableButton(false);
  };

  // const handleChangeMultiple = (e) => {
  //   const { options } = event.target;
  //   const value = [];
  //   for (let i = 0, l = options.length; i < l; i += 1) {
  //     if (options[i].selected) {
  //       value.push(options[i].value);
  //     }
  //   }
  //   setContaName(value)
  // };

  const handleChange = event => {
    setContaName(event.target.value);
  };

  const handleChangeCode = event => {
    setCode(event.target.value);
  };

  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };

  const handleChangeValor = event => {
    setValor(event.target.value);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.getContrastText(theme.palette.primary.light),
      '&:hover': {
        backgroundColor: state.isSelected
          ? theme.palette.primary.light
          : theme.palette.primary.dark,
      },
    }),
  };

  return (
    <Wrapper
      title="Novo Registro"
      crumb={[
        {
          name: 'Dashboard',
          link: '/',
        },
        {
          name: 'Centro de Custo',
          link: '/centro-de-custo',
        },
      ]}
    >
      <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} sm={2}>
            <Typography color="textPrimary" className={classes.inputtextTitle}>
              Código
            </Typography>
            <TextField
              disabled
              size="small"
              className={classes.inputtext}
              id="outlined-basic"
              value="0"
              variant="outlined"
              onChange={handleChangeCode}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography color="textPrimary" className={classes.inputtextTitle}>
              Descrição
            </Typography>
            <TextField
              autoFocus
              size="small"
              className={classes.inputtext}
              id="outlined-basic"
              // label="Descrição"
              value={description}
              variant="outlined"
              onChange={handleChangeDescription}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography color="textPrimary" className={classes.inputtextTitle}>
              Meta Mensal
            </Typography>

            <InputMoney
              value={valor.value}
              onChange={val => setValor(val)}
              error={valor.error}
              disabled={valor.disabled}
              label=""
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} sm={12}>
            <Typography color="textPrimary">
              Em quais contas quer cadastrar? *
            </Typography>
            <Select
              placeholder="Selecione..."
              required
              isMulti
              styles={customStyles}
              defaultValue={contaName}
              name="label"
              options={contas}
              style={{ borderRadius: '20px' }}
              className="basic-multi-select"
              classNamePrefix="contas"
              onChange={handleMultipleSelect}
            />
            <span>* Campos obrigatórios</span>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.grid}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <ConfirmButton
              variant="contained"
              title={'Cancelar'}
              startIcon={<MdClear size={15} color="background" />}
              href="/centro-de-custo"
            ></ConfirmButton>
            <ConfirmButton
              variant="contained"
              title={'Salvar'}
              disabled={disableButton}
              startIcon={
                <FaSave
                  size="1em"
                  color={disableButton ? 'disabled' : 'background'}
                />
              }
              onClick={enviaDados}
            >
              Salvar
            </ConfirmButton>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
}

export default Cadastro;
