import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@material-ui/data-grid';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';
import formattedMoney from '../../util/FormatteMoney/index';
import Button from '../../components/Buttons/ConfirmButton';
import { MdPrint } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  grey: {
    color: 'grey',
  },
  icons: {
    marginTop: '3px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  alinhamento: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  alinhar: {
    float: 'left',
  },
}));

export default function ExtractPdf() {
  const classes = useStyles();
  const theme = useTheme();

  function printDiv() {
    var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);

    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printButton').style.display = 'inline';
  }

  const rows = JSON.parse(localStorage.getItem('pdfExtrato'));
  console.log('Testando pdf: ', rows);
  // const columnsTable = localStorage.getItem("columnsTable")

  const newRows = rows.map(item => (
    <>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: '10px', marginTop: '10px' }}
      >
        <Grid item xs={12} md={2}>
          {item.data}
        </Grid>
        <Grid item xs={12} md={3}>
          {item.centroCusto}
        </Grid>
        <Grid item xs={12} md={3}>
          {item.historicoEvento}
        </Grid>
        <Grid item xs={12} md={2}>
          {item.complemento} {item.complementoNome}
        </Grid>
        {item.tipoEvento == 'C' ? (
          <Grid
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            item
            xs={12}
            md={2}
          >
            {' '}
            + {formattedMoney(parseFloat(item.valor))}
          </Grid>
        ) : (
          <Grid
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            item
            xs={12}
            md={2}
          >
            {' '}
            - {formattedMoney(parseFloat(item.valor))}
          </Grid>
        )}
      </Grid>
      <Divider />
    </>
  ));

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <div id="printableArea">
          <img alt="" height="80" src={theme.config.loginLogo} />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              color="primary"
              id="printButton"
              title={'Imprimir'}
              onClick={printDiv}
              startIcon={<MdPrint color="background" />}
            >
              Imprimir
            </Button>
            <br />
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant="h5" component="h5">
              Extrato
            </Typography>
          </div>
          <div className={classes.alinhamento}>
            <div>
              <Typography variant="h6" component="h6">
                Data
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Centro de Custo
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Histórico
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Complemento
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Valor
              </Typography>
            </div>
          </div>
          {newRows}
        </div>
      </Container>
    </React.Fragment>
  );
}
