import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useCount } from '../../Context/CountTime';
import AppBar from '@material-ui/core/AppBar';
import axios from 'axios';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { TiThMenu } from 'react-icons/ti';
import IconButton from '@material-ui/core/IconButton';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {
  Brightness5,
  Brightness2,
  Visibility,
  VisibilityOff,
  ErrorRounded,
} from '@material-ui/icons';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import PubSub from 'pubsub-js';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, alpha } from '@material-ui/core/styles';
import DefaultMenu from '../Menu';
import {
  listarContasRequest,
  setContaPadrao,
} from '../../store/modules/contas/actions';
import formattedMoney from '../../util/FormatteMoney/index';
import '../styles.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';
import qs from 'qs';
import TopBarMobile from './TopBarMobile';
import { changeModeDark } from '../../store/modules/app';
import { Tooltip } from '@material-ui/core';
import { saldoRequest } from '../../store/modules/saldo/actions';
import { IoPersonCircle } from 'react-icons/io5';
import { MSAMBIENTE } from '../../util/constants';

const drawerWidth = 260;

export const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    position: 'fixed',
    borderBottom: `1px solid ${alpha(theme.palette.background.paper, 0.2)}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    height: '100px',
    zIndex: theme.zIndex.drawer + 1,
    width: '300px',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    ...theme.mixins.toolbar,
  },
  toolbarIconShift: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '62px',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      height: '85px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '10px',
    },
  },
  appBar: {
    position: 'fixed',
    padding: '17px 0 0',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.dark
        : theme.palette.background.default,
    paddingLeft: '20px',
    borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.2)}`,
    height: '100px',
    color: theme.palette.appBar[theme.palette.type].text,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      padding: '0',
      height: '100px',
      backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    },
  },
  appBarShift: {
    padding: '0',
    position: 'fixed',
    paddingLeft: '20px',
    marginLeft: drawerWidth,
    marginRight: 0,
    flexGrow: 1,
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.dark
        : theme.palette.background.default,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer + 5,
    overflowX: 'auto',
    scrollbarColor: theme.palette.primary.main,
    scrollbarWidth: 'thin',
    height: '100px',
    justifyContent: 'center',

    '&::-webkit-scrollbar': {
      width: '8px',
      maxHeight: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '3px',
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      padding: '0',
      height: '100px',
      backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    },
    justifyContent: 'center',
  },
  boxGridBgMenu: {
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
    },
  },
  gridBgMenu: {
    borderRadius: '20px',
    display: 'flex',
    padding: 10,
    height: '80px',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down('sm')]: {
      marginRight: 5,
      marginLeft: 15,
      marginTop: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 8,
      marginLeft: -5,
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  logo: {
    width: '65%',
    marginRight: '10px',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    border: 'none',
    width: drawerWidth,
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    border: 'none',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 110,
    [theme.breakpoints.down('xs')]: {
      width: 0,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    padding: theme.spacing(4),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(1),
    fontWeigth: '500',
    fontSize: '14px',
    textTransform: 'none',
  },
  bancoEmpresaGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5px',
      justifyContent: 'center',
    },
  },
  acessoGrid: {
    display: 'flex',
    alignItems: 'center',
    borderRight: `2px solid ${alpha(
      theme.palette.getContrastText(
        theme.palette.appBar[theme.palette.type].bgMenu,
      ),
      0.2,
    )}`,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginLeft: '10px',
      marginTop: '-12px',
      height: '50px',
    },
  },
  IconButtonsGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      marginTop: '-12px',
      marginLeft: '10px',
      height: '55px',
    },
  },
  chevroletBackground: {
    width: 25,
    height: 25,
    boxShadow: 'none',
    marginLeft: 10,
    textTransform: 'initial',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  topBarMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '50px',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  topBarMobileleft: {
    display: 'flex',
    flex: 1,
    marginLeft: '15px',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
      // maxWidth: '220px',
    },
  },
}));

export default function OutlinedCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  let kcOidc;
  switch (MSAMBIENTE) {
    case 'prod':
      kcOidc = require('../../keycloak-prd.json');
      break;
    case 'hml':
      kcOidc = require('../../keycloak.json');
      break;
    case 'prodx':
      kcOidc = require('../../keycloak-prd-xmind.json');
      break;
    default:
      kcOidc = require('../../keycloak.json');
      break;
  }
  const refreshExp = parseInt(localStorage.getItem('expiracao-refresh'));
  var idCliente = localStorage.getItem('id-cliente');
  const infoRedux = useSelector(state => state.contas);
  const { modeTheme } = useSelector(state => state.app);
  const saldo = useSelector(state => state.saldo);
  const horaRefresh = moment().add(refreshExp, 'seconds').format('LTS');
  const { horaexpiracao, setHoraExpiracao } = useCount('');

  setInterval(function () {
    const horaCompare = moment().format('LTS');
    horaexpiracao === horaCompare || newHoraRefresh === horaCompare ? (
      setOpenModal(true)
    ) : (
      <></>
    );
  }, 1000);

  var data = qs.stringify({
    grant_type: 'refresh_token',
    client_id: kcOidc.resource,
    client_secret: kcOidc.credentials.secret,
    refresh_token: localStorage.getItem('tkn-refresh'),
    scope: 'openid',
  });

  var config = {
    method: 'post',
    url:
      kcOidc['auth-server-url'] +
      'realms/' +
      kcOidc.realm +
      '/protocol/openid-connect/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  };

  const newHoraRefresh = localStorage.getItem('hora-refresh');

  const handleSubmit = event => {
    localStorage.setItem('hora-refresh', horaRefresh);

    event.preventDefault();
    axios(config)
      .then(function (response) {
        setHoraExpiracao(newHoraRefresh);
        setOpenModal(false);
        localStorage.setItem('tkn-access', response.data.access_token);
        localStorage.setItem('tkn-refresh', response.data.refresh_token);
        window.location.reload();
        setIsRenovado(true);
      })
      .catch(function (error) {
        localStorage.setItem('tkn-access', '');
        localStorage.setItem('tkn-refresh', '');
        localStorage.setItem('permission', '');
        localStorage.setItem('auth-ok', false);
        localStorage.setItem('gender', '');
        localStorage.setItem('name', '');
        localStorage.setItem('given_name', '');
        if (
          error.response.status == 401 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          mostrarErro();
        }
      });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [isRenovado, setIsRenovado] = useState(false);

  setInterval(function () {
    openModal === true ? (
      isRenovado ? (
        setTimeout(function () {
          location.reload();
        }, 1000)
      ) : (
        setTimeout(function () {
          history.push(`/login`);
        }, 15000)
      )
    ) : (
      <></>
    );
  }, 10000);

  const id_cliente = localStorage.getItem('id-cliente');
  useEffect(() => {
    if (id_cliente) {
      console.log('chama o request do client');
      dispatch(listarContasRequest(id_cliente));
    }
  }, [id_cliente, dispatch]);

  const accountComponent = useMemo(() => {
    const { contasCliente, contaPadrao } = infoRedux;

    const activeContas = contasCliente.filter(
      item => item.situacao === 'ATIVADA',
    );

    const listItems = activeContas.map(item => {
      const fundoSelecionado = item.id === contaPadrao?.id;

      return (
        <ListItem
          button
          onClick={() => setDefaultAccount(item)}
          key={item.id}
          selected={fundoSelecionado}
        >
          <ListItemAvatar>
            <Avatar>
              <AccountBalanceIcon color="inherit" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Banco: ${item.banco.nome} / Agência ${item.agencia} / Conta: ${item.conta}-${item.digitoConta} (${item.tipoControle})`}
            secondary={`Razão Social: ${item.cliente.nome} / ${
              item.usuarioAdministrador != null
                ? `Associado à: ${item.usuarioAdministrador.nomeUsuarioAdministrador} /`
                : ''
            } Saldo: ${
              formattedMoney(parseFloat(item.saldo.saldo)) ?? 'R$ 0,00'
            }`}
          />
        </ListItem>
      );
    });

    return <>{listItems}</>;
  }, [infoRedux, dispatch]);

  function setDefaultAccount(value) {
    const filterAccount = infoRedux.contasCliente.map(data => ({
      ...data,
      contaPadrao: data.id === value.id ? true : false,
    }));
    dispatch(setContaPadrao(filterAccount));
    setAnchorEl(null);
    if (infoRedux.contaPadrao.id !== value.id) {
      location.href = '/';
    }
  }

  useEffect(() => {
    const formattedInfo = infoRedux.contasCliente.map(item => (
      <ListItem button onClick={() => handleListItemClick(item)} key={item}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <AccountBalanceIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          secondary={
            'Banco: ' +
            item.banco.nome +
            ' / ' +
            'Agência ' +
            item.agencia +
            ' / ' +
            'Conta: ' +
            item.conta +
            '-' +
            item.digitoConta
          }
        />
      </ListItem>
    ));
    setInfoGraficos(formattedInfo);
  }, [infoRedux]);

  const [dataInformacoes, setDataInformacoes] = useState({
    banco: '',
    agencia: '',
    conta: '',
    cliente: '',
  });

  useEffect(() => {
    localStorage.setItem('dataInformacoes', JSON.stringify(dataInformacoes));
    PubSub.publish('captureItem', dataInformacoes);
  }, [dataInformacoes]);

  useEffect(() => {
    const infoStorage = localStorage.getItem('dataInformacoes');
    if (infoStorage) {
      setDataInformacoes(JSON.parse(infoStorage));
    }
    if (infoRedux.contaPadrao.id > 0) {
      dispatch(saldoRequest(infoRedux.contaPadrao?.id, saldo?.showSaldo));
    }
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleListItemClick = value => {
    setDataInformacoes(value);
  };

  const [infoGraficos, setInfoGraficos] = useState([]);
  const [localEmpresa, setLocalEmpresa] = useState('');
  const [cnpjEmpresa, setCNPJEmpresa] = useState('');

  const handleOpenAccount = () => {
    history.push('/select-account');
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Selecione uma conta</MenuItem>
      <List>{accountComponent}</List>
    </Menu>
  );
  const bull = <span className={classes.bullet}>•</span>;
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickProfile = event => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  useEffect(() => {
    if (infoRedux.contaPadrao.cliente) {
      setLocalEmpresa(infoRedux.contaPadrao.cliente.nome);
      setCNPJEmpresa(infoRedux.contaPadrao.cliente.cnpjCpf);
      console.log('buscar id:', infoRedux);
    }
  }, [infoRedux]);

  const nome = localStorage.getItem('given_name');

  useEffect(() => {
    if (!idCliente) {
      history.push('/select-account');
    }
  }, [idCliente]);

  const theme = useTheme();
  const matchesPoint = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (matchesPoint) {
      setOpen(false);
    }
  }, [matchesPoint]);

  localStorage.setItem('printBanco', infoRedux.contaPadrao.banco?.nome);
  localStorage.setItem('printAgencia', infoRedux.contaPadrao?.agencia);
  localStorage.setItem('printDigito', infoRedux.contaPadrao?.digitoConta);
  localStorage.setItem('printConta', infoRedux.contaPadrao?.conta);
  localStorage.setItem('idConta', infoRedux.contaPadrao?.id);
  localStorage.setItem('printNome', localEmpresa);
  localStorage.setItem('printCnpj', cnpjEmpresa);

  return (
    <>
      <AppBar
        position="absolute"
        elevation={0}
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <TiThMenu
              size={!matchesPoint ? 26 : 20}
              color={
                !matchesPoint
                  ? theme.palette.type === 'dark'
                    ? theme.palette.background.default
                    : theme.palette.appBar[theme.palette.type].bgMenu
                  : theme.palette.getContrastText(
                      theme.palette.appBar[theme.palette.type].bgMenu,
                    )
              }
            />
          </IconButton>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <div className={classes.paperModal}>
                <h2 id="transition-modal-title">Tempo expirado</h2>
                <p id="transition-modal-description">
                  Deseja renovar a sessão?
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Renovar
                </Button>
                <Link to="/login">
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                  >
                    Sair
                  </Button>
                </Link>
              </div>
            </Fade>
          </Modal>
          {openModal === true ? (
            setTimeout(function () {
              location.reload();
            }, 16000)
          ) : (
            <></>
          )}
          <Box
            flexGrow={1}
            justifyContent={'flex-start'}
            className={classes.boxGridBgMenu}
          >
            <Grid container spacing={2}>
              <Hidden smDown>
                <Grid md={8} lg={8} className={classes.gridBgMenu}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sm={4}
                    style={{
                      borderRight: `2px solid ${alpha(
                        theme.palette.getContrastText(
                          theme.palette.appBar[theme.palette.type].bgMenu,
                        ),
                        0.2,
                      )}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconButton color="inherit" onClick={handleOpenAccount}>
                      <AccountBalanceWalletIcon color="primary" />
                    </IconButton>
                    <Typography variant="body2">
                      <Typography
                        style={{
                          flexGrow: '2',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }}
                        color="inherit"
                        variant="h5"
                        noWrap
                        class="font-titulo"
                      >
                        {localEmpresa}
                        <Typography
                          style={{
                            fontSize: '11px',
                            textTransform: 'lowercase',
                          }}
                        >
                          {infoRedux?.contaPadrao?.apelidoConta}
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorElProfile}
                    keepMounted
                    open={Boolean(anchorElProfile)}
                    onClose={handleCloseProfile}
                  >
                    <Link to="/meu-perfil">
                      <MenuItem onClick={handleCloseProfile}>
                        <AccountCircle color="primary" />
                        <Typography component="body2" color="primary">
                          Minha conta
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/new-financial-password">
                      <MenuItem>
                        <LockIcon color="primary" />
                        <Typography component="body2" color="primary">
                          Alterar senhas
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/usuarios">
                      <MenuItem>
                        <PeopleIcon color="primary" />
                        <Typography component="body2" color="primary">
                          Usuários
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/login">
                      <MenuItem>
                        <ExitToAppIcon color="primary" />
                        <Typography component="body2" color="primary">
                          Sair
                        </Typography>
                      </MenuItem>
                    </Link>
                  </Menu>

                  <Grid
                    item
                    xs={12}
                    md={4}
                    sm={4}
                    className={classes.acessoGrid}
                  >
                    <IconButton
                      onClick={handleProfileMenuOpen}
                      aria-label="account of current user"
                      aria-controls="primary-search-account-menu"
                      aria-haspopup="true"
                      color="inherit"
                    >
                      <AccountBalanceIcon color="primary" />
                    </IconButton>
                    <Typography variant="body2" noWrap>
                      <Typography
                        style={{
                          flexGrow: '2',
                          flexDirection: 'column',
                          fontSize: '12px',
                        }}
                        color="inherit"
                        variant="h5"
                        noWrap
                        class="font-titulo"
                      >
                        <Typography
                          color="inherit"
                          variant="h5"
                          noWrap
                          class="font-titulo"
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          Banco:
                          <span
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 100,
                            }}
                          >
                            {' '}
                            {infoRedux.contaPadrao.banco?.nome}
                          </span>
                        </Typography>
                        <Typography
                          color="inherit"
                          variant="h5"
                          noWrap
                          class="font-titulo"
                          style={{ fontSize: '12px' }}
                        >
                          Agência:
                          <span
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 100,
                            }}
                          >
                            {' '}
                            {infoRedux.contaPadrao?.agencia}
                          </span>
                        </Typography>
                        <Typography
                          color="inherit"
                          variant="h5"
                          noWrap
                          style={{ fontSize: '12px' }}
                          class="font-titulo"
                        >
                          Conta Corrente:
                          <span
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 100,
                            }}
                          >
                            {' '}
                            {infoRedux.contaPadrao?.conta} -{' '}
                            {infoRedux.contaPadrao?.digitoConta}
                          </span>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sm={4}
                    className={classes.bancoEmpresaGrid}
                  >
                    <Typography
                      style={{
                        fontSize: '12px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      Saldo:{' '}
                      {saldo?.showSaldo
                        ? saldo?.saldo?.saldo
                          ? ` ${formattedMoney(
                              parseFloat(saldo?.saldo?.saldo),
                            )}${' '}`
                          : 'R$ 0,00 '
                        : null}
                    </Typography>
                    <Tooltip
                      placement="right"
                      title={
                        saldo === 'erro'
                          ? 'Não foi possível conectar-se ao servidor'
                          : !saldo?.showSaldo
                          ? 'Visualizar saldo'
                          : 'Ocultar saldo'
                      }
                    >
                      <IconButton
                        style={{ padding: 0 }}
                        color="inherit"
                        onClick={() => {
                          if (infoRedux) {
                            dispatch(
                              saldoRequest(
                                infoRedux.contaPadrao?.id,
                                !saldo?.showSaldo,
                              ),
                            );
                          }
                        }}
                      >
                        {saldo === 'erro' ? (
                          <ErrorRounded color="primary" />
                        ) : saldo?.showSaldo ? (
                          <VisibilityOff color="primary" />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  sm={4}
                  className={classes.IconButtonsGrid}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: 'right',
                      color:
                        theme.palette.type === 'dark'
                          ? theme.palette.background.default
                          : theme.palette.appBar[theme.palette.type].bgMenu,
                      fontSize: '12px',
                      marginRight: '15px',
                    }}
                  >
                    <Typography
                      style={{
                        flexGrow: '2',
                        color:
                          theme.palette.type === 'dark'
                            ? theme.palette.background.default
                            : theme.palette.appBar[theme.palette.type].bgMenu,
                        textTransform: 'capitalize',
                        fontSize: '18px',
                        fontStyle: 'normal',
                      }}
                      color="inherit"
                      variant="h5"
                      noWrap
                      class="font-titulo"
                    >
                      {localStorage.getItem('given_name')}
                    </Typography>
                    Seu acesso expira às{' '}
                    {newHoraRefresh === null ? horaexpiracao : newHoraRefresh}
                  </Typography>
                  <IconButton style={{ padding: 2 }}>
                    <IoPersonCircle
                      size={40}
                      color={
                        theme.palette.type === 'dark'
                          ? theme.palette.background.default
                          : theme.palette.appBar[theme.palette.type].bgMenu
                      }
                      onClick={handleClickProfile}
                    />
                    <MdKeyboardArrowDown
                      size={20}
                      style={{ padding: 0, margin: 0 }}
                      color={
                        theme.palette.type === 'dark'
                          ? theme.palette.background.default
                          : theme.palette.appBar[theme.palette.type].bgMenu
                      }
                      onClick={handleClickProfile}
                    />
                  </IconButton>
                  <Tooltip
                    title={
                      modeTheme === 'dark'
                        ? 'Desativar tema escuro'
                        : 'Ativar tema escuro'
                    }
                  >
                    <IconButton
                      color="primary"
                      onClick={() =>
                        dispatch(
                          changeModeDark(modeTheme === 'dark' ? false : true),
                        )
                      }
                    >
                      {modeTheme === 'dark' ? (
                        <Brightness5
                          style={{
                            color: theme.palette.background.default,
                          }}
                        />
                      ) : (
                        <Brightness2
                          style={{
                            color:
                              theme.palette.appBar[theme.palette.type].bgMenu,
                          }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <TopBarMobile
                  open={open}
                  saldo={saldo}
                  localEmpresa={localEmpresa}
                  handleProfileMenuOpen={handleProfileMenuOpen}
                  infoRedux={infoRedux}
                  anchorElProfile={anchorElProfile}
                  handleCloseProfile={handleCloseProfile}
                  newHoraRefresh={newHoraRefresh}
                  horaexpiracao={horaexpiracao}
                  handleClickProfile={handleClickProfile}
                  handleOpenAccount={handleOpenAccount}
                />
              </Hidden>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div
          className={clsx(
            classes.toolbarIcon,
            !open && classes.toolbarIconShift,
          )}
        >
          <img src={theme.config.menuLogo} className={classes.logo} />
          <IconButton
            onClick={handleDrawerClose}
            className={classes.chevroletBackground}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <DefaultMenu open={open} tooltip={!open} />
      </Drawer>
      {renderMenu}
    </>
  );
}
