import produce from 'immer';

const INITIAL_STATE = {
  contasCliente: [],
  contaPadrao: {},
};

const contas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LISTAR_CONTAS_SUCCESS': {
      return produce(state, draft => {
        draft.contasCliente = action.payload;
        sessionStorage.setItem(
          'accounts_cliente',
          JSON.stringify(draft.contasCliente),
        );
      });
    }

    case 'SET_CONTA_DEFAULT_SUCCESS': {
      return produce(state, draft => {
        let contaPadrao = {};
        if (action.payload.length > 1) {
          const findDefaultAccount = action.payload.filter(
            data => data.contaPadrao,
          );
          if (findDefaultAccount.length) {
            contaPadrao = findDefaultAccount[0];
          }
        } else {
          contaPadrao = action.payload[0];
        }
        draft.contaPadrao = contaPadrao;
        sessionStorage.setItem(
          'accounts_cliente',
          JSON.stringify(action.payload),
        );
        console.log('axdesousa redux contaPadrao mudou', contaPadrao);
        localStorage.setItem('accounts_default', JSON.stringify(contaPadrao));
      });
    }

    case 'LISTAR_CONTAS_ERROR': {
      return [state];
    }

    default: {
      return state;
    }
  }
};

export default contas;
